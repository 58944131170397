

<!-- xs -->
<p class="d-block d-sm-none" style="text-align: center; margin-top: 5%; font-size: 25px; padding: 0px 40px;">
  Bienvenido al Sistema de Gestión<br>
  De Control de Flotas de Vehículos<br>
  <img width="80%" style="margin: 20px;" src="https://cdn-sacyr.s3.amazonaws.com/logo-login-2.svg">
</p>



<!-- sm -->
<p class="d-none d-sm-block d-md-none" style="text-align: center; margin-top: 5%; font-size: 30px; padding: 0px 40px;">
  Bienvenido al Sistema de Gestión<br>
  De Control de Flotas de Vehículos<br>
  <img width="70%" style="margin: 20px;" src="https://cdn-sacyr.s3.amazonaws.com/logo-login-2.svg">
</p>



<!-- md -->
<p class="d-none d-md-block d-lg-none" style="text-align: center; margin-top: 5%; font-size: 40px;">
  Bienvenido al Sistema de Gestión<br>
  De Control de Flotas de Vehículos<br>
  <img width="45%" style="margin: 20px;" src="https://cdn-sacyr.s3.amazonaws.com/logo-login-2.svg">
</p>




<!-- lg -->

<p class="d-none d-lg-block d-xl-none" style="text-align: center; margin-top: 5%; font-size: 40px;">
  Bienvenido al Sistema de Gestión<br>
  De Control de Flotas de Vehículos<br>
  <img width="45%" style="margin: 20px;" src="https://cdn-sacyr.s3.amazonaws.com/logo-login-2.svg">
</p>

<!-- xl -->

<p class="d-none d-xl-block" style="text-align: center; margin-top: 5%; font-size: 40px;">
  Bienvenido al Sistema de Gestión<br>
  De Control de Flotas de Vehículos<br>
  <img width="45%" style="margin: 20px;" src="https://cdn-sacyr.s3.amazonaws.com/logo-login-2.svg">
</p>

