<div class="container-fluid">
  <h1 class="h3 mb-2 text-gray-800">Gestión de Vehículos</h1>
  <p class="mb-4">Descripción del módulo.</p>
  <div class="card shadow border-bottom-{{global.clase_menu}} shadow h-100">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold">
        <button class="btn btn-{{global.clase_menu}} btn-icon-split" data-toggle="modal" data-target="#modal-nuevo-vehiculo">
          <span class="icon text-white-50">
            <i class="fas fa-user-plus"></i>
          </span>
          <span class="text">Registrar nuevo Vehículo</span>
        </button>
      </h6>
    </div>
    <div class="card-body pl-3 pr-3">
      <div class="table-responsive">
        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
          <thead>
            <tr>
              <th>Descripción</th>
              <th width="50px" class="text-center">Patente</th>
              <th width="50px" class="text-center">Identificador</th>
              <th width="100px" class="text-center">IMEI GPS</th>
              <th width="180px" class="text-center">Tipo de Vehículo</th>
              <th>Observaciones</th>
              <th width="180px" class="text-center">Estado</th>
              <th width="180px" class="text-center">Orden</th>
              <th width="150px" class="text-center">Acciones</th>
            </tr>
          </thead>
        </table>
      </div>
  </div>
</div>
<div class="modal fade" id="modal-nuevo-vehiculo" tabindex="-1" role="dialog" aria-labelledby="modal-nuevo-vehiculo" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Registro de nuevo Vehículo</h5>
        <button class="close" type="button" (click)="CerrarModal()" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="card-body p-0">
          <div class="row ml-0 mr-0">
            <!-- <div class="col-lg-5 d-none d-lg-block bg-register-image"></div> -->
            <div class="col-lg-12">
              <div class="p-2">
                <form class="user" (ngSubmit)="RegistrarVehiculo($event)" [formGroup]="registerForm">
                  <div class="form-group row">
                    <div class="col-sm-6">
                      <label>IMEI del Dispositivo: *</label>
                      <input type="number" formControlName="imei" ng-maxlength="20" class="form-control form-control-user" dd123="IMEI del Dispositivo *" required>
                    </div>
                    <div class="col-sm-3">
                      <label>Patente: *</label>
                      <input type="text" formControlName="patente" ng-maxlength="6" class="form-control form-control-user" dd123="Patente *" required>
                    </div>
                    <div class="col-sm-3">
                      <label>Identificador: *</label>
                      <input type="text" formControlName="identificador" ng-maxlength="10" class="form-control form-control-user" dd123="Patente *" required>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Descripción: *</label>
                    <input type="text" formControlName="descripcion" class="form-control form-control-user" dd123="Descripción *" required>
                  </div>
                  <div class="form-group row p-2">
                    <div class="col-sm-4">
                      <label>Tipo de Vehículo: </label>
                    </div>
                    <div class="col-sm-8">
                      <select class="form-control" formControlName="tipo" required>
                        <option selected value=".">Seleccione una opción</option>
                        <option *ngFor="let tipo_v of global.Tipos_de_Vehiculos; let i = index" [value]="tipo_v.id">{{tipo_v.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row p-2 pb-0">
                    <div class="col-sm-4">
                      <label>Color de Vehículo: </label>
                    </div>
                    <div class="col-sm-8">
                      <input  formControlName="codigo_color" type="text" dd123="codigo_color *" value="{{Codigo_de_Color}}">
                      <input  type="color" (change)="cambiarColor($event)" value="#{{Codigo_de_Color}}">
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Observaciones: </label>
                    <textarea formControlName="observaciones" class="form-control form-control-user" placeholder="Observaciones"></textarea>
                  </div>
                  <div class="form-group">
                    <label>Flotas: </label>
                    <select  id="flotas-new" multiple style="width: 100%;" class="form-control select2" placeholder="Observaciones">
                      <option *ngFor="let flota of Listado_Flotas; let i = index" [value]="flota.id">{{flota.name}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>Orden:</label>
                    <input type="text" formControlName="orden" ng-maxlength="6" class="form-control form-control-user" dd123="Orden *" required>
                  </div>
                  <input type="submit" class="d-none" id="enviar-formulario">
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" id="btn-cerrar-modal" type="button" (click)="CerrarModal()" >Cancelar</button>
        <button class="btn btn-primary" (click)="Enviar()">Registrar</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="modal-editar-vehiculo" tabindex="-1" role="dialog" aria-labelledby="modal-editar-vehiculo" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Edición de Vehículo</h5>
        <button class="close" type="button" (click)="CerrarModalE()" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="card-body p-0">
          <div class="row ml-0 mr-0">
            <!-- <div class="col-lg-5 d-none d-lg-block bg-register-image"></div> -->
            <div class="col-lg-12">
              <div class="p-2">
                <form class="user" (ngSubmit)="EditarVehiculo($event)" [formGroup]="editForm">
                  <div class="form-group row">
                    <div class="col-sm-6">
                      <label>IMEI del Dispositivo:</label>
                      <input type="number" formControlName="imei" ng-maxlength="20" class="form-control form-control-user disabled" dd123="IMEI del Dispositivo *">
                    </div>
                     <div class="col-sm-3">
                      <label>Patente: </label>
                      <input type="text" formControlName="patente" ng-maxlength="6" class="form-control form-control-user" dd123="Patente *" required>
                    </div>
                    <div class="col-sm-3">
                      <label>Identificador: </label>
                      <input type="text" formControlName="identificador" ng-maxlength="10" class="form-control form-control-user" dd123="Identificador *" required>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Descripción:</label>
                    <input type="text" formControlName="descripcion" class="form-control form-control-user" dd123="Descripción *" required>
                  </div>
                  <div class="form-group row p-2 pb-0">
                    <div class="col-sm-4">
                      <span *ngIf="!Icono_actual_isB64" class="icon-{{this.Icono_actual}}" style="; margin-left: 20px; font-size:40px"></span>
                      <span *ngIf="Icono_actual_isB64" class="" style="font-size:40px; margin-left: 20px; "><img [src]="Icono_actual" style="height: 40px;"></span>
                    </div>
                    <div class="col-sm-8">
                      <select class="form-control" formControlName="tipo" required>
                        <option selected value=".">Seleccione una opción</option>
                        <option *ngFor="let tipo_v of global.Tipos_de_Vehiculos; let i = index" [value]="tipo_v.id">{{tipo_v.name}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row p-2 pb-0">
                    <div class="col-sm-4">
                      <label>Color de Vehículo: </label>
                    </div>
                    <div class="col-sm-8">
                      <input  formControlName="codigo_color" type="text" dd123="codigo_color *" value="{{Codigo_de_Color}}">
                      <input  type="color" (change)="cambiarColor($event)" value="#{{Codigo_de_Color}}">
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Flotas: </label>
                    <select  id="flotas-edit" multiple style="width: 100%;" class="form-control select2" placeholder="Observaciones">
                      <option *ngFor="let flota of Listado_Flotas; let i = index" [value]="flota.id">{{flota.name}}</option>
                    </select>
                  </div>
                  <div class="form-group">
                    <label>Observaciones:</label>
                    <textarea formControlName="observaciones" class="form-control form-control-user" dd123="Observaciones"></textarea>
                  </div>
                  <div class="form-group">
                    <label>Orden:</label>
                    <input type="text" formControlName="orden" class="form-control form-control-user" dd123="Orden *" required>
                  </div>
                  <input type="submit" class="d-none" id="enviar-formulario-e">
                </form>               
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" id="btn-cerrar-modal-e" type="button" (click)="CerrarModalE()" >Cancelar</button>
        <button class="btn btn-primary" (click)="EnviarE()">Guardar</button>
      </div>
    </div>
  </div>
</div>
<script>
  $( "#color_selector" ).change(function() {
    $("#codigo_color").val($("#color_selector").val().toString().split("#")[1]).change()
    
});
</script>
