import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import * as moment from "moment";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable'




declare var $: any;
declare var Swal: any;
declare var firebase: any;
declare var ol: any;

@Component({
  selector: 'app-reportes-rango',
  templateUrl: './reportes-rango.component.html',
  styleUrls: ['./reportes-rango.component.less']  
})
export class ReportesRangoComponent implements OnInit {
  // public range: DateRange = { start: new Date(2021, 4, 1), end: new Date(Date.now()) };
  currentDate = new Date();
  desde = new Date();
  hasta = new Date();
  fecha_maxima = moment().format('YYYY-MM-DD')
  coso:any = {};
  isReporteIndividual:any = "Individual";
  ID_DB:string;
  constructor(public global:GlobalService) {    this.ID_DB = this.global.ID_DB; this.coso = { array: [], fin:0};
  let mes = moment(this.desde).format("MM") 
  let anho = moment(this.desde).format("YYYY") 
  let fecha =  moment(`${anho}-${mes}-01`).format("MM-DD-YYYY")  

  this.desde =  new Date (fecha);
  this.hasta = new Date();

}
  
  Listado_Vehiculos:any = [];
  Vehiculo_Seleccionado:any = "";
  Flota_Seleccionada:any = "";
  Fecha_Desde:any="";
  Hora_Desde:any="";
  // Fecha_Hasta:any="";
  Hora_Hasta:any="";
  Tipo_Reporte:any="";
  Puede_Buscar:any = false;
  ShowEmpresas:any = false;
  Distancia_Recorrida:any = "0 Kms";
  Tiempo_Encendido:any = "";
  Listado_Empresas:any = [];
  Listado_Flotas:any = [];
  ObjectFlotas:any = {};
  addHours(a, h){
    a.setHours(a.getHours()+0);
    return a;
  }


  ngOnInit(): void {
    
    this.init();
  }

  async init(){

    if(this.global.User.superadmin){
      this.ShowEmpresas = "ALL";
    }

    await this.global.getEmpresasArray().then((Empresas:any)=>{
      let array_ = [];
        for(let index in Empresas){
            if(!Empresas[index].delete) array_.push(Empresas[index]);
        }
      this.Listado_Empresas = array_;
    });
    
    await this.global.getFlotasArray().then((Flotas:any)=>{
      let array_ = [];
      this.ObjectFlotas = Flotas;
        for(let index in Flotas){
            if(!Flotas[index].delete) array_.push(Flotas[index]);
        }
      this.Listado_Flotas = array_;
    });


    await this.global.getVehiculos(Response=>{
      let Dispo_Aux = [];
      this.global.ShowLoading();
      for(let i in Response.val()){
        let Dispositivo = Response.val()[i];
        Dispositivo.id = i;
        let action = "CargarVehiculo";
        let push_data = {
          descripcion: Dispositivo.descripcion,
          imei: Dispositivo.imei?Dispositivo.imei:"-",
          observaciones: Dispositivo.observaciones?Dispositivo.observaciones:"-",
          patente: Dispositivo.patente?Dispositivo.patente:"-",
          color: Dispositivo.color?Dispositivo.color:"c1",
          fecha_creacion: Dispositivo.created_date?Dispositivo.created_date:"-",
          estado: Dispositivo.active?"Activo":"Bloqueado",
          tipo: this.global.Tipos_de_Vehiculos.filter(A=>Dispositivo.tipo.toString()===A.id.toString())[0].name,
          orden: Dispositivo.orden?Dispositivo.orden:0,
        }
        !Dispositivo.delete ? Dispo_Aux.push(push_data) : false;
      }
      Dispo_Aux = Dispo_Aux.sort((A,B)=>A.orden-B.orden);
      this.Listado_Vehiculos = Dispo_Aux;
      this.global.HideLoading();
    });

    $('#fecha-desde,#fecha-hasta').on("change",evento=>{
      var fecha_hoy = moment().format('YYYY-MM-DD')
      var desde_  = moment(this.desde).format('YYYY-MM-DD')
      var hasta_  = moment(this.hasta).format('YYYY-MM-DD')
      let alerta=()=>{
        this.global.Alert({
          title: '¡Atención!',
          text: "La fecha ingresada no es válida, por favor intente nuevamente",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
        })
      }
      if ( desde_ > fecha_hoy ){
        alerta()
        let mes = moment(this.desde).format("MM") 
        let anho = moment(this.desde).format("YYYY") 
        let fecha =  moment(`${anho}-${mes}-01`).format("YYYY-MM-DD") 
        $('#fecha-desde').val(fecha)   
      }
      if (  hasta_ > fecha_hoy  ){
        alerta()
        $('#fecha-hasta').val(fecha_hoy)   
      }
    })
  }


ActualizarEstado(){
  let Variables = ["Vehiculo_Seleccionado"];
  this.Puede_Buscar = true;
  for(let i=0; i < Variables.length; i++ ) if(this[Variables[i]].length === 0) this.Puede_Buscar = false;

  if(!this.Puede_Buscar){
    $("#ruta").addClass("d-none");
    $("#encendido").addClass("d-none");
    $("#btn_reporte").addClass("d-none");
  }
}
LimpiarEstado(){
  $("#ruta").addClass("d-none");
  $("#encendido").addClass("d-none");
  $("#btn_reporte").addClass("d-none");
}
ActualizarVariable(Obj, V){
  let Id = Obj.target?Obj.target.value:Obj;
  this[V] = Id;
  this.ActualizarEstado();
}
Posicion_Global:any = null;
HoraMarcada:any = "0";

BuscarReportes(){
  this.global.ShowLoading();
  let _self_ = this;
  let _desde_ = moment(this.desde).format("YYYY-MM-DD"); 
  let _hasta_ = moment(this.hasta).format("YYYY-MM-DD"); 
  var idEquipo = _self_.Vehiculo_Seleccionado;
  this.global.sendGetRequestRangos(idEquipo, _desde_+"@"+_hasta_).subscribe((response: any[])=>{

    let Registros = response
    let vehiculo = _self_.Listado_Vehiculos.filter(A=>A.imei == idEquipo)?_self_.Listado_Vehiculos.filter(A=>A.imei == idEquipo)[0]:false

    console.log(vehiculo)
    let Reportes_Array = [];
    let Total_km = 0
    let Total_tiempo = 0
    $("#tabla_reporte").empty()
    let tabla_reporte = '<thead><tr><th width="200">Fecha</th><th width="200">Kilometraje</th><th width="200">Tiempo Encendido</th></tr></thead>'
    let Registros_por_fecha = {}
    let fecha = "";
    for(let x in Registros){
      fecha = moment(Registros[x].FECHA).format("DD-MM-YYYY")      
      Total_km += Registros[x].KILOMETRAJE?Number(Registros[x].KILOMETRAJE):0
      Total_tiempo += Registros[x].TIEMPO?Number(Registros[x].TIEMPO):0
      Registros[x].FECHA2 = fecha
      //tabla_reporte +=`<tr><td>${Registros[x].FECHA2}</td><td>${Registros[x].KILOMETRAJE}</td><td>${Registros[x].TIEMPO}</td></tr>`
      if(!Registros_por_fecha[fecha]) Registros_por_fecha[fecha] = {Total_km:0,Total_tiempo:0}      
      Registros_por_fecha[fecha].Total_km += Registros[x].KILOMETRAJE?Number(Registros[x].KILOMETRAJE):0  
      Registros_por_fecha[fecha].Total_tiempo += Registros[x].TIEMPO?Number(Registros[x].TIEMPO):0  

    }

    var array_registros_diarios = []; 
    for (var [key] of Object.entries(Registros_por_fecha)) {
      tabla_reporte +=`<tr><td>${key}</td><td>${Registros_por_fecha[key].Total_km.toFixed(1)}</td><td>${fancyTimeFormat(Registros_por_fecha[key].Total_tiempo)}</td></tr>`
      array_registros_diarios.push({FECHA: key, TIEMPO2: Registros_por_fecha[key].Total_tiempo, TIEMPO: fancyTimeFormat(Registros_por_fecha[key].Total_tiempo), KILOMETRAJE: Registros_por_fecha[key].Total_km.toFixed(1)})
  }
  // tabla_reporte +=`<tr><td>Total</td><td>${Total_km.toFixed(1)}</td><td>${fancyTimeFormat(Total_tiempo)}</td></tr>`

  


    console.log(array_registros_diarios)
    _self_.Tiempo_Encendido = fancyTimeFormat(Total_tiempo)
    _self_.Distancia_Recorrida = Total_km.toFixed(1)
    $("#tabla_reporte").html(tabla_reporte)
    $("#reporte").removeClass("d-none");
    let Tabla = null;
    $('#dataTable').DataTable().destroy();
    Tabla = $('#dataTable').DataTable({dom: ' <"datatable-header"fl<"toolbar">><B><"datatable-scroll"t><"datatable-footer"ip >',scrollX: false,scrollCollapse: false,lengthMenu: [[-1, 5, 10, 25, 50],["Todos", 5, 10, 25, 50]],aaSorting: [[0, "asc"]],language: {search: "<span >Buscar Registro:</span> _INPUT_",lengthMenu: "<label>Cantidad de registros mostrados</label>_MENU_",paginate: {first: "Primero",last: "Ultimo",next: "→",previous: "←"},decimal: "",emptyTable: "No existen registros con los filtros indicados.",info: "Mostrando _START_ a _END_, de un total de _TOTAL_ registros",infoEmpty: "",infoFiltered: "(Filtrando de un máximo de _MAX_ registros).",infoPostFix: "",thousands: ",",loadingRecords: "Cargando",processing: "Procesando",zeroRecords: "No se encontraron vehículos registrados."},processing: true,data: [],
      columnDefs:[{
          className: "text-center",
          targets: [0,1,2,3]
      },{ visible: false, targets: [3] }],
        createdRow: function(t, a, e) {
          //console.log(a)
          $(t).addClass("tr-normal");
          // if(a.velocidad === "0.0") $(t).addClass("tr-sin-mover");
          //   else $(t).addClass("tr-normal");
      },
      footerCallback: function ( row, data, start, end, display ) {
        var api = this.api(), data;

        // Remove the formatting to get integer data for summation
        var intVal = function ( i ) {
            return typeof i === 'string' ?
            +i.replace(/[\$,]/g, '') * 1 :
                typeof i === 'number' ?
                    i : 0;
        };

        // Total over all pages
        let kmTotal = api
            .column( 1 )
            .data()
            .reduce( function (a, b) {
                return intVal(a) + intVal(b);
            }, 0 );

        // Total over this page
        // let pageTotal = api
        //     .column( 1, { page: 'current'} )
        //     .data()
        //     .reduce( function (a, b) {
        //         return intVal(a) + intVal(b);
        //     }, 0 );
        let tiempoTotal = api
            .column( 3 )
            .data()
            .reduce( function (a, b) {
                return intVal(a) + intVal(b);
            }, 0 );

        // Update footer
        $( api.column( 1 ).footer() ).html(
          kmTotal.toFixed(1) //+' ( $'+ total +' total)'
        );
        $( api.column( 2 ).footer() ).html(
          fancyTimeFormat (tiempoTotal) //+' ( $'+ total +' total)'
        );
      },
      //   buttons: [
      //       'copy', 'csv', 'excel', 'pdf', 'print'
      // ] 
      buttons: [
      //   {
      //     extend: 'copyHtml5',
      //     text:  'Copy',
      //     copyTitle: 'Copy Data',
      //     copyKeys: 'Press <i>ctrl</i> or <i>\u2318</i> + <i>C</i> to copy the table data<br>to your system clipboard.<br><br>To cancel, click this message or press escape.'                          
      // },
      // {
      //   extend: 'csv',
      //   messageTop: 'Reporte sacyr.',
      //   title: "Titulo",
      //   filename: 'Data export'
      // },
      {
        extend: 'excel',
        title: 'Tiempo de funcionamiento diario',
        messageTop: vehiculo.observaciones.replace('\n',' '),
        filename: `Reporte de funcionamiento ${vehiculo.descripcion} ${vehiculo.patente.toUpperCase()} ${_desde_} al ${_hasta_}`
      },
      {
      text: 'Reporte PDF',
          action: function ( e, dt, node, config ) {
            _self_.GenerarReporte()
          }
      }
      // {
      //   extend: 'pdf',
      //   title: "Titulo",
      //   messageTop: 'Reporte sacyr.',
      //   filename: 'Data export'
      // }
    ] ,
      columns: [{data:"FECHA"},{data:"KILOMETRAJE"},{data:"TIEMPO"},{data:"TIEMPO2"}],ordering: true});
      $('.buttons-excel, .buttons-print , .buttons-csv, .buttons-pdf, .buttons-copy, .dt-button').each(function() {
        $(this).removeClass().addClass('btn btn-success btn-reporte')
     })
    Tabla.clear().draw();
    Tabla.rows.add(array_registros_diarios).draw();
    //console.log(Tabla.rows)
    _self_.global.HideLoading();
    //console.log(_self_.bsRangeValue[0].toISOString() )
 



    
  });
 

  
}

GenerarReporte(){
//   let source = $('#div_reporte')[0];
//   let specialElementHandlers = {
//     // element with id of "bypass" - jQuery style selector
//     '#bypassme': function (element, renderer) {
//         // true = "handled elsewhere, bypass text extraction"
//         return true
//     }
//   };
// let margins = {
//     top: 80,
//     bottom: 60,
//     left: 40,
//     width: 522
// };
// pdf.fromHTML(
//   source, // HTML string or DOM elem ref.
//   margins.left, // x coord
//   margins.top, { // y coord
//       'width': margins.width, // max width of content on PDF
//       'elementHandlers': specialElementHandlers
//   },

let _self_ = this;
var pdf = new jsPDF('p', 'pt', 'letter');
let _desde_ = moment(_self_.desde).format("YYYY-MM-DD"); 
let _hasta_ = moment(_self_.hasta).format("YYYY-MM-DD"); 
var idEquipo = _self_.Vehiculo_Seleccionado;
let vehiculo = _self_.Listado_Vehiculos.filter(A=>A.imei == idEquipo)?_self_.Listado_Vehiculos.filter(A=>A.imei == idEquipo)[0]:false
if(vehiculo){
  console.log(vehiculo)
  pdf.setFontSize(25)
  pdf.text(100, 75, 'Tiempo de funcionamiento diario')
  pdf.setFontSize(15)
  pdf.setFont("arial","normal","normal")
  pdf.text(40, 105, vehiculo.observaciones)
  pdf.autoTable({ margin: { top: 130 }, html: '#tabla_reporte' })
  pdf.autoTable({ html: '#tabla_reporte_total' })
  pdf.save(`Reporte de funcionamiento ${vehiculo.descripcion} ${vehiculo.patente.toUpperCase()} ${_desde_} al ${_hasta_}.pdf`);
}


  // function (dispose) {
  //     // dispose: object with X, Y of the last line add to the PDF 
  //     //          this allow the insertion of new lines after html
  //     pdf.save('Test.pdf');
  // }, margins);
}

}

function fancyTimeFormat(duration){   
  // Hours, minutes and seconds
  var hrs = ~~(duration / 3600);
  var mins = ~~((duration % 3600) / 60);
  var secs = ~~duration % 60;
  // Output like "1:01" or "4:03:59" or "123:03:59"
  var ret = "";
  if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }
  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;
  return ret;
}