import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from '../../services/global.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

declare var $: any;
declare var Swal: any;
@Component({
  selector: 'app-flotas',
  templateUrl: './flotas.component.html',
  styleUrls: ['./flotas.component.less']
})
export class FlotasComponent implements OnInit {



  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  Tabla_de_Datos:any;
  Listado_Flotas:any = [];
  registerForm = new FormGroup({
    name: new FormControl('',[ Validators.required , Validators.maxLength(30)]),
    description: new FormControl('',Validators.required),
    // x: new FormControl('',Validators.required),
    // zoom: new FormControl('',Validators.required),
    // y: new FormControl('',Validators.required),
    id: new FormControl({ value:getUniqueId(8), disabled:false})
  });

  editForm = new FormGroup({
    id: new FormControl({ value:"", disabled:true}),
    name: new FormControl('',[ Validators.required , Validators.maxLength(30)]),
    // x: new FormControl('',Validators.required),
    // zoom: new FormControl('',Validators.required),
    // y: new FormControl('',Validators.required),
    description: new FormControl('',Validators.required)
  });

  constructor(public global:GlobalService) {}

  ngOnInit(): void {




    //speechSynthesis.getVoices()[31]



    this.Tabla_de_Datos = $('#dataTable').DataTable({dom: '<"datatable-header"f<"toolbar">><"datatable-scroll"t><"datatable-footer"i>',scrollX: false,scrollCollapse: false,lengthMenu: [[-1, 50, 25, 10, 5],["Todos", 50, 25, 10, 5]],aaSorting: [[0, "asc"]],language: {search: "<span >Buscar Flota:</span> _INPUT_",lengthMenu: "<label>Cantidad de registros mostrados</label>_MENU_",paginate: {first: "Primero",last: "Ultimo",next: "→",previous: "←"},decimal: "",emptyTable: "No existen registros con los filtros indicados.",info: "Mostrando _START_ a _END_, de un total de _TOTAL_ registros",infoEmpty: "",infoFiltered: "(Filtrando de un máximo de _MAX_ registros).",infoPostFix: "",thousands: ",",loadingRecords: "Cargando",processing: "Procesando",zeroRecords: "No se encontraron flotas registradas."},processing: true,data: [],
      columnDefs:[{
          className: "text-center",
          targets: [1,2,3]
      },{ visible: false, targets: [3] }],
      columns: [{data:"name"},{data:"description"},{data:"acciones"},{data:"fecha_creacion"}],ordering: true});
    let self = this;
    this.global.ShowLoading();


    this.global.getFlotas(Response=>{
      let Dispo_Aux = [];
      this.global.ShowLoading();
      for(let i in Response.val()){
        let Flota = Response.val()[i];
        let push_data = {
          description: Flota.description?Flota.description:"-",
          name: (Flota.name?Flota.name:"-") + `<span class="icon-${Flota.icon}" style="font-size:40px;margin-left:20px"></span> `,
          fecha_creacion: Flota.created_date?Flota.created_date:"-",
          // tipo: this.global.Tipos_de_Vehiculos.filter(A=>Flota.tipo.toString()===A.id.toString())[0].name,
          acciones: `
            <button class="btn btn-info btn-icon-split mr-1" data-toggle="tooltip" data-button-edit="${Flota.id}" title="Editar"><span class="icon"><i class="fas fa-user-edit"></i></span></button>
            <button class="btn btn-danger btn-icon-split" data-toggle="tooltip" data-button-delete="${Flota.id}" title="Borrar"><span class="icon"><i class="fas fa-trash"></i></span></button>`
        }
        !Flota.delete ? Dispo_Aux.push(push_data) : false;
      }
      this.Listado_Flotas = Dispo_Aux;
      this.Tabla_de_Datos.clear().draw();
      this.Tabla_de_Datos.rows.add(Dispo_Aux).draw();
      this.global.HideLoading();
      //setTimeout(()=>$('[data-toggle="tooltip"]').tooltip(),0);

      /** Carga eventos de botoones **/
      $("[data-button-edit]").bind("click").on("click", E=>{
         let id = $(E.currentTarget).data('button-edit');
         this.Cargar(id);
      });
      $("[data-button-delete]").bind("click").on("click", E=>{
         let id = $(E.currentTarget).data('button-delete');
         this.Eliminar(id);
      });
    });
  }

  ObjectoActual:any= {};
  Editar(E){
    //console.log(this.ObjectoActual)
    E.preventDefault();
    E.stopPropagation();
    let _continue_ = true;
    for(let i in this.editForm.controls){
      if(!this.editForm.controls[i].valid && i != "id"){
        $("#modal-editar [formControlName="+i+"]").addClass("is-invalid");
        _continue_ = false;
      }
    }
    if(!_continue_){
      setTimeout(()=>$("#modal-editar [formControlName]").removeClass("is-invalid"), 1000);
      return;
    }
    if(this.editForm.valid){
      for(let i in this.editForm.controls) this.ObjectoActual[i] = this.editForm.controls[i].value;
      this.ObjectoActual.active===undefined?this.ObjectoActual.active=true:false;
    this.global.ShowLoading();
      
      // if(this.croppedImage2.length>0){
      //    fnc();
      // }
      // else{
        this.global.addFlota(this.ObjectoActual);
         this.global.Alert({
          icon: 'success',
          title: '',
          text: 'Flota registrada correctamente.'
        });
         this.CerrarModalE();

      //}
      
    }
  }


  Eliminar = (id)=>{
    this.global.Alert({
      title: '¡Atención!',
      text: "¿Segur@ que deseas eliminar la Flota?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ObjectoActual = {};
        this.global.getFlota(id, R=>{
         let Dispositivo = R.val();
         this.ObjectoActual = Dispositivo;
         this.ObjectoActual.delete = true;
        this.global.addFlota(this.ObjectoActual);
        Swal.fire(
          '!Eliminado!',
          'Se ha eliminado con éxito.',
          'success'
        );
      });

    }
    });
  }
  Cargar = (id)=>{
    this.ObjectoActual = {};
    this.global.getFlota(id, R=>{
      let Flota = R.val();
      this.ObjectoActual = Flota;
      this.editForm = new FormGroup({
        id: new FormControl({ value: Flota.id, disabled:true}),
        name: new FormControl(Flota.name,[Validators.required, Validators.maxLength(30)]),
        // x: new FormControl(Flota.x,Validators.required),
        // y: new FormControl(Flota.y,Validators.required),
        // zoom: new FormControl(Flota.zoom,Validators.required),
        description: new FormControl(Flota.description,Validators.required)
      });
      $("#modal-editar").modal()
    });
  }
   Registrar(E){
    E.preventDefault();
    E.stopPropagation();
    let _continue_ = true;

   // if(this.croppedImage.length>0){}

    for(let i in this.registerForm.controls){
      if(!this.registerForm.controls[i].valid){
        $("#modal-nuevo [formcontrolname="+i+"]").addClass("is-invalid");
        _continue_ = false;
      }
    }
    if(!_continue_){
      setTimeout(()=>$("#modal-nuevo [formcontrolname]").removeClass("is-invalid"), 1000);
      return;
    }

    if(this.registerForm.valid){
      let _New_ = this.registerForm.value;
        let self = this;
          _New_.active = true;
          _New_.delete = false;
          _New_.created_date = new Date().toISOString();
          _New_.id_empresa = self.global.ID_EMPRESA_ACTUAL;
          
          // if(self.croppedImage.length>0){
          //    fnc();
          // }
          // else{
            self.global.addFlota(_New_);
             self.global.Alert({
              icon: 'success',
              title: '',
              text: 'Flota registrada correctamente.'
            });
             self.CerrarModal();

          //}
    }
    else console.log("formulario no valido.")
  }
  CerrarModal(){
    $("#modal-nuevo").modal('hide')
    this.registerForm = new FormGroup({
      name: new FormControl('',[ Validators.required , Validators.maxLength(30)]),
      description: new FormControl('',Validators.required),
      // x: new FormControl('',Validators.required),
      // y: new FormControl('',Validators.required),
      // zoom: new FormControl('',Validators.required),
      id: new FormControl({ value:getUniqueId(8), disabled:false}),
    });
  }
  CerrarModalE(){
    $("#modal-editar").modal('hide')
    this.editForm = new FormGroup({
      id: new FormControl({ value:"", disabled:true}),
      // x: new FormControl('',Validators.required),
      // y: new FormControl('',Validators.required),
      name: new FormControl('',[ Validators.required , Validators.maxLength(30)]),
      // zoom: new FormControl('',Validators.required),
      description: new FormControl('',Validators.required),
    });
  }
  Enviar(){
    $("#enviar-formulario").click();
  }
  EnviarE(){
    $("#enviar-formulario-e").click();
  }



}




 export function getUniqueId(parts: number): string {
  const stringArr = [];
  for(let i = 0; i< parts; i++){
    const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    stringArr.push(S4);
  }
  return stringArr.join('-');
}
