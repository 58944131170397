<!-- <link rel="stylesheet" href="/assets/css/bs-datepicker.css" type="text/css">
<link rel="stylesheet" href="/assets/css/bootstrap.min.css" type="text/css">
 -->

<style>
 
</style>
<div class="container-fluid">
    <h1 class="h3 mb-2 text-gray-800">Módulo de Reportes</h1>
    <p class="mb-4">Descripción del módulo.</p>
    <div class="card shadow border-bottom-{{global.clase_menu}} shadow h-100">
      <div class="card-header py-3 border-bottom-{{global.clase_menu}}">
        <h6 class="m-0 font-weight-bold row">          
          <div class="form-group col-lg-3" *ngIf="true">
              <label>Tipo de Reporte:</label>
              <select class="form-control" (change)="ActualizarVariable($event, 'isReporteIndividual');LimpiarEstado();">
                <option selected value="Individual">Reporte por Vehículo</option>
                <option value="Flota">Reporte por Flota</option>
                
              </select>
          </div>     
          <div class="form-group col-lg-3" id="foco" *ngIf="isReporteIndividual==='Individual'">
              <label>Vehículo:</label>
              <select class="form-control" id="vehiculo" (change)="ActualizarVariable($event, 'Vehiculo_Seleccionado');LimpiarEstado();">
                <option selected disabled>Seleccione...</option>
                <option *ngFor="let dispositivo of Listado_Vehiculos; let i = index" [value]="dispositivo.imei">{{dispositivo.descripcion}} - {{dispositivo.patente}} - {{dispositivo.imei}}</option>
              </select>
          </div>
          <div class="form-group col-lg-3" id="foco" *ngIf="isReporteIndividual==='Flota'">
              <label>Flota:</label>
              <select class="form-control" id="vehiculo" (change)="ActualizarVariable($event, 'Flota_Seleccionada');LimpiarEstado();">
                <option selected disabled>Seleccione...</option>
                <option *ngFor="let flota of Listado_Flotas; let i = index" [value]="flota.id">{{flota.name}}</option>
              </select>
          </div>     
          <div class="form-group col-lg-2">
            <label>Desde: </label>
            <input class="form-control"  id="fecha-desde" [ngModel]="desde | date:'yyyy-MM-dd' : 'UTC'"  (ngModelChange)="desde = $event" placeholder="fecha" max="{{this.fecha_maxima}}" (change)="ActualizarVariable($event, 'Rango')" #fecha  type="date">
            

         </div>
         <div class="form-group col-lg-2">
          <label>Hasta: </label><br>
          <input class="form-control" id="fecha-hasta" [ngModel]="hasta | date:'yyyy-MM-dd' : 'UTC'"  (ngModelChange)="hasta = $event" placeholder="fecha"  max="{{this.fecha_maxima}}" #fecha  type="date">

         </div>
          <div class="form-group col-12 col-lg-2 d-none d-lg-block">
            <button style="width: 100%" *ngIf="Puede_Buscar" class="mt-25 ml-1 btn btn-{{global.clase_menu}} btn-icon-split" (click)="BuscarReportes()">
              <span style="" class="icon text-white-50">
                <i class="fas fa-search"></i>
              </span>
              <span class="text" style="font-size:15px;margin-left:15px;">Buscar Reportes</span>
            </button>
            <button style="width: 100%" *ngIf="!Puede_Buscar" class="mt-25 ml-1 btn btn-{{global.clase_menu}} btn-icon-split" disabled>
              <span  style=""  class="icon text-white-50">
                <i class="fas fa-search"></i>
              </span>
              <span class="text" style="font-size:15px;margin-left:15px;">Buscar Reportes</span>
            </button>
          </div>
  
          <div class="form-group col-12 col-lg-2 d-lg-none ">
              <button style="width: 100%;" *ngIf="Puede_Buscar" class="mt-25 ml-1 btn btn-{{global.clase_menu}} btn-icon-split" (click)="BuscarReportes()">
                <span style=" position: absolute; left: 17px;" class="icon text-white-50">
                  <i class="fas fa-search"></i>
                </span>
                <span class="text" style="font-size:15px;margin-left:15px;">Buscar Reportes</span>
              </button>
              <button style="width: 100%;" *ngIf="!Puede_Buscar" class="mt-25 ml-1 btn btn-{{global.clase_menu}} btn-icon-split" disabled>
                <span class="icon text-white-50 icon-from-button">
                  <i class="fas fa-search"></i>
                </span>
                <span class="text" style="font-size:15px;margin-left:15px;">Buscar Reportes</span>
              </button>
          </div>
  
        </h6>
      </div>
      <div class="card-body pl-3 pr-3 row d-none" id="reporte">
     
        <div class="table-responsive p-3">
          <table class="table table-bordered display nowrap" id="dataTable" width="100%" cellspacing="0">
            <thead>
              <tr>
                <th width="200">Fecha</th>
                <th width="200">Kilometraje</th>
                <th width="200">Tiempo <br>Encendido</th>       
                <th>Tiempo en Minutos</th>
              </tr>
            </thead>
            <tfoot>
              <tr>
                  <th>Total</th>
                  <th colspan="1" style="text-align:right">Total:</th>
                  <th></th>
                  <th></th>
              </tr>
          </tfoot>
          </table>
        </div>
        <!-- <button style="width: 100%"  class="mt-25 ml-1 btn btn-success  btn-icon-split" (click)="GenerarReporte()">
          <span style="" class="icon text-white-50">
            <i class="fas fa-file-download"></i>
          </span>
          <span class="text" style="font-size:15px;margin-left:15px;">Generar Reporte</span>
        </button> -->
      </div>
      <div id="div_reporte" style="display: none;"> 
        <h1>Tiempo de funcionamiento Diario</h1>       
      </div>
      <table class="hidden" style="display: none;" id="tabla_reporte">          
      </table>
      <table class="hidden" style="display: none;" id="tabla_reporte_total">          
        <tbody>
          <tr>
            <td colspan="2">Estadísticas</td>            
          </tr>
          <tr>
            <td>Tiempo en movimiento</td> <td>{{Tiempo_Encendido}} horas</td>
          </tr>
          <tr>
            <td>Kilometros en viaje</td> <td>{{Distancia_Recorrida}} km</td>
          </tr>
        </tbody>
      </table>
      <div class="card-body pl-3 pr-3 row d-none" id="encendido">
        <span class="pl-3 pt-1 pb-3"><b>Tiempo Encendido: {{Tiempo_Encendido}}</b></span> 
        <div class="table-responsive p-3">
          <table class="table table-bordered" id="dataTable-2" width="100%" cellspacing="0">
            <thead>
              <tr>
                <th>Hora</th>
                <th>Estado</th>
                <th>Tipo</th>
                <th>Tiempo<br>Encendido</th>
                <th>Tiempo<br>Encendido</th>

              </tr>
            </thead>
          </table>
        </div>
      </div>
   
  </div>
  
