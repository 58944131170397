import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from '../../services/global.service';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  inicioRecuperacion=false;
  useReadOnlyPass = true;
  loginForm = new FormGroup({
    email: new FormControl('',[
      Validators.required,Validators.pattern(this.emailPattern)]),
    password: new FormControl('', Validators.required)
  });
  recuperarForm = new FormGroup({
    email: new FormControl('',[
      Validators.required,Validators.pattern(this.emailPattern)])
  });
  constructor(public global:GlobalService) {  }

  ngOnInit(): void {
    $("#input-password").prop("readOnly", false);
  }

  iniciarRecuperacionDeContrasenia(obj){
    if(this.inicioRecuperacion) return;
    this.inicioRecuperacion=true;
  }
  volver(){
    this.inicioRecuperacion=false;
    this.global.onRecuperando=false;

  }
  recuperarContrasenia(E){
    E.preventDefault();
    E.stopPropagation();
    if(!this.recuperarForm.controls.email.valid){
      return this.global.Alert({
        icon: 'warning',
        title: 'Oops...',
        text: 'El campo "Correo" no tiene el formato correcto.'
      });
    }
    if(this.recuperarForm.valid){
      this.global.onRecuperando = true;

      this.global.findUser(this.recuperarForm.value.email).then(user=>{
        if(user===null){
          this.global.onRecuperando = false;
          this.global.Alert({
            icon: 'error',
            title: 'Oops...',
            text: 'El correo ingresado no se encuentra registrado.'
          });
        }
        else if(!user["active"]){
          this.global.onRecuperando = false;
          this.global.Alert({
            icon: 'error',
            title: 'Oops...',
            text: 'El correo ingresado no se encuentra activo.'
          });
        }
        else{
          let clave = Math.random().toString(36).slice(-4);
          let timestamp = new Date().getTime();
          this.global.patchUser(this.recuperarForm.value.email, "clave_temporal", {clave: clave, timestamp: timestamp}).then(rsp=>{
            this.global.sendMail(this.recuperarForm.value.email, "Recuperar Contraseña de Acceso", this.global.FormatoCorreoRecuperacion(clave)).then(response=>{
              this.global.onRecuperando = false;
              this.global.Alert({
                icon: 'success',
                title: '',
                text: 'Se ha enviado una contraseña temporal a su correo.'
              });

              this.loginForm.patchValue({
                email: this.recuperarForm.value.email,
                 password: ""
              });
              this.volver();

            });
          });
        }

      });
    }
    else{
      this.global.onRecuperando = false;
      this.global.Alert({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!'
      });
    }
  }
  onSubmit(E){
    E.preventDefault();
    E.stopPropagation();
    if(!this.loginForm.controls.email.valid){
      return this.global.Alert({
        icon: 'warning',
        title: 'Oops...',
        text: 'El campo "Correo" no tiene el formato correcto.'
      });
    }
    if(!this.loginForm.controls.password.valid){
      return this.global.Alert({
        icon: 'warning',
        title: 'Oops...',
        text: 'El campo "Contraseña" no tiene el formato correcto. '
      });
    }
    if(this.loginForm.valid) this.global.Login(this.loginForm.value.email, this.loginForm.value.password);
    else{
      this.global.Alert({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong!'
      });
    }
  }

}
