import { Component } from '@angular/core';
import { GlobalService } from './services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {

  constructor(public global:GlobalService) {
    console.log("hello from AppComponent")
    this.global.sessionActive?this.global.listenUser():false;
    if(this.global.ID_EMPRESA_ACTUAL===false){
        if(this.global.User != null){
            if(this.global.User.id_empresa){
                this.global.setIdEmpresa(this.global.User.id_empresa);
            }
        }
    }
    // this.global.sessionActive?this.global.listenEmpresa(this.global.ID_EMPRESA_ACTUAL):false;

    this.global.listenTipos(tipo=>{
        let aux = tipo.val();
        let array_ = [];
        for(let index in aux){
          if(!aux[index].delete) array_.push(aux[index]);
        }
        this.global.setTipos(array_);
    });
  }
}
