import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ImageCropperModule } from 'ngx-image-cropper';


import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { environment } from '../environments/environment';
import { LandingComponent } from './components/landing/landing.component';
import { LoginComponent } from './components/login/login.component';
import { PageNotFoundComponentComponent } from './components/page-not-found-component/page-not-found-component.component';
import { LayoutComponent } from './components/layout/layout.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { NavComponent } from './components/nav/nav.component';
import { MenuComponent } from './components/menu/menu.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { VehiculosComponent } from './components/vehiculos/vehiculos.component';
import { TipoVehiculoComponent } from './components/tipo-vehiculo/tipo-vehiculo.component';
import { ReportesComponent } from './components/reportes/reportes.component';
import { ReportesRangoComponent } from './components/reportes-rango/reportes-rango.component';
import { HttpClientModule } from '@angular/common/http';
import { FlotasComponent } from './components/flotas/flotas.component';
import { EmpresasComponent } from './components/empresas/empresas.component';
import { PerfilComponent } from './components/perfil/perfil.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    LoginComponent,
    PageNotFoundComponentComponent,
    LayoutComponent,
    InicioComponent,
    NavComponent,
    MenuComponent,
    UsuariosComponent,
    VehiculosComponent,
    TipoVehiculoComponent,
    ReportesComponent,
    ReportesRangoComponent,
    FlotasComponent,
    EmpresasComponent,
    PerfilComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ImageCropperModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { 
  // constructor( private bsLocaleService: BsLocaleService){
  //   this.bsLocaleService.use('es');//fecha en español, datepicker
  // }
}
