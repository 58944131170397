<div class="container-fluid">
    <h1 class="h3 mb-2 text-gray-800">Gestión de Flotas</h1>
    <!-- <p class="mb-4">Descripción del módulo.</p> -->
    <div class="card shadow border-bottom-{{global.clase_menu}} shadow h-100">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold">
          <button class="btn btn-{{global.clase_menu}} btn-icon-split" data-toggle="modal" data-target="#modal-nuevo">
            <span class="icon text-white-50">
              <i class="fas fa-user-plus"></i>
            </span>
            <span class="text">Nueva Flota</span>
          </button>
        </h6>
      </div>
      <div class="card-body pl-3 pr-3">
        <div class="table-responsive">
          <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
            <thead>
              <tr>
                <th width="500px">Nombre</th>
                <th>Descripción</th>
                <th width="150px" class="text-center">Acciones</th>
              </tr>
            </thead>
          </table>
        </div>
    </div>
  </div>

  <div class="modal fade" id="modal-nuevo" tabindex="-1" role="dialog" aria-labelledby="modal-nuevo" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Registro de nueva Flota</h5>
          <button class="close" type="button" (click)="CerrarModal()" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="card-body p-0">
            <div class="row ml-0 mr-0">
              <!-- <div class="col-lg-5 d-none d-lg-block bg-register-image"></div> -->
              <div class="col-lg-12">
                <div class="p-2">
                  <form class="user" (ngSubmit)="Registrar($event)" [formGroup]="registerForm">
                    <div class="form-group row">
                      <div class="col-sm-6">
                        <label>Nombre: *</label>
                        <input type="text" formControlName="name" class="form-control form-control-user" dd123="name *" required>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Descripción: *</label>
                      <input type="text" formControlName="description" class="form-control form-control-user" dd123="Descripción *" required>
                    </div>
                    <div class="row d-none">
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label>Id: </label>
                          <input type="text" formControlName="id" class="form-control form-control-user" dd123="Id *" required>
                        </div>
                      </div>
                    </div>
                    <input type="submit" class="d-none" id="enviar-formulario">
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" id="btn-cerrar-modal" type="button" (click)="CerrarModal()" >Cancelar</button>
          <button class="btn btn-primary" (click)="Enviar()">Registrar</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="modal-editar" tabindex="-1" role="dialog" aria-labelledby="modal-editar" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edición de Flota</h5>
          <button class="close" type="button" (click)="CerrarModalE()" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="card-body p-0">
            <div class="row ml-0 mr-0">
              <!-- <div class="col-lg-5 d-none d-lg-block bg-register-image"></div> -->
              <div class="col-lg-12">
                <div class="p-2">
                  <form class="user" (ngSubmit)="Editar($event)" [formGroup]="editForm">
                    <div class="form-group">
                      <label>Nombre:</label>
                      <input type="text" formControlName="name" class="form-control form-control-user" dd123="name *" required>
                    </div>
                    <div class="form-group">
                      <label>Descripción:</label>
                      <input type="text" formControlName="description" class="form-control form-control-user" dd123="Descripción *" required>
                    </div>
                    <div class="row d-none">
                      <div class="col-sm-4">
                        <div class="form-group">
                          <label>Id: </label>
                          <input type="text" formControlName="id" class="form-control form-control-user" dd123="Id *" required>
                        </div>
                      </div>
                      
                    </div>
                    
                    <input type="submit" class="d-none" id="enviar-formulario-e">
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" id="btn-cerrar-modal-e" type="button" (click)="CerrarModalE()" >Cancelar</button>
          <button class="btn btn-primary" (click)="EnviarE()">Guardar</button>
        </div>
      </div>
    </div>
  </div>

