import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from '../../services/global.service';
declare var $: any;
declare var Swal: any;
@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.less']
})
export class UsuariosComponent implements OnInit {

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";

  registerForm = new FormGroup({
    email: new FormControl('',[ Validators.required, Validators.pattern(this.emailPattern) ]),
    name: new FormControl('',Validators.required),
    apellido_paterno: new FormControl('',Validators.required),
    apellido_materno: new FormControl(''),
    user_type: new FormControl('.', [Validators.required,Validators.minLength(2)]),
    cargo: new FormControl('',Validators.required),
    password: new FormControl('', Validators.required),
    repit_password: new FormControl('', Validators.required)
  });

  editForm = new FormGroup({
    email: new FormControl({ value:"", disabled:true}),
    name: new FormControl('',Validators.required),
    apellido_paterno: new FormControl('',Validators.required),
    apellido_materno: new FormControl(''),
    cargo: new FormControl('',Validators.required),
    user_type: new FormControl('.', [Validators.required,Validators.minLength(2)]),
    password: new FormControl(''),
    // repit_password: new FormControl('')
  });

  constructor(public global:GlobalService) {}
  Listado_Usuarios:any = [];
  Tabla_de_Datos:any;
  ngOnInit(): void {
    this.Tabla_de_Datos = $('#dataTable').DataTable({
      dom: '<"datatable-header"f<"toolbar">><"datatable-scroll"t><"datatable-footer"i>',
      scrollX: false,
      scrollCollapse: false,
      lengthMenu: [[-1, 50, 25, 10, 5],["Todos", 50, 25, 10, 5]],
      aaSorting: [[5, "asc"]],
      language: {search: "<span >Buscar Usuario:</span> _INPUT_",lengthMenu: "<label>Cantidad de registros mostrados</label>_MENU_",paginate: {first: "Primero",last: "Ultimo",next: "→",previous: "←"},decimal: "",emptyTable: "No existen registros con los filtros indicados.",info: "Mostrando _START_ a _END_, de un total de _TOTAL_ registros",infoEmpty: "",infoFiltered: "(Filtrando de un máximo de _MAX_ registros).",infoPostFix: "",thousands: ",",loadingRecords: "Cargando",processing: "Procesando",zeroRecords: "No se encontraron usuarios registrados."},
      processing: true,
      data: [],
      columnDefs:[
        { className: "text-center", targets: [3,4,5] },
        { visible: false, targets: [6] }
      ],
      columns: [{data:"nombre"},{data:"cargo"},{data:"correo"}, {data:"tipo_usuario"},{data:"estado"},{data:"acciones"},{data:"fecha_creacion"}],ordering: true});
    let self = this;
    this.global.ShowLoading();
    this.global.getUsuarios(Response=>{
      let User_Aux = [];
      this.global.ShowLoading();
      for(let i in Response.val()){
        let User = Response.val()[i];
        User.id = i;
        let action = "CargarUsuario";
        let push_data = {
          nombre: ([User.name,User.apellido_paterno,User.apellido_materno]).join(" "),
          correo: User.email?User.email:"-",
          cargo: User.cargo?User.cargo:"-",
          estado: User.active?"<span class='badge badge-success' style='font-size: 16px;'>Activo</span>":"<span class='badge badge-danger' style='font-size: 16px;'>Bloqueado</span>",
          fecha_creacion: User.created_date,
          tipo_usuario: User.user_type?User.user_type:"-",
          acciones: `
            <button class="${User.master&&this.global.User.email!=User.email?'disabled':''} btn btn-info btn-icon-split mr-1" data-toggle="tooltip" ${User.master&&this.global.User.email!=User.email?'data-button-edit-none':'data-button-edit'}="${User.email}" title="Editar"><span class="icon"><i class="fas fa-user-edit"></i></span></button>
            <button ${this.global.User.email===User.email ?'disabled':''} ${User.master&&this.global.User.email!=User.email?'data-button-active-none':'data-button-active'}="${User.email}" data-button-active-state="${User.active}" class="${User.master&&this.global.User.email!=User.email?'disabled':''} btn btn-${User.active?"warning": "success"} btn-icon-split mr-1" data-toggle="tooltip" title="${User.active?"Bloquear Cuenta": "Habilitar Cuenta"}"><span class="icon"><i class="fas fa-exclamation-triangle"></i></span></button>
            <button ${this.global.User.email===User.email?'disabled':''} class="${User.master&&this.global.User.email!=User.email?'disabled':''} btn btn-danger btn-icon-split" data-toggle="tooltip" ${User.master&&this.global.User.email!=User.email?'data-button-delete-none':'data-button-delete'}="${User.email}" title="Borrar"><span class="icon"><i class="fas fa-trash"></i></span></button>`
        }
        if(!User.delete){
          if(this.global.ID_EMPRESA_ACTUAL!="integritic" && User.user_integritic){
            if(this.global.User.superadmin){
              User_Aux.push(push_data)   
            }
          }
            else User_Aux.push(push_data)
        }
      }
      // <button ${this.global.User.email===User.email?'disabled':''} disabled class="btn btn-success btn-icon-split mr-1" data-toggle="tooltip" title="Permisos"><span class="icon"><i class="fas fa-key"></i></span></button>
      this.Listado_Usuarios = User_Aux;
      this.Tabla_de_Datos.clear().draw();
      this.Tabla_de_Datos.rows.add(User_Aux).draw();
      this.global.HideLoading();
      //setTimeout(()=>$('[data-toggle="tooltip"]').tooltip(),0);

      /** Carga eventos de botoones **/
      $("[data-button-edit]").bind("click").on("click", E=>{
         let id = $(E.currentTarget).data('button-edit');
         this.CargarUsuario(id);
      });
      $("[data-button-delete]").bind("click").on("click", E=>{
         let id = $(E.currentTarget).data('button-delete');
         this.EliminarUsuario(id);
      });
      $("[data-button-active]").bind("click").on("click", E=>{
         let id = $(E.currentTarget).data('button-active');
         let state = $(E.currentTarget).data('button-active-state');
         this.Habilitar(id,state);

      });

    });
  }

  Usuario_Actual:any= {};
  EditarUsuario(E){
    E.preventDefault();
    E.stopPropagation();
    let _continue_ = true;
    for(let i in this.editForm.controls){
      if(!this.editForm.controls[i].valid && i != "email"){
        $("#modal-editar-usuario [formControlName="+i+"]").addClass("is-invalid");
        _continue_ = false;
      }
    }
    if(!_continue_){
      setTimeout(()=>$("#modal-editar-usuario [formControlName]").removeClass("is-invalid"), 1000);
      return;
    }
    if(this.editForm.valid){
      let User = this.editForm.value;
      for(let i in this.editForm.controls){
        console.log(i)
        if(i === "password" && this.editForm.controls[i].value.length === 0){}
          else this.Usuario_Actual[i] = this.editForm.controls[i].value;
      }
      this.Usuario_Actual.active===undefined?this.Usuario_Actual.active=true:false;
      if(this.Usuario_Actual.user_type==="Administrador"){
        this.Usuario_Actual.admin = true;
        this.Usuario_Actual.user = false;
      }
      if(this.Usuario_Actual.user_type==="Normal"){
        this.Usuario_Actual.admin = false;
        this.Usuario_Actual.user = true;
      }
      if(this.Usuario_Actual.user_type==="Chofer"){
        this.Usuario_Actual.admin = false;
        this.Usuario_Actual.user = true;
      }
      this.global.addUserDB(this.Usuario_Actual);
      this.CerrarModalE();
    }
  }

  Habilitar = (id, state)=>{

    const Habilitado = this.Usuario_Actual.active;
    this.global.Alert({
      title: '¡Atención!',
      text: Habilitado?"¿Segur@ que deseas bloquear el acceso a la plataforma para el usuario?":"¿Segur@ que deseas habilitar el acceso a la plataforma para el usuario?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.Usuario_Actual = {};
        this.global.getUserDB(id, R=>{
         let User = R.val();
         this.Usuario_Actual = User;
         this.Usuario_Actual.active = this.Usuario_Actual.active ? false: true;
         this.global.addUserDB(this.Usuario_Actual);
         Swal.fire(
          '!'+(Habilitado?'Bloqueado':'Habilitado')+'!',
          'Se ha modificado con éxito.',
          'success'
        );
        });
      }
    });
  }

  EliminarUsuario = (id)=>{
    this.global.Alert({
      title: '¡Atención!',
      text: "¿Segur@ que deseas eliminar el usuario?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          '!Eliminado!',
          'Se ha eliminado con éxito.',
          'success'
        );
        this.Usuario_Actual = {};
        this.global.getUserDB(id, R=>{
         let User = R.val();
         this.Usuario_Actual = User;
         this.Usuario_Actual.delete = true;
          this.global.addUserDB(this.Usuario_Actual);
        });
      }
    });
  }

  CargarUsuario = (id)=>{
    this.Usuario_Actual = {};
    this.global.getUserDB(id, R=>{
     let User = R.val();
     this.Usuario_Actual = User;
     this.editForm = new FormGroup({
      email: new FormControl({ value: User.email, disabled:true}),
      name: new FormControl(User.name,Validators.required),
      cargo: new FormControl(User.cargo,Validators.required),
      apellido_paterno: new FormControl(User.apellido_paterno,Validators.required),
      apellido_materno: new FormControl(User.apellido_materno),
      password: new FormControl(''),
      user_type: new FormControl(User.user_type, [Validators.required,Validators.minLength(2)]),
    });
    $("#modal-editar-usuario").modal()
   });
  }

  RegistrarUsuario(E){
    E.preventDefault();
    E.stopPropagation();
    let _continue_ = true;
    for(let i in this.registerForm.controls){
      if(!this.registerForm.controls[i].valid){
        $("#modal-nuevo-usuario [formcontrolname="+i+"]").addClass("is-invalid");
        _continue_ = false;
      }
    }
    if(!_continue_){
      setTimeout(()=>$("#modal-nuevo-usuario [formcontrolname]").removeClass("is-invalid"), 1000);
      return;
    }

    if(this.registerForm.controls.password.value!=this.registerForm.controls.repit_password.value){
      _continue_ = false;
      this.global.Alert({
        icon: 'warning',
        title: 'Oops...',
        text: 'Las contraseñas no coinciden.'
      });
     $("#modal-nuevo-usuario [formcontrolname=password]").addClass("is-invalid");
     $("#modal-nuevo-usuario [formcontrolname=repit_password]").addClass("is-invalid");
    }

    if(!_continue_){
      setTimeout(()=>$("#modal-nuevo-usuario [formcontrolname]").removeClass("is-invalid"), 3000);
      return;
    }

    if(this.registerForm.valid){
      let New_User = this.registerForm.value;
      delete New_User.repit_password;
      this.global.getUserDB(New_User.email, Response=>{
        let self = this;
        if(Response.val()===null || Response.val().delete === true){
          New_User.active = true;
          New_User.delete = false;
          New_User.id_empresa = self.global.ID_EMPRESA_ACTUAL;
          if(New_User.user_type==="Administrador"){
            New_User.admin = true;
            New_User.user = false;
          }
          if(New_User.user_type==="Normal"){
            New_User.admin = false;
            New_User.user = true;
          }
          if(New_User.user_type==="Chofer"){
            New_User.admin = false;
            New_User.user = true;
          }
          New_User.created_date = new Date().toISOString();
          self.global.addUserDB(New_User);
          self.global.Alert({
            icon: 'success',
            title: '',
            text: 'Usuario registrado correctamente.'
          })
          self.CerrarModal();
        }
        else{
          self.global.Alert({
            icon: 'warning',
            title: 'Oops...',
            text: 'El correo ingresado ya se encuentra registrado.'
          });
          $("[formcontrolname=email]").addClass("is-invalid");
          setTimeout(()=>$("[formcontrolname]").removeClass("is-invalid"), 3000);
        }
      });

    }
  }

  CerrarModal(){
    $("#modal-nuevo-usuario").modal('hide')
    this.registerForm = new FormGroup({
      email: new FormControl('',[ Validators.required, Validators.pattern(this.emailPattern) ]),
      name: new FormControl('',Validators.required),
      cargo: new FormControl('',Validators.required),
      apellido_paterno: new FormControl('',Validators.required),
      apellido_materno: new FormControl(''),
      user_type: new FormControl('.', [Validators.required,Validators.minLength(2)]),
      password: new FormControl('', Validators.required),
      repit_password: new FormControl('', Validators.required)
    });
  }

  CerrarModalE(){
    $("#modal-editar-usuario").modal('hide')
    this.editForm = new FormGroup({
      email: new FormControl({ value:"", disabled:true}),
      name: new FormControl('',Validators.required),
      cargo: new FormControl('',Validators.required),
      apellido_paterno: new FormControl('',Validators.required),
      apellido_materno: new FormControl(''),
      user_type: new FormControl('.', [Validators.required,Validators.minLength(2)]),
      password: new FormControl('', Validators.required),
      // repit_password: new FormControl('', Validators.required)
    });
  }

  Enviar(){
    $("#enviar-formulario").click();
  }

  EnviarE(){
    $("#enviar-formulario-e").click();
  }

}
