import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from '../../services/global.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

declare var $: any;
declare var Swal: any;

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.less']
})
export class PerfilComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageCharged: boolean = false;
  imageB64Initial:string = "";
  datosUsuario:any =[];
  constructor(public global:GlobalService) {}

  name:string = '';
  apellido_paterno:string = '';
  apellido_materno:string = '';
  email:string = '';
  telefono:string = '';
  cargo:string = '';

  clave_1:string = '';
  clave_2:string = '';

  ngOnInit(): void {

      let self = this;
      this.datosUsuario = [];

      this.name = this.global.User.name;
      this.apellido_paterno = this.global.User.apellido_paterno;
      this.apellido_materno = this.global.User.apellido_materno;
      this.telefono = this.global.User.telefono;
      this.cargo = this.global.User.cargo;
      this.email = this.global.User.email;


      if(this.global.EnProcesoDeCambioDeClaveObligatorio===true){
        this.global.Alert({
          icon: 'warning',
          title: 'Oops...',
          text: 'Por seguridad, debe actualizar su contraseña.'
        });
      }
      self.imageB64Initial = this.global.User.imagen;
  }

  update(attr, nombre){
    let newA = [];
    for(let i = 0; i < this.datosUsuario.length; i++)
      if(attr!=this.datosUsuario[i].id)
        newA.push(this.datosUsuario[i])
    this.datosUsuario = newA;
    this.datosUsuario.push({id: attr, nombre: nombre,value: this[attr]})
  }


  async guardarPerfil(){
    for(let i=0; i < this.datosUsuario.length;i++){
      if(this.datosUsuario[i].value.toString().length === 0){
        return this.global.Alert({
          icon: 'warning',
          title: 'Oops...',
          text: `El campo "${this.datosUsuario[i].nombre}" no tiene el formato correcto.`
        });
      }
      this.global.User[this.datosUsuario[i].id] = this.datosUsuario[i].value;
    }
    console.log(this.imageCharged)
    if(this.imageCharged){
      this.global.ShowLoading();

      let imgResized = await this.global.reducirImagenFija(this.croppedImage,200,200);

      this.global.uploadImage(imgResized,btoa(this.global.User.email)+"_imagen_perfil").then(response=>{
        console.log(this.global.User)
        this.global.User.imagen = response+"?v="+Math.random().toString(36).slice(-4);
        console.log(this.global.User.imagen)
        this.global.addUserDB(this.global.User);
        this.global.setUser(this.global.User);
        this.global.Alert({
          icon: 'success',
          title: '',
          text: 'El perfil se ha actualizado correctamente.'
        });
      });
    }
    else{
      this.global.addUserDB(this.global.User);
      this.global.setUser(this.global.User);
      this.global.Alert({
        icon: 'success',
        title: '',
        text: 'El perfil se ha actualizado correctamente.'
      });
    }
  }
  guardarContrasenia(){
    if(this.clave_1==="" || this.clave_1.length < 6){
      return this.global.Alert({
        icon: 'warning',
        title: 'Oops...',
        text: 'El campo "Contraseña" no tiene el formato correcto.'
      });
    }
    if(this.clave_2==="" || this.clave_1.length < 6){
      return this.global.Alert({
        icon: 'warning',
        title: 'Oops...',
        text: 'El campo "Repita Contraseña" no tiene el formato correcto.'
      });
    }
    if(this.clave_2!=this.clave_1){
      return this.global.Alert({
        icon: 'warning',
        title: 'Oops...',
        text: 'Las contraseñas no coinciden.'
      });
    }
    if(this.clave_2===this.clave_1){
       this.global.User.password =  this.clave_1;
       this.global.addUserDB(this.global.User);
       this.clave_1 = "";
       this.clave_2 = "";
       this.global.removeClaveTemporal(this.global.User.email);
       this.global.setEnProcesoDeCambioDeClaveObligatorio(false);
       this.global.setHiddenMenuComplete(false);
       return this.global.Alert({
        icon: 'success',
        title: '',
        text: 'Contraseña actualizada correctamente.'
      });
    }
  }
  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
  }
  imageCropped(event) {
      this.croppedImage = event.base64;
      this.global.User.imagen = this.croppedImage;
  }
  imageLoaded() { this.imageCharged = true; }
  cropperReady(){}
  loadImageFailed(){}
}
