<div class="container-fluid">
    <h1 class="h3 mb-2 text-gray-800">Tipo de Vehículos</h1>
    <!-- <p class="mb-4">Descripción del módulo.</p> -->
    <div class="card shadow border-bottom-{{global.clase_menu}} shadow h-100">
      <div class="card-header py-3">
        <h6 class="m-0 font-weight-bold">
          <button class="btn btn-{{global.clase_menu}} btn-icon-split" data-toggle="modal" data-target="#modal-nuevo-tipo">
            <span class="icon text-white-50">
              <i class="fas fa-user-plus"></i>
            </span>
            <span class="text">Nuevo Tipo de Vehículo</span>
          </button>
        </h6>
      </div>
      <div class="card-body pl-3 pr-3">
        <div class="table-responsive">
          <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
            <thead>
              <tr>
                <th>Tipo</th>
                <th width="50px" class="text-center">Descripción</th>        
                <th width="150px" class="text-center">Acciones</th>
              </tr>
            </thead>
          </table>
        </div>
    </div>
  </div>
  <div class="modal fade" id="modal-nuevo-tipo" tabindex="-1" role="dialog" aria-labelledby="modal-nuevo-tipo" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Registro de tipo de Vehículo</h5>
          <button class="close" type="button" (click)="CerrarModal()" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="card-body p-0">
            <div class="row ml-0 mr-0">
              <!-- <div class="col-lg-5 d-none d-lg-block bg-register-image"></div> -->
              <div class="col-lg-12">
                <div class="p-2">
                  <form class="user" (ngSubmit)="RegistrarTipoVehiculo($event)" [formGroup]="registerForm">
                    <div class="form-group row">
                      <div class="col-sm-6">
                        <label>Nombre: *</label>
                        <input type="text" formControlName="name" class="form-control form-control-user" dd123="name *" required>
                      </div>
                    </div>
                    <div class="form-group d-none">
                      <label>Icono:</label>
                      <!-- <input type="text" formControlName="icon" class="form-control form-control-user" dd123="icon *" required> -->
                      <select class="form-control tipo_icono" formControlName="icon" id="crearIcono" required>
                        <option value="" selected disabled >Seleccione una opción</option>
                        <option *ngFor="let tipo_v of global.Icono_vehiculos; let i = index" [value]="tipo_v.name">{{tipo_v.name}}<span class="icon-mixer"></span>
                        </option>
                      </select>
                    </div>   
                    <div class="form-group">
                      <label>Imagen:</label><br>
                      <input type="file" id="file2" accept="image/png"  class="form-control" style="height: 45px; margin-bottom: 5px;" (change)="fileChangeEvent2($event)">
                      <div class="cropper-wrapper">

                      <image-cropper
                        [ngStyle]="{width: '100%',maxHeight: '80px'}"
                        [imageChangedEvent]="imageChangedEvent2"
                        [imageURL]="imageB64Initial2"
                        [maintainAspectRatio]="false"

                        format="png"
                        [style.display]="imageCharged2 ? '' : 'none'"

                        (imageCropped)="imageCropped2($event)"
                        (imageLoaded)="imageLoaded2()"
                        (cropperReady)="cropperReady2()"
                        (loadImageFailed)="loadImageFailed2()"></image-cropper>

                    </div>
                    <img class="d-none" [src]="croppedImage" />
                  </div>
                    <div class="form-group">
                      <label>Descripción: *</label>
                      <input type="text" formControlName="description" class="form-control form-control-user" dd123="Descripción *" required>
                    </div>  
                    <div class="form-group">                       
                        <input type="hidden" formControlName="id" class="form-control form-control-user" dd123="Id *" required>
                    </div>                 
                    <input type="submit" class="d-none" id="enviar-formulario">
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" id="btn-cerrar-modal" type="button" (click)="CerrarModal()" >Cancelar</button>
          <button class="btn btn-primary" (click)="Enviar()">Registrar</button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal fade" id="modal-editar-tipo" tabindex="-1" role="dialog" aria-labelledby="modal-editar-tipo" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Edición Tipo de Vehículo</h5>
          <button class="close" type="button" (click)="CerrarModalE()" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body p-0">
          <div class="card-body p-0">
            <div class="row ml-0 mr-0">
              <!-- <div class="col-lg-5 d-none d-lg-block bg-register-image"></div> -->
              <div class="col-lg-12">
                <div class="p-2">
                  <form class="user" (ngSubmit)="EditarTipo($event)" [formGroup]="editForm">
                    <div class="form-group">
                      <label>Nombre:</label>
                      <input type="text" formControlName="name" class="form-control form-control-user" dd123="name *" required>
                    </div>
                    <div class="form-group d-none" >
                      <label>Icono:</label>
                        <span *ngIf="!Icono_actual_isB64" class="icon-{{this.Icono_actual}}" style="; margin-left: 20px; font-size:40px"></span>
                        <span *ngIf="Icono_actual_isB64" class="" style="font-size:40px; margin-left: 20px; "><img [src]="Icono_actual" style="height: 40px;"></span>
                      <br>
                      <!-- <input type="text" formControlName="icon" class="form-control form-control-user" dd123="icon *" required> -->
                      <select class="form-control form-control-user tipo_icono" formControlName="icon" id="editar_icono" >
                        <option value="" selected disabled >Seleccione una opción</option>
                        <option *ngFor="let tipo_v_icon of global.Icono_vehiculos; let i = index" [value]="tipo_v_icon.name">{{tipo_v_icon.name}}
                        </option>
                      </select>
                    </div> 
                    <div class="form-group">
                      <label>Imagen:</label><br>
                      <input type="file" id="file" accept="image/png"  class="form-control" style="height: 45px; margin-bottom: 5px;" (change)="fileChangeEvent($event)">
                      <div class="cropper-wrapper">

                      <image-cropper
                        [ngStyle]="{width: '100%',maxHeight: '80px'}"
                        [imageChangedEvent]="imageChangedEvent"
                        [imageURL]="imageB64Initial"
                        [maintainAspectRatio]="false"

                        format="png"
                        [style.display]="imageCharged ? '' : 'none'"

                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()"
                        (cropperReady)="cropperReady()"
                        (loadImageFailed)="loadImageFailed()"></image-cropper>

                    </div>
                    <img class="d-none" [src]="croppedImage" />
                  </div>
                    <div class="form-group">
                      <label>Descripción:</label>
                      <input type="text" formControlName="description" class="form-control form-control-user" dd123="Descripción *" required>
                    </div>                  
                    <input type="submit" class="d-none" id="enviar-formulario-e">
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" id="btn-cerrar-modal-e" type="button" (click)="CerrarModalE()" >Cancelar</button>
          <button class="btn btn-primary" (click)="EnviarE()">Guardar</button>
        </div>
      </div>
    </div>
  </div>
  
