import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { LayoutComponent  } from '../../components/layout/layout.component';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.less']
})
export class NavComponent implements OnInit {

  @Input() layout: LayoutComponent;
  constructor(public global:GlobalService) { }

  ngOnInit(): void {
  }

}
