import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from '../../services/global.service';
declare var $: any;
declare var Swal: any;
@Component({
  selector: 'app-vehiculos',
  templateUrl: './vehiculos.component.html',
  styleUrls: ['./vehiculos.component.less']
})
export class VehiculosComponent implements OnInit {

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  Icono_actual:any;
  Listado_Flotas:any = [];
  registerForm = new FormGroup({
    imei: new FormControl('',[ Validators.required ]),
    patente: new FormControl('',[Validators.required, Validators.maxLength(6)]),
    identificador: new FormControl('',[Validators.required, Validators.maxLength(10)]),
    descripcion: new FormControl('',Validators.required),
    tipo: new FormControl('.', [Validators.required,Validators.minLength(2)]),
    observaciones: new FormControl(''),
    codigo_color: new FormControl(''),
    orden: new FormControl('')
  });
  Icono_actual_isB64:any = false;
  editForm = new FormGroup({
    imei: new FormControl({ value:"", disabled:true}),
    patente: new FormControl('',[Validators.required, Validators.maxLength(6)]),
    identificador: new FormControl('',[Validators.required, Validators.maxLength(10)]),
    descripcion: new FormControl('',Validators.required),
    tipo: new FormControl('.', [Validators.required,Validators.minLength(2)]),
    observaciones: new FormControl(''),
    codigo_color: new FormControl(''),
    orden: new FormControl('')
  });

  constructor(public global:GlobalService) {}
  Listado_Vehiculos:any = [];
  Tabla_de_Datos:any;
  Codigo_de_Color:string = "";
 
  ngOnInit(): void {
    this.init();
  }
  async init(){
    await this.global.getFlotasArray().then((Flotas:any)=>{
      let array_ = [];
        for(let index in Flotas){
            if(!Flotas[index].delete) array_.push(Flotas[index]);
        }
      this.Listado_Flotas = array_;
    });

    
    this.global.listenTipos(tipo=>{
        let aux = tipo.val();
        let array_ = [];
        for(let index in aux){
            if(!aux[index].delete) array_.push(aux[index]);
        }
        this.global.setTipos(array_);
    });

    this.Tabla_de_Datos = $('#dataTable').DataTable({dom: '<"datatable-header"f<"toolbar">><"datatable-scroll"t><"datatable-footer"i>',scrollX: false,scrollCollapse: false,lengthMenu: [[-1, 50, 25, 10, 5],["Todos", 50, 25, 10, 5]],aaSorting: [[7, "asc"]],language: {search: "<span >Buscar Vehículo:</span> _INPUT_",lengthMenu: "<label>Cantidad de registros mostrados</label>_MENU_",paginate: {first: "Primero",last: "Ultimo",next: "→",previous: "←"},decimal: "",emptyTable: "No existen registros con los filtros indicados.",info: "Mostrando _START_ a _END_, de un total de _TOTAL_ registros",infoEmpty: "",infoFiltered: "(Filtrando de un máximo de _MAX_ registros).",infoPostFix: "",thousands: ",",loadingRecords: "Cargando",processing: "Procesando",zeroRecords: "No se encontraron vehículos registrados."},processing: true,data: [],
      columnDefs:[{
          className: "text-center",
          targets: [1,2,3,4,6,7,8]
      },{ visible: false, targets: [9] }],
      columns: [{data:"descripcion"},{data:"patente"},{data:"identificador"},{data:"imei"},{data:"tipo"},{data:"observaciones"},{data:"estado"},{data:"orden"},{data:"acciones"},{data:"fecha_creacion"}],ordering: true});
    let self = this;
    this.global.ShowLoading();
    this.global.getVehiculos(Response=>{
      let Dispo_Aux = [];
      this.global.ShowLoading();
      for(let i in Response.val()){
        let Dispositivo = Response.val()[i];
        Dispositivo.id = i;
        let action = "CargarVehiculo";
        let push_data = {
          descripcion: Dispositivo.descripcion,
          imei: Dispositivo.imei?Dispositivo.imei:"-",
          identificador: Dispositivo.identificador?Dispositivo.identificador:"-",
          observaciones: Dispositivo.observaciones?Dispositivo.observaciones:"-",
          patente: Dispositivo.patente?Dispositivo.patente:"-",
          fecha_creacion: Dispositivo.created_date?Dispositivo.created_date:"-",
          estado: Dispositivo.active?"Activo":"Bloqueado",
          orden: Dispositivo.orden?Dispositivo.orden:"-",
          tipo: this.global.Tipos_de_Vehiculos.filter(A=>Dispositivo.tipo.toString()===A.id.toString())[0].name,
          acciones: `
            <button class="btn btn-info btn-icon-split mr-1" data-toggle="tooltip" data-button-edit="${Dispositivo.imei}" title="Editar"><span class="icon"><i class="fas fa-user-edit"></i></span></button>
            <button class="btn btn-danger btn-icon-split" data-toggle="tooltip" data-button-delete="${Dispositivo.imei}" title="Borrar"><span class="icon"><i class="fas fa-trash"></i></span></button>`
        }
        !Dispositivo.delete ? Dispo_Aux.push(push_data) : false;
      }
      //console.log(Dispo_Aux)
      this.Listado_Vehiculos = Dispo_Aux;
      this.Tabla_de_Datos.clear().draw();
      this.Tabla_de_Datos.rows.add(Dispo_Aux).draw();
      this.global.HideLoading();
      //setTimeout(()=>$('[data-toggle="tooltip"]').tooltip(),0);

      /** Carga eventos de botoones **/
      $("[data-button-edit]").bind("click").on("click", E=>{
         let id = $(E.currentTarget).data('button-edit');
         this.CargarVehiculo(id);
      });
      $("[data-button-delete]").bind("click").on("click", E=>{
         let id = $(E.currentTarget).data('button-delete');
         this.EliminarVehiculo(id);
      });
    });
    $(".select2").select2({
      tags: true,
      createTag: params=> false
    });
  }
  Vechiculo_Actual:any= {};
  EditarVehiculo(E){
    E.preventDefault();
    E.stopPropagation();
    $("#codigo_color").trigger("change")
    let _continue_ = true;
    for(let i in this.editForm.controls){
      if(!this.editForm.controls[i].valid && i != "imei"){
        $("#modal-editar-vehiculo [formControlName="+i+"]").addClass("is-invalid");
        _continue_ = false;
      }
    }
    if(!_continue_){
      setTimeout(()=>$("#modal-editar-vehiculo [formControlName]").removeClass("is-invalid"), 1000);
      return;
    }
    if(this.editForm.valid){
      let User = this.editForm.value;
      for(let i in this.editForm.controls) {
        if(i=="codigo_color")
        this.Vechiculo_Actual[i] = this.Codigo_de_Color
        else
        this.Vechiculo_Actual[i] = this.editForm.controls[i].value;
      }
      this.Vechiculo_Actual.active===undefined?this.Vechiculo_Actual.active=true:false;

      this.Vechiculo_Actual.flotas = $("#flotas-edit").val();

      this.global.addDispositivo(this.Vechiculo_Actual);
      this.CerrarModalE();
    }
    
  }
  EliminarVehiculo = (id)=>{
    this.global.Alert({
      title: '¡Atención!',
      text: "¿Segur@ que deseas eliminar el vehículo?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          '!Eliminado!',
          'Se ha eliminado con éxito.',
          'success'
        );
        this.Vechiculo_Actual = {};
        this.global.getDispositivo(id, R=>{
         let Dispositivo = R.val();
         this.Vechiculo_Actual = Dispositivo;
         this.Vechiculo_Actual.delete = true;
          this.global.addDispositivo(this.Vechiculo_Actual);
        });
      }
    });
  }
  CargarVehiculo = (id)=>{
    this.Vechiculo_Actual = {};
    this.global.getDispositivo(id, R=>{
     let Dispositivo = R.val();
     this.Vechiculo_Actual = Dispositivo;

     let iconSearch = this.global.Tipos_de_Vehiculos.filter(A=>Dispositivo.tipo.toString()===A.id.toString())[0];
     if(iconSearch.b64){
      this.Icono_actual_isB64 = true;
      this.Icono_actual = iconSearch.b64;
    }
    else{
      this.Icono_actual = iconSearch.icon;
      this.Icono_actual_isB64 = false;
    }

    $("#flotas-edit").val(Dispositivo.flotas).trigger("change");

     this.editForm = new FormGroup({
      imei: new FormControl({ value: Dispositivo.imei, disabled:true}),
      patente: new FormControl(Dispositivo.patente,[Validators.required, Validators.maxLength(6)]),
      descripcion: new FormControl(Dispositivo.descripcion,Validators.required),
      identificador: new FormControl(Dispositivo.identificador?Dispositivo.identificador:'',Validators.required),
      tipo: new FormControl(Dispositivo.tipo,[Validators.required,Validators.minLength(2)]),
      observaciones: new FormControl(Dispositivo.observaciones),
      codigo_color: new FormControl(Dispositivo.codigo_color),
      orden: new FormControl(Dispositivo.orden),

    }); 
    this.Codigo_de_Color = Dispositivo.codigo_color
    $("#modal-editar-vehiculo").modal()
   });
  }
  RegistrarVehiculo(E){
    E.preventDefault();
    E.stopPropagation();
    let _continue_ = true;
    for(let i in this.registerForm.controls){
      if(!this.registerForm.controls[i].valid){
        $("#modal-nuevo-vehiculo [formcontrolname="+i+"]").addClass("is-invalid");
        _continue_ = false;
      }
    }
    if(!_continue_){
      setTimeout(()=>$("#modal-nuevo-vehiculo [formcontrolname]").removeClass("is-invalid"), 1000);
      return;
    }

    if(this.registerForm.valid){
      let _New_ = this.registerForm.value;
      this.global.getDispositivo(_New_.imei, Response=>{
        let self = this;
        if(Response.val()===null || Response.val().delete === true){
          _New_.active = true;
          _New_.delete = false;
          _New_.id_empresa = self.global.ID_EMPRESA_ACTUAL;
          _New_.flotas = $("#flotas-new").val();
          _New_.created_date = new Date().toISOString();
          self.global.addDispositivo(_New_);
          self.global.Alert({
            icon: 'success',
            title: '',
            text: 'Vehículo registrado correctamente.'
          })
          self.CerrarModal();
        }
        else{
          self.global.Alert({
            icon: 'warning',
            title: 'Oops...',
            text: 'El IMEI ingresado ya se encuentra registrado.'
          });
          $("[formcontrolname=imei]").addClass("is-invalid");
          setTimeout(()=>$("[formcontrolname]").removeClass("is-invalid"), 3000);
        }
      });

    }
  }
  CerrarModal(){
    $("#modal-nuevo-vehiculo").modal('hide')
    this.registerForm = new FormGroup({
      email: new FormControl('',[ Validators.required, Validators.pattern(this.emailPattern) ]),
      name: new FormControl('',Validators.required),
      apellido_paterno: new FormControl('',Validators.required),
      apellido_materno: new FormControl(''),
      user_type: new FormControl('.', [Validators.required,Validators.minLength(2)]),
      password: new FormControl('', Validators.required),
      repit_password: new FormControl('', Validators.required)
    });
    $("#flotas-edit").val(null).trigger("change");
  }
  CerrarModalE(){
    $("#modal-editar-vehiculo").modal('hide')
    this.editForm = new FormGroup({
      email: new FormControl({ value:"", disabled:true}),
      name: new FormControl('',Validators.required),
      apellido_paterno: new FormControl('',Validators.required),
      apellido_materno: new FormControl(''),
      user_type: new FormControl('.', [Validators.required,Validators.minLength(2)]),
    });
    $("#flotas-edit").val(null).trigger("change");
  }
  Enviar(){
    $("#enviar-formulario").click();
  }
  EnviarE(){
    $("#enviar-formulario-e").click();
  }
  cambiarColor(value){
    this.Codigo_de_Color = value.target.value.toString().split("#")[1]
  }
}
