import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from '../../services/global.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

declare var $: any;
declare var Swal: any;
@Component({
  selector: 'app-empresas',
  templateUrl: './empresas.component.html',
  styleUrls: ['./empresas.component.less']
})
export class EmpresasComponent implements OnInit {


  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageCharged: boolean = false;
  imageB64Initial:string = "";

  imageChangedEvent2: any = '';
  croppedImage2: any = '';
  imageCharged2: boolean = false;
  imageB64Initial2:string = "";

  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  Tabla_de_Datos:any;
  Listado_Empresas:any = [];
  registerForm = new FormGroup({
    name: new FormControl('',[ Validators.required , Validators.maxLength(15)]),
    description: new FormControl('',Validators.required),
    x: new FormControl('',Validators.required),
    zoom: new FormControl('',Validators.required),
    y: new FormControl('',Validators.required),
    color_menu_logo: new FormControl('auto'),
    id: new FormControl({ value:getUniqueId(8), disabled:false})
  });

  editForm = new FormGroup({
    id: new FormControl({ value:"", disabled:true}),
    name: new FormControl('',[ Validators.required , Validators.maxLength(15)]),
    x: new FormControl('',Validators.required),
    zoom: new FormControl('',Validators.required),
    y: new FormControl('',Validators.required),
    color_menu_logo: new FormControl('auto'),
    description: new FormControl('',Validators.required)
  });

  constructor(public global:GlobalService) {}

  ngOnInit(): void {


    //speechSynthesis.getVoices()[31]



    this.Tabla_de_Datos = $('#dataTable').DataTable({dom: '<"datatable-header"f<"toolbar">><"datatable-scroll"t><"datatable-footer"i>',scrollX: false,scrollCollapse: false,lengthMenu: [[-1, 50, 25, 10, 5],["Todos", 50, 25, 10, 5]],aaSorting: [[0, "asc"]],language: {search: "<span >Buscar Empresa:</span> _INPUT_",lengthMenu: "<label>Cantidad de registros mostrados</label>_MENU_",paginate: {first: "Primero",last: "Ultimo",next: "→",previous: "←"},decimal: "",emptyTable: "No existen registros con los filtros indicados.",info: "Mostrando _START_ a _END_, de un total de _TOTAL_ registros",infoEmpty: "",infoFiltered: "(Filtrando de un máximo de _MAX_ registros).",infoPostFix: "",thousands: ",",loadingRecords: "Cargando",processing: "Procesando",zeroRecords: "No se encontraron empresas registradas."},processing: true,data: [],
      columnDefs:[{
          className: "text-center",
          targets: [1,2,3]
      },{ visible: false, targets: [3] }],
      columns: [{data:"name"},{data:"description"},{data:"acciones"},{data:"fecha_creacion"}],ordering: true});
    let self = this;
    this.global.ShowLoading();
    this.global.getEmpresas(Response=>{
      let Dispo_Aux = [];
      this.global.ShowLoading();
      for(let i in Response.val()){
        let Empresa = Response.val()[i];
        let push_data = {
          description: Empresa.description?Empresa.description:"-",
          name: (Empresa.name?Empresa.name:"-") + `<span class="icon-${Empresa.icon}" style="font-size:40px;margin-left:20px"></span> `,
          fecha_creacion: Empresa.created_date?Empresa.created_date:"-",
          // tipo: this.global.Tipos_de_Vehiculos.filter(A=>Empresa.tipo.toString()===A.id.toString())[0].name,
          acciones: `
            <button class="btn btn-info btn-icon-split mr-1" data-toggle="tooltip" data-button-edit="${Empresa.id}" title="Editar"><span class="icon"><i class="fas fa-user-edit"></i></span></button>
            <button class="btn btn-danger btn-icon-split" data-toggle="tooltip" data-button-delete="${Empresa.id}" title="Borrar"><span class="icon"><i class="fas fa-trash"></i></span></button>`
        }
        !Empresa.delete ? Dispo_Aux.push(push_data) : false;
      }
      this.Listado_Empresas = Dispo_Aux;
      this.Tabla_de_Datos.clear().draw();
      this.Tabla_de_Datos.rows.add(Dispo_Aux).draw();
      this.global.HideLoading();
      //setTimeout(()=>$('[data-toggle="tooltip"]').tooltip(),0);

      /** Carga eventos de botoones **/
      $("[data-button-edit]").bind("click").on("click", E=>{
         let id = $(E.currentTarget).data('button-edit');
         this.CargarEmpresa(id);
      });
      $("[data-button-delete]").bind("click").on("click", E=>{
         let id = $(E.currentTarget).data('button-delete');
         this.Eliminar(id);
      });

      function formatState (icon) {
        if (!icon.id) {
          return icon.id;
        }
        var $icon = $(
          `<span class="icon-${icon.id}" style="font-size:20px;margin-rigth:10px">  ${icon.id}</span>`
        );
        return $icon;
      };

      $(".tipo_icono").select2({
        templateResult: formatState
      });

    });
  }

  Empresa_Actual:any= {};
  Editar(E){
    //console.log(this.Empresa_Actual)
    E.preventDefault();
    E.stopPropagation();
    let _continue_ = true;
    for(let i in this.editForm.controls){
      if(!this.editForm.controls[i].valid && i != "id"){
        $("#modal-editar [formControlName="+i+"]").addClass("is-invalid");
        _continue_ = false;
      }
    }
    if(!_continue_){
      setTimeout(()=>$("#modal-editar [formControlName]").removeClass("is-invalid"), 1000);
      return;
    }
    if(this.editForm.valid){
      for(let i in this.editForm.controls) this.Empresa_Actual[i] = this.editForm.controls[i].value;
      this.Empresa_Actual.active===undefined?this.Empresa_Actual.active=true:false;
      let fnc = async()=>{
        this.global.ShowLoading();
        let imgResized = await this.global.reducirImagenManteniendoAspectRatio(this.croppedImage2,180);
          this.global.uploadImage(imgResized,btoa(this.Empresa_Actual.id)+"_logo_empresa").then(response=>{
          this.Empresa_Actual.image = response+"?v="+Math.random().toString(36).slice(-4);
          this.global.addEmpresa(this.Empresa_Actual);
           this.global.Alert({
            icon: 'success',
            title: '',
            text: 'Empresa registrada correctamente.'
          });

         });
          this.CerrarModalE();
      };
      if(this.croppedImage2.length>0){
         fnc();
      }
      else{
        this.global.addEmpresa(this.Empresa_Actual);
         this.global.Alert({
          icon: 'success',
          title: '',
          text: 'Empresa registrada correctamente.'
        });
         this.CerrarModalE();

      }
      
    }
  }


  Eliminar = (id)=>{
    this.global.Alert({
      title: '¡Atención!',
      text: "¿Segur@ que deseas eliminar la Empresa?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        this.Empresa_Actual = {};
        this.global.getEmpresa(id, R=>{
         let Dispositivo = R.val();
         this.Empresa_Actual = Dispositivo;
         this.Empresa_Actual.delete = true;
        this.global.addEmpresa(this.Empresa_Actual);
        Swal.fire(
          '!Eliminado!',
          'Se ha eliminado con éxito.',
          'success'
        );
      });

    }
    });
  }
  CargarEmpresa = (id)=>{
    this.Empresa_Actual = {};
    this.global.getEmpresa(id, R=>{
      let Empresa = R.val();
      this.Empresa_Actual = Empresa;
      this.imageB64Initial2 = Empresa.image;
      this.editForm = new FormGroup({
        id: new FormControl({ value: Empresa.id, disabled:true}),
        name: new FormControl(Empresa.name,[Validators.required, Validators.maxLength(15)]),
        x: new FormControl(Empresa.x,Validators.required),
        y: new FormControl(Empresa.y,Validators.required),
        color_menu_logo: new FormControl(Empresa.color_menu_logo),
        zoom: new FormControl(Empresa.zoom,Validators.required),
        description: new FormControl(Empresa.description,Validators.required)
      });
      $("#modal-editar").modal()
    });
  }
   RegistrarEmpresa(E){
    E.preventDefault();
    E.stopPropagation();
    let _continue_ = true;

    if(this.croppedImage.length>0){}

    for(let i in this.registerForm.controls){
      if(!this.registerForm.controls[i].valid){
        $("#modal-nuevo [formcontrolname="+i+"]").addClass("is-invalid");
        _continue_ = false;
      }
    }
    if(!_continue_){
      setTimeout(()=>$("#modal-nuevo [formcontrolname]").removeClass("is-invalid"), 1000);
      return;
    }

    if(this.registerForm.valid){
      let _New_ = this.registerForm.value;
        let self = this;
          _New_.active = true;
          _New_.delete = false;
          _New_.created_date = new Date().toISOString();
          let fnc = async()=>{
            self.global.ShowLoading();
            let imgResized = await self.global.reducirImagenManteniendoAspectRatio(self.croppedImage,180);
              self.global.uploadImage(imgResized,btoa(_New_.id)+"_logo_empresa").then(response=>{
              _New_.image = response+"?v="+Math.random().toString(36).slice(-4);
              self.global.addEmpresa(_New_);
               self.global.Alert({
                icon: 'success',
                title: '',
                text: 'Empresa registrada correctamente.'
              });

             });
              self.CerrarModal();
          };
          if(self.croppedImage.length>0){
             fnc();
          }
          else{
            self.global.addEmpresa(_New_);
             self.global.Alert({
              icon: 'success',
              title: '',
              text: 'Empresa registrada correctamente.'
            });
             self.CerrarModal();

          }
    }
    else console.log("formulario no valido.")
  }
  CerrarModal(){
    $("#modal-nuevo").modal('hide')
    this.registerForm = new FormGroup({
      name: new FormControl('',[ Validators.required , Validators.maxLength(15)]),
      description: new FormControl('',Validators.required),
      x: new FormControl('',Validators.required),
      y: new FormControl('',Validators.required),
      color_menu_logo: new FormControl('auto'),
      zoom: new FormControl('',Validators.required),
      id: new FormControl({ value:getUniqueId(8), disabled:false}),
    });
  }
  CerrarModalE(){
    $("#modal-editar").modal('hide')
    this.editForm = new FormGroup({
      id: new FormControl({ value:"", disabled:true}),
      x: new FormControl('',Validators.required),
      y: new FormControl('',Validators.required),
      color_menu_logo: new FormControl('auto'),
      name: new FormControl('',[ Validators.required , Validators.maxLength(15)]),
      zoom: new FormControl('',Validators.required),
      description: new FormControl('',Validators.required),
    });
  }
  Enviar(){
    $("#enviar-formulario").click();
  }
  EnviarE(){
    $("#enviar-formulario-e").click();
  }

  /**Eventos crooped **/
  fileChangeEvent(event: any): void { this.imageChangedEvent = event;}
  imageCropped(event) { this.croppedImage = event.base64; }
  imageLoaded() { this.imageCharged = true; }
  cropperReady(){}
  loadImageFailed(){}
  fileChangeEvent2(event: any): void { this.imageChangedEvent2 = event; }
  imageCropped2(event) { this.croppedImage2 = event.base64; }
  imageLoaded2() { this.imageCharged2 = true; }
  cropperReady2(){}
  loadImageFailed2(){}

}




 export function getUniqueId(parts: number): string {
  const stringArr = [];
  for(let i = 0; i< parts; i++){
    const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    stringArr.push(S4);
  }
  return stringArr.join('-');
}
