import { Component, OnInit, Input } from '@angular/core';
import { LayoutComponent  } from '../../components/layout/layout.component';
import { Router,  ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../services/global.service';
declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less']
})
export class MenuComponent implements OnInit {

  @Input() layout: LayoutComponent;
  Menu_Oculto = false;
  menus:any = [];
  Empresa:any = {};
  clase_menu:string = "";
  constructor(public router: Router, public global:GlobalService) {
    this.Empresa = this.global.getEmpresasLocal();
    console.log(this.Empresa);
  }

  ngOnInit(): void {
    console.log("MenuComponent:ngOnInit")
    this.global.listenEmpresa();
    $("#sidebarToggleTop").on("click", E=>{
      if($(".accordionSidebar-xs").hasClass("toggled")){
        this.global.setMenuOculto(this.global.MenuOculto===true?false:true);
      }
      else{
        this.global.setMenuOculto(this.global.MenuOculto===true?false:true);
      }
    });

    /** Si no existe la clase o le quieres cambiar el color, hay que hacerlo en style.less **/
    if(this.global.User["superadmin"]){
      this.clase_menu = "bg-dark";
      return;
    }
    if(this.global.User.admin){
      this.clase_menu = "bg-gradient-danger";
      return;
    }
    if(this.global.User.user){
      this.clase_menu = "bg-gradient-primary";
      return;
    }



  }

  Evento_Menu(){
    this.global.setMenuOculto(this.global.MenuOculto?false:true);
  }



}
