<div class="container-fluid pb-10">
  <h1 class="h3 mb-2 text-gray-800">Perfil</h1>
  <p class="mb-4">Descripción del módulo.</p>
  <div class="card border-bottom-{{global.clase_menu}}">
    <div class="card-header py-3 border-bottom-{{global.clase_menu}}">
      <h6 class="m-0 font-weight-bold">
        <div class="row">
          <div class="col-sm-8">
              <div class="row">
                <div class="form-group col-sm-4">
                    <label>Nombres:</label>
                    <input type="text" class="form-control" [value]="global.User.name?global.User.name:''" [(ngModel)]="name" (change)="update('name', 'Nombres')" maxlength="50">
                </div>
                <div class="form-group col-sm-4">
                    <label>Apellido Paterno:</label>
                    <input type="text" class="form-control" [value]="global.User.apellido_paterno?global.User.apellido_paterno:''" maxlength="50" [(ngModel)]="apellido_paterno" (change)="update('apellido_paterno','Apellido Paterno')">
                </div>
                <div class="form-group col-sm-4">
                    <label>Apellido Materno:</label>
                    <input type="text" class="form-control" [value]="global.User.apellido_materno?global.User.apellido_materno:''" maxlength="50" [(ngModel)]="apellido_materno" (change)="update('apellido_materno','Apellido Materno')">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-6">
                    <label>Correo:</label>
                    <input type="email" class="form-control" [value]="global.User.email?global.User.email:''" maxlength="50" disabled>
                </div>
                <div class="form-group col-sm-4">
                    <label>Teléfono:</label>
                    <input type="phone" class="form-control" [value]="global.User.telefono?global.User.telefono:''" maxlength="12" [(ngModel)]="telefono" (change)="update('telefono','Teléfono')">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-sm-6">
                    <label>Cargo:</label>
                    <input type="text" class="form-control" [value]="global.User.cargo?global.User.cargo:''"  maxlength="50" [(ngModel)]="cargo" (change)="update('cargo','Cargo')">
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <label>Imagen:</label><br>
              <input type="file" id="file" accept="image/png, image/jpeg"  class="form-control" style="height: 45px; margin-bottom: 5px;" (change)="fileChangeEvent($event)">
              <div class="cropper-wrapper">

              <image-cropper
                [ngStyle]="{width: '100%',maxHeight: '180px'}"
                [imageChangedEvent]="imageChangedEvent"
                [imageURL]="imageB64Initial"
                [maintainAspectRatio]="true"
                [aspectRatio]="4 / 4"
                format="png"
                [style.display]="imageCharged ? '' : 'none'"

                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()"></image-cropper>

            </div>
            <img class="d-none" [src]="croppedImage" />
          </div>
          </div>


          <div class="row">
            <div class="form-group pull-right col-sm-12">
              <button (click)="guardarPerfil()" class="btn btn-{{global.clase_menu}} btn-icon-split">
                <span class="icon text-white-50"><i class="fas fa-user-plus"></i></span>
                <span class="text">Actualizar Perfil</span>
              </button>
            </div>
          </div>
      </h6>


    </div>

  <div class="card-header py-3 border-bottom-{{global.clase_menu}}">
    <h6 class="m-0 font-weight-bold">
      <div class="row">
        <div class="form-group col-sm-6">
          <label>Contraseña: (mínimo 6 caracteres.)</label>
          <input type="password" class="form-control" [(ngModel)]="clave_1" maxlength="50">
        </div>
        <div class="form-group col-sm-6">
          <label>Repita Contraseña:</label>
          <input type="password" class="form-control" [(ngModel)]="clave_2" maxlength="50">
        </div>
      </div>
      <div class="row">
        <div class="form-group pull-right col-sm-12">
          <button (click)="guardarContrasenia()" class="btn btn-{{global.clase_menu}} btn-icon-split">
            <span class="icon text-white-50"><i class="fas fa-user-plus"></i></span>
            <span class="text">Actualizar Contraseña</span></button>
        </div>
      </div>
    </h6>
  </div>
</div>
