import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from '../../services/global.service';
declare var ol: any;
declare var firebase: any;
declare var $: any;

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.less']
})

export class InicioComponent implements OnInit {
  showMap = false;
  AutoZoom = true;
  SoloEncendidos = false;
  SoloFlotas = "ALL";
  AutoCenter = true;
  ultimaActualizacion:any = false;
  identificadorEquipo:any = false;
  imei:any = false;
  Listado_Flotas:any = [];
  estadoEquipo:any = false;
  datosAdicionalesEquipo:any = [];
  coordenadas:any = false;
  TipoGlobal:any = "ALL";
  ShowEmpresas:any = "ALL";
  Dispositivo_Actual:any = {};
  Icono_Dispositivo_Actual:any = {};
  VelocidadActual:any = "";
  Odometro:any = "";
  urlImgGPS:string = "https://cdn-sacyr.s3.amazonaws.com/c1.png";
  urlImgLoading:string = "https://cdn.dribbble.com/users/503653/screenshots/3143656/fluid-loader.gif" ;

  imgB64: any = "";
  ID_DB:string;

  Listado_Empresas:any = [];
  Listado_Vehiculos_Select:any = [];
  Listado_Vehiculos:any = [];
  globalX:any = -71.174460;
  globalY:any = -32.796171;
  globalZoom:any = 14;
  ObjectFlotas = {};

  constructor(public global:GlobalService) {
    this.ID_DB = this.global.ID_DB;
    this.global.ShowLoading();
    this.init();
  }
  async init(){
    console.log("InicioComponent:ngOnInit")
    await this.global.listenEmpresa().then((Empresa:any)=>{
      this.globalX = isNaN(Number(Empresa.x))?-71.174460:Number(Empresa.x);
      this.globalY = isNaN(Number(Empresa.y))?-32.796171:Number(Empresa.y);
      if(Empresa.zoom){
        this.globalZoom = isNaN(Number(Empresa.zoom))?14:Number(Empresa.zoom);
      }
      this.ShowEmpresas = Empresa.id;
    });
    
    if(this.global.User.superadmin){
      this.ShowEmpresas = "ALL";
    }

    await this.global.getEmpresasArray().then((Empresas:any)=>{
      let array_ = [];
        for(let index in Empresas){
            if(!Empresas[index].delete) array_.push(Empresas[index]);
        }
      this.Listado_Empresas = array_;
    });
    
    await this.global.getFlotasArray().then((Flotas:any)=>{
      let array_ = [];
      this.ObjectFlotas = Flotas;
        for(let index in Flotas){
            if(!Flotas[index].delete) array_.push(Flotas[index]);
        }
      this.Listado_Flotas = array_;
    });

    this._GLOBAL_VIEW_  = new ol.View({
      projection: 'EPSG:4326',
      center: [this.globalX, this.globalY],
      zoom: this.globalZoom
    });

    await this.global.listenTipos(tipo=>{
        let aux = tipo.val();
        let array_ = [];
        for(let index in aux){
            if(!aux[index].delete) array_.push(aux[index]);
        }
        this.global.setTipos(array_);
        this.global.getVehiculos(Response=>{
        let Dispo_Aux = [];
        for(let i in Response.val()){
          let Dispositivo = Response.val()[i];
          Dispositivo.id = i;
          let action = "CargarVehiculo";
          let TipoV = this.global.Tipos_de_Vehiculos.filter(A=>Dispositivo.tipo.toString()===A.id.toString())[0];
          let push_data = {
            descripcion: Dispositivo.descripcion,
            imei: Dispositivo.imei?Dispositivo.imei:"-",
            identificador: Dispositivo.identificador?Dispositivo.identificador:"-",
            observaciones: Dispositivo.observaciones?Dispositivo.observaciones:"-",
            patente: Dispositivo.patente?Dispositivo.patente:"-",
            color: Dispositivo.color?Dispositivo.color:"c1",
            codigo_color: Dispositivo.codigo_color?Dispositivo.codigo_color:"c1",
            fecha_creacion: Dispositivo.created_date?Dispositivo.created_date:"-",
            estado: Dispositivo.active?"Activo":"Bloqueado",
            tipo: this.global.Tipos_de_Vehiculos.filter(A=>Dispositivo.tipo.toString()===A.id.toString())[0].name,
            tipo_id: Dispositivo.tipo.toString(),
            flotas: Dispositivo.flotas?Dispositivo.flotas:[],
            id_empresa: Dispositivo.id_empresa.toString(),
            tipo_icon: TipoV.b64?TipoV.b64:TipoV.icon,
            isb64: TipoV.b64?true:false,
            orden: Dispositivo.orden?Dispositivo.orden:0,
          }
          !Dispositivo.delete ? Dispo_Aux.push(push_data) : false;
        }
        Dispo_Aux = Dispo_Aux.sort((A,B)=>A.orden-B.orden);
        this.Listado_Vehiculos = Dispo_Aux;
        this.Listado_Vehiculos_Select = Dispo_Aux;
        this.global.HideLoading();
        $("#seleccion-vehiculo").val("ALL");
        this.VerTodos();
      });
    });
  }
  ResetearSeleccion(){
    $("#seleccion-vehiculo").val("ALL").trigger("change");
    this.VerTodos();
  }
  VerVehiculo(Id){
     $("#seleccion-vehiculo").val(Id);
      this.seleccionarVehiculo(Id);
  }
  ngOnInit(): void {
    
  }
  _VER_TODOS_ = false;

  _GLOBAL_MAP_:any = null;
  _GLOBAL_VIEW_:any = {};
  ContadorDeVistos:number = 0;

  VerTodos=()=>{

    //this.TipoGlobal = "10002";
    this.ContadorDeVistos = 0;
    this.IdActualSelect = "ALL";
    this._VER_TODOS_ = true;
    this.AutoZoom = false;
    this.AutoCenter = false;
    this.showMap = true;
    $("#row-map").removeClass("d-none");
    $("#map").empty();
    $("#overlays").empty();

    //console.log(new ol.source.OSM())

    var attribution = new ol.control.Attribution({collapsible: true});
    this._GLOBAL_MAP_ = new ol.Map({
      controls: ol.control.defaults({attribution: false}).extend([attribution]),
      //layers: [new ol.layer.Tile({source: new ol.source.OSM()})],
      layers: [new ol.layer.Tile({source: new ol.source.OSM({attributions: ['@Integritic','2022'],url:`
        https://tile.openstreetmap.org/{z}/{x}/{y}.png
      `}),
      })],
      target: 'map',
      view: this._GLOBAL_VIEW_
    });

    let onMoveEnd = (evt)=> {
      if(evt.frameState.animate===false){
          setTimeout(()=>{},1000);
      }
      else{}
    }

    this._GLOBAL_MAP_.on('moveend', onMoveEnd);

    for(let i=0; i < this.Listado_Vehiculos.length; i++){
      if(!this.SoloEncendidos?true:(this.SoloEncendidos&&(this.Listado_Vehiculos[i].GPS?this.Listado_Vehiculos[i].GPS.ESTADO==='Encendido'?true:false:false))){
       this.Listado_Vehiculos[i].show = true; 
      }
      else{
       this.Listado_Vehiculos[i].show = false;  
      }
      if(this.Listado_Vehiculos[i].show){
        if(this.ShowEmpresas!="ALL"){
          if(this.Listado_Vehiculos[i].id_empresa!=this.ShowEmpresas){
            this.Listado_Vehiculos[i].show = false;  
          }
        }
      }
      if(this.Listado_Vehiculos[i].show){
        if(this.SoloFlotas!="ALL"){
          if(this.Listado_Vehiculos[i].flotas.filter(A=>A === this.SoloFlotas).length===0) 
            this.Listado_Vehiculos[i].show = false;
        }
      }
      if(this.Listado_Vehiculos[i].show){
        if(this.TipoGlobal!="ALL"){
          if(this.Listado_Vehiculos[i].tipo_id!=this.TipoGlobal){
            this.Listado_Vehiculos[i].show = false;  
          }
        }
      }
      if(this.Listado_Vehiculos[i].show){
        this.ContadorDeVistos++;
        this.addListenerMapaGlobal(this.Listado_Vehiculos[i]);
      }
    }
    this.Listado_Vehiculos_Select = this.Listado_Vehiculos.filter(A=>A.show);
  }

  LISTENER_POSICION_F:any = null;
  ID_ACTUAL:any = null;
  LISTENER_POSICION_F_ARRAY :any = {};
  /** Posiciionamiento en tiempo real de todos los equipos **/
  addListenerMapaGlobal = (Equipo)=>{
    let iF = new ol.Feature();
    let iSo = new ol.source.Vector({features: [iF]});
    let iL = new ol.layer.Vector({
      source: iSo
    });
    $("#overlays").append('<span id="overlay-'+Equipo.imei+'" class="vehiculo test" title="" style="background-color: #'+Equipo.codigo_color+';">'+Equipo.identificador+'</span>');
    this._GLOBAL_MAP_.addLayer(iL);
    let self = this;
    var overlay = new ol.Overlay({
      element: document.getElementById('overlay-'+Equipo.imei)
    });
    self._GLOBAL_MAP_.addOverlay(overlay);
    this.LISTENER_POSICION_F_ARRAY[Equipo.imei] = firebase.database().ref(this.ID_DB+"/EQUIPOS/"+Equipo.imei.toString()).on('value', function(snapshot) {
      if(snapshot.val() != null) { 
        Equipo.GPS = snapshot.val();
        let pos = [snapshot.val().ULTIMA_POSICION?snapshot.val().ULTIMA_POSICION.X:self.globalX, snapshot.val().ULTIMA_POSICION?snapshot.val().ULTIMA_POSICION.Y:self.globalY];
        iF.setGeometry(new ol.geom.Point(pos));
        if(snapshot.val().VELOCIDAD!=="0.0" || snapshot.val().VELOCIDAD!=="0"){
          $('#overlay-'+Equipo.imei).prop('title', "Vehículo "+Equipo.GPS.ESTADO+" "+Equipo.patente+" "+Equipo.observaciones+" " +snapshot.val().VELOCIDAD+" km/h");
        }
        else
        $('#overlay-'+Equipo.imei).prop('title', "Vehículo "+Equipo.GPS.ESTADO+" "+Equipo.patente+" "+Equipo.observaciones);
        overlay.setPosition([Number(pos[0]),(Number(pos[1])) ]);
      }
    });
  }
  _PantallaCompleta_:any = false;
  IdActualSelect:any  = "ALL";
  PantallaCompleta=()=>{
    if(this._PantallaCompleta_){

      $("#row-mapa-css").css("flex", "0 0 66.66667%");
      $("#row-mapa-css").css("max-width", "66.66667%");
      $("app-menu").show();
      $("app-nav").show();
      $("#map").css("height", "500px");
      this._PantallaCompleta_ = false;
    }
    else{

      $("#row-mapa-css").css("flex", "0 0 100%");
      $("#row-mapa-css").css("max-width", "100%");
      $("app-menu").hide();
      $("app-nav").hide();
      $("#map").css("height", "60vh");
      this._PantallaCompleta_ = true;
    }
    this.seleccionarVehiculo(this.IdActualSelect);
  }
  ArrayCoordenadas:any = {};
  addHours(a, h){
    a.setHours(a.getHours()+h);
    return a;
  }
  seleccionarVehiculo=(Obj)=>{

    for(let i=0; i < this.Listado_Vehiculos.length; i++){
      firebase.database().ref('GPS/EQUIPOS/'+this.Listado_Vehiculos[i].imei).off('value', this.LISTENER_POSICION_F_ARRAY[this.Listado_Vehiculos[i].imei]);
    }

    let idEquipo = Obj.target?Obj.target.value:Obj;
    this.IdActualSelect = idEquipo;
    if(idEquipo === "ALL") return this.VerTodos();
    this._VER_TODOS_ = false;
    this.AutoZoom = true;
    this.AutoCenter = true;
    let _self_ = this;
    this.global.getDispositivo(idEquipo, R=>{
      this.Dispositivo_Actual = R.val();
      let Strings = [];
      if(this.Dispositivo_Actual.flotas){
        for(let i=0; i < this.Dispositivo_Actual.flotas.length;i++){
          if(this.ObjectFlotas[this.Dispositivo_Actual.flotas[i]]){
            Strings.push(this.ObjectFlotas[this.Dispositivo_Actual.flotas[i]].name)
          }
        }
      }
      let Flotas_String = Strings.length===0?false:Strings.join(", ");
      this.Dispositivo_Actual.flotas_string = Flotas_String;
      console.log(this.Dispositivo_Actual);
      this.showMap = true;
      this.ultimaActualizacion = false;
      this.identificadorEquipo = false;
      this.imei = idEquipo;
      this.coordenadas = false;
      this.estadoEquipo = false;
      this.datosAdicionalesEquipo = false;

      let auxDi = this.global.Tipos_de_Vehiculos.filter(A=>this.Dispositivo_Actual.tipo.toString()===A.id.toString())[0];
      this.urlImgGPS = auxDi.b64?auxDi.b64:"https://cdn-sacyr.s3.amazonaws.com/"+this.Dispositivo_Actual.color+".png";
      this.Dispositivo_Actual.isb64 = auxDi.b64?true:false;
      this.Dispositivo_Actual.tipoNombre = auxDi.name;
      this.Dispositivo_Actual.tipo_icon = this.Dispositivo_Actual.isb64?auxDi.b64:auxDi.icon;

      $("#row-map").removeClass("d-none");
      $("#map").empty();
      $("#overlays").empty();

      var attribution = new ol.control.Attribution({collapsible: true});
      var view = new ol.View({projection: 'EPSG:4326',center: [this.globalX, this.globalY],zoom: 6});
      var iconStyle = new ol.style.Style({zIndex: 2,image: new ol.style.Icon({opacity: 1,scale: this.Dispositivo_Actual.isb64?.3:.12 ,src: this.urlImgGPS })});
      var iconFeature = new ol.Feature();
      var iconSource = new ol.source.Vector({features: [iconFeature]});
      //var iconLayer = new ol.layer.Vector({source: iconSource,style : iconStyle});
      let iconLayer = new ol.layer.Vector({
        source: iconSource,
         style : iconStyle
      });
      var baseLayer = new ol.layer.Tile({source: new ol.source.OSM({attributions: ['@Integritic','2022'],url:`
        https://tile.openstreetmap.org/{z}/{x}/{y}.png
     `}),
      });
      var map = new ol.Map({controls: ol.control.defaults({attribution: false}).extend([attribution]),layers: [baseLayer],target: 'map',view: view});
      let self = this;
      var last_co = [];

      firebase.database().ref('GPS/EQUIPOS/'+this.ID_ACTUAL).off('value', this.LISTENER_POSICION_F);
      if(this.ArrayCoordenadas[idEquipo]){

        for(let a=0; a < this.ArrayCoordenadas[idEquipo].length; a++){
            var features = [new ol.Feature({'geometry': new ol.geom.LineString(this.ArrayCoordenadas[idEquipo][a])})];
            var vector = new ol.layer.Vector({
              source: new ol.source.Vector({
                features: features,
                wrapX: false,
              }),
              style: new ol.style.Style({
                zIndex: 1,
                stroke: new ol.style.Stroke({
                  color: "black",
                  lineCap: 'round',
                  width: 8,
                }),
              }),
            });
            map.addLayer(vector);
        }
      }

      $('#overlay-'+this.Dispositivo_Actual.imei).prop('title', "Vehículo"+this.Dispositivo_Actual.patente+" "+this.Dispositivo_Actual.observaciones);

      var overlay = new ol.Overlay({
        element: document.getElementById('overlay-'+this.Dispositivo_Actual.imei)
      });
      map.addLayer(iconLayer);
      map.addOverlay(overlay);

      this.LISTENER_POSICION_F = firebase.database().ref(this.ID_DB+"/EQUIPOS/"+idEquipo).on('value', function(snapshot) {
        if(snapshot.val() != null) { 
          if(snapshot.val().IMEI.toString() === idEquipo.toString()){

            self.ID_ACTUAL = idEquipo.toString();

            var pos = [snapshot.val().ULTIMA_POSICION?snapshot.val().ULTIMA_POSICION.X:self.globalX, snapshot.val().ULTIMA_POSICION?snapshot.val().ULTIMA_POSICION.Y:self.globalY];

            pos[0] = Number(pos[0]);
            pos[1] = Number(pos[1]);

            if(!self.ArrayCoordenadas[idEquipo]) self.ArrayCoordenadas[idEquipo] = [];
            var features = [new ol.Feature({'geometry': new ol.geom.LineString([last_co, pos])})];

            var vector = new ol.layer.Vector({
              source: new ol.source.Vector({
                features: features,
                wrapX: false,
              }),
              style: new ol.style.Style({
                zIndex: 1,
                stroke: new ol.style.Stroke({
                  color: "black",
                  opacity: .3,
                  lineCap: 'round',
                  width: 8,
                }),
              }),
            });
            if(last_co.length>0){
              map.addLayer(vector);
              self.ArrayCoordenadas[idEquipo].push([last_co, pos]);
            }

            if(self.AutoZoom){
              view.setZoom(snapshot.val().ULTIMA_POSICION?15:6);
            }
            if(self.AutoCenter){
              view.setCenter(pos);
            }
            last_co = pos;

            if(snapshot.val().COURSE){
              iconStyle.getImage().setRotation((6.3*snapshot.val().COURSE)/360);
              iconFeature.changed();
            }
            iconFeature.setGeometry(new ol.geom.Point(pos));

            self.ultimaActualizacion = snapshot.val().ULTIMA_POSICION?self.addHours(new Date(snapshot.val().ULTIMA_POSICION.LAST_DATE), 4):'';
            self.coordenadas = (snapshot.val().ULTIMA_POSICION?snapshot.val().ULTIMA_POSICION.Y:self.globalX) + " " + (snapshot.val().ULTIMA_POSICION?snapshot.val().ULTIMA_POSICION.X:self.globalY);
            snapshot.val().ID ? self.identificadorEquipo = snapshot.val().ID : false;

            typeof snapshot.val().VELOCIDAD !== "undefined"?(self.VelocidadActual = snapshot.val().VELOCIDAD.toString()) : false;
            snapshot.val().KILOMETRAJE ? self.Odometro = snapshot.val().KILOMETRAJE === 0 ? "0.0": snapshot.val().KILOMETRAJE : false;
            snapshot.val().ESTADO ? self.estadoEquipo = snapshot.val().ESTADO : false;
            snapshot.val().DATOS_ADICIONALES ? self.datosAdicionalesEquipo = snapshot.val().DATOS_ADICIONALES : false;
            console.log(self.datosAdicionalesEquipo)

          }
        }
      });
    });
  }
}
