import { Injectable } from '@angular/core';
import { Router,  ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
declare var firebase: any;
declare var Swal: any;
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  private REST_API_SERVER = "https://api.integritic.cl/gps";

  base_url = location.host+"/";
  protocol = location.protocol+"//";
  onLogin = false;
  onRecuperando=false;
  sessionActive = false;
  User: any={};
  Empresa: any={};
  MenuOculto: any = false;
  Listener_Array: any = null;
  hiddenMenuComplete: boolean = false;
  EnProcesoDeCambioDeClaveObligatorio: boolean = false;
  Listener_Tipos: any = null;
  Listener_User: any = null;
  Listener_Empresa: any = null;
  Listener_Array_Tipos: any = null;
  ID_EMPRESA_ACTUAL:any = false;
  Menu_Array: any = [];
  clase_menu: string = "";
  ID_DB:string = "GPS";
  // ID_DB:string = "GPS_DEPLOY"; 
  CLAVE_MAESTRA: string = "Crci1704";
  Ruta_Actual = "";
  public Tipos_de_Vehiculos: any = [
    {
      id: "10002",
      name: "Camión Mixer",
      image: "",
      icon: "",
      descripcion: ""
    },
    // {
    //   id: "10001",
    //   name: "Auto",
    //   image: "",
    //   icon: ""
    // }
  ];

  public Icono_vehiculos: any = [
    {
      id: "900",
      name: "pluma"      
    },
    {
      id: "902",
      name: "mixer"      
    },   
    {
      id: "cubo",
      name: "Fiat Cubo",
      b64: ""
    }
  ];

  constructor(private router: Router,private httpClient: HttpClient) {
    console.log("hello from GlobalService")
    this.initFirebase();
    this.SetActualUrl(location.pathname);
    this.getSessionActive();
    this.getTiposLS();
    this.getUser();
    this.getMenuOculto();
    this.getMenuOcultoCompleto();
    this.getEnProcesoDeCambioDeClaveObligatorio();
    this.CheckAccesoRuta();

    //this.sessionActive?this.listenEmpresa(this.ID_EMPRESA_ACTUAL):false;

  }
  SetMenu(){
    let aux = [
      {
        url: "/Inicio",
        name: "Inicio",
        show: true,
        icon: "fas fa-fw fa-tachometer-alt",
        access: ["admin", "user"],
      },
      {
        url: "/Inicio/Seguimiento",
        name: "Ubicación en Tiempo Real",
        show: true,
        icon: "fas fa-location-arrow fa-beat",
        access: ["admin", "user"],
      },
      {
        url: "/Inicio/Perfil",
        name: "Gestión de Perfil",
        show: true,
        hidden: true,
        icon: "fas fa-building",
        access: ["user","admin","superadmin"],
      },
      {
        url: "/Inicio/Empresas",
        name: "Gestión de Empresas",
        show: true,
        icon: "fas fa-building",
        access: ["superadmin"],
      },
      {
        url: "/Inicio/Usuarios",
        name: "Gestión de Usuarios",
        show: true,
        icon: "fas fa-user-cog",
        access: ["admin"],
      },
      {
        url: "/Inicio/Vehiculos",
        name: "Gestión de Vehículos",
        show: true,
        icon: "fas fa-truck",
        access: ["admin"],
      },

      {
        url: "/Inicio/Tipos-de-Vehiculos",
        name: "Tipos de Vehículos",
        show: true,
        icon: "fas fa-list",
        access: ["admin"],
      },
      {
        url: "/Inicio/Flotas",
        name: "Gestión de Flotas",
        show: true,
        icon: "fas fa-layer-group",
        access: ["admin"],
      },
      {
        url: "/Inicio/Reportes",
        name: "Reportes",
        show: true,
        icon: "fas fa-file-alt",
        access: ["admin", "user"],
      },
      {
        url: "/Inicio/Reportes-rangos",
        name: "Reportes por rango",
        show: true,
        icon: "fas fa-file-alt",
        access: ["admin", "user"],
      }
    ];
    this.Menu_Array = [];
    for(let x = 0; x < aux.length; x++){
      const original_show = aux[x].show;
      aux[x].show = false;
      for(let i=0; i < aux[x].access.length; i++){
        if(this.User[aux[x].access[i]]) aux[x].show = true;
      }
      aux[x].show?original_show===false?false:this.Menu_Array.push(aux[x]):false;
    }
  }
  sendGetRequestRangos(id,dates){
    return this.httpClient.get(this.REST_API_SERVER+"/buscar/"+id+"/rango/"+dates);
  }
  checkLogin = () => this.sessionActive;
  getSessionActive(){
    this.sessionActive = localStorage.getItem("sessionActive")==null?false:localStorage.getItem("sessionActive").toString()==="true"?true:false;
    return this.sessionActive;
  }
  setMenuOculto(value){
    this.MenuOculto = value;
    return localStorage.setItem("MenuOculto", this.MenuOculto.toString());
  }
  setEmpresaLocal(value){
    this.Empresa = value;
    return localStorage.setItem("EmpresaActual", JSON.stringify(this.Empresa));
  }
  setEnProcesoDeCambioDeClaveObligatorio(value){
    this.EnProcesoDeCambioDeClaveObligatorio = value;
    return localStorage.setItem("EnProcesoDeCambioDeClaveObligatorio", this.EnProcesoDeCambioDeClaveObligatorio.toString());
  }

  getEnProcesoDeCambioDeClaveObligatorio(){
    this.EnProcesoDeCambioDeClaveObligatorio = localStorage.getItem("EnProcesoDeCambioDeClaveObligatorio")==null?false:localStorage.getItem("EnProcesoDeCambioDeClaveObligatorio").toString()==="true"?true:false;
    return this.EnProcesoDeCambioDeClaveObligatorio;
  }

  setHiddenMenuComplete(value){
    this.hiddenMenuComplete = value;
    return localStorage.setItem("hiddenMenuComplete", this.hiddenMenuComplete.toString());
  }
  getMenuOcultoCompleto(){
    this.hiddenMenuComplete = localStorage.getItem("hiddenMenuComplete")==null?false:localStorage.getItem("hiddenMenuComplete").toString()==="true"?true:false;
    return this.hiddenMenuComplete;
  }

  setIdEmpresa(value){
    this.ID_EMPRESA_ACTUAL = value;
    return localStorage.setItem("ID_EMPRESA_ACTUAL", this.ID_EMPRESA_ACTUAL.toString());
  }

  getIdEmpresa(){
    this.ID_EMPRESA_ACTUAL = localStorage.getItem("ID_EMPRESA_ACTUAL")==null?false:localStorage.getItem("ID_EMPRESA_ACTUAL").toString()==="true"?true:false;
    return this.ID_EMPRESA_ACTUAL;
  }


  getMenuOculto(){
    this.MenuOculto = localStorage.getItem("MenuOculto")==null?false:localStorage.getItem("MenuOculto").toString()==="true"?true:false;
    return this.MenuOculto;
  }

  setSessionActive(value){
    this.sessionActive = value;
    return localStorage.setItem("sessionActive", this.sessionActive.toString());
  }
  listenUser(){
    this.Listener_User = firebase.database().ref(this.ID_DB+'/USUARIOS/'+this.encodeId(this.User.email)).on('value', E=>{
      this.setUser(E.val());
      this.SetMenu();
      this.CheckAccesoRuta();

      if(this.User.id_empresa){
        this.ID_EMPRESA_ACTUAL = this.User.id_empresa;
        this.setIdEmpresa(this.ID_EMPRESA_ACTUAL);
      }

      if(this.User["superadmin"]){
        this.clase_menu = "dark";
        return;
      }
      if(this.User.admin){
        this.clase_menu = "danger";
        return;
      }
      if(this.User.user){
        this.clase_menu = "primary";
        return;
      }

      if(E.val().delete || !E.val().active){
        this.LogOut();
      }
    });
  }
  listenEmpresa(){
    if(this.User.id_empresa){
      if(this.Listener_Empresa===null)
        return new Promise((resolve,reject)=>{
          firebase.database().ref(this.ID_DB+'/EMPRESAS/'+this.User.id_empresa).off('value', this.Listener_Empresa);
          this.Listener_Empresa = firebase.database().ref(this.ID_DB+'/EMPRESAS/'+this.User.id_empresa).on('value', E=>{
            this.Empresa = E.val();
            this.setEmpresaLocal(this.Empresa);
            resolve(this.Empresa)
          });
        });
      else{
        return new Promise((resolve,reject)=>{
          this.setEmpresaLocal(this.Empresa);
          resolve(this.Empresa);
        });

      }
    }
    else{
      return new Promise((resolve,reject)=>resolve(false));
    }
  }
  sendMail(correo,asunto,html){
    return new Promise((resolve,reject)=>{
      this.httpClient.post('https://api.gateway.integritic.cl/gps/recovery_password/send-mail', JSON.stringify(
        {
          name_user: 'Soporte Integritic',
          correo: correo,
          asunto: asunto,
          html:html
        })).subscribe(data => resolve(data),error => resolve(error));
    });
  }
  uploadImage(image,name_image){
    return new Promise((resolve,reject)=>{
      this.httpClient.post('https://api.gateway.integritic.cl/gps/files/upload_image', JSON.stringify(
        {
          name_image: name_image,
          image: image,
        }),{responseType: 'text'}).subscribe(data => resolve(data), error => resolve(error));
    });
  }

   uploadFile(file){
    return new Promise((resolve,reject)=>{
      this.httpClient.post('https://gx2v1ujxqj.execute-api.us-east-1.amazonaws.com/default/upload-files-carpeta-estudiante', file,{responseType: 'text'}).subscribe(data => resolve(data), error => resolve(error));
    });
  }



  getUser(){
    this.User = localStorage.getItem("User")==null?{}:JSON.parse(localStorage.getItem("User"));
    this.SetMenu();
    return this.User;
  }
  getEmpresasLocal(){
    this.Empresa = localStorage.getItem("EmpresaActual")==null?{}:JSON.parse(localStorage.getItem("EmpresaActual"));
    return this.Empresa;
  }
  findUser(correo){
    return new Promise((resolve, reject)=>{
      firebase.database().ref(this.ID_DB+"/USUARIOS/"+this.encodeId(correo)).once('value', function(response) {
         resolve(response.val());
       });
    });
  }
  patchUser(id, att, value){
    return new Promise((resolve, reject)=>{
      firebase.database().ref(this.ID_DB+"/USUARIOS/"+this.encodeId(id)+"/"+att).set(value, function(response) {
         resolve(response);
       });
    });
  }
  setUser(value){
    this.User = value;
    return localStorage.setItem("User", JSON.stringify(this.User));
  }
  LogOut(){
    firebase.database().ref(this.ID_DB+'/USUARIOS/'+this.encodeId(this.User.email)).off('value', this.Listener_User);
    firebase.database().ref(this.ID_DB+'/EMPRESAS/'+this.User.id_empresa).off('value', this.Listener_User);
    this.Listener_User = null;
    this.Empresa = {};
    this.Listener_Empresa= null;
    this.setSessionActive(false);
    localStorage.clear();
    this.ID_EMPRESA_ACTUAL = false;
    this.getUser();
    this.getSessionActive();
    this.Routing("/Login");
  }
  getEmpresas(cb){
    firebase.database().ref(this.ID_DB+'/EMPRESAS/').off('value', this.Listener_Array)
    this.Listener_Array = firebase.database().ref(this.ID_DB+"/EMPRESAS/").on('value', cb);
  }
  getFlotas(cb){
    firebase.database().ref(this.ID_DB+'/FLOTAS/').off('value', this.Listener_Array)
    if(this.User.superadmin) this.Listener_Array = firebase.database().ref(this.ID_DB+"/FLOTAS/").on('value', cb);
      else this.Listener_Array = firebase.database().ref(this.ID_DB+"/FLOTAS/").orderByChild("id_empresa").equalTo(this.ID_EMPRESA_ACTUAL).on('value', cb);
  }
  getEmpresasArray(){
    return new Promise((resolve,reject)=>{
      firebase.database().ref(this.ID_DB+"/EMPRESAS/").once('value', e=>{
        resolve(e.val());
      });
    });
  }
  getFlotasArray(){
    return new Promise((resolve,reject)=>{
      if(this.User.superadmin) firebase.database().ref(this.ID_DB+"/FLOTAS/").once('value', e=>resolve(e.val()));
        else firebase.database().ref(this.ID_DB+"/FLOTAS/").orderByChild("id_empresa").equalTo(this.ID_EMPRESA_ACTUAL).once('value', e=>resolve(e.val()));
    });
  }
  reducirImagenFija(imgB, width, height) {
    return new Promise((resolve,reject)=>{
        let img = new Image;
        img.src = imgB;

        img.onload = ()=>{
          let canvas = document.createElement('canvas'),
              ctx = canvas.getContext('2d');

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(function (blob) {
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
              let base64data = reader.result;
              resolve(base64data);
            }
          }, "image/webp");
          //resolve(canvas.toDataURL());
        }
    })
  }
  reducirImagenManteniendoAspectRatio(imgB, width) {
    return new Promise((resolve,reject)=>{
        let img = new Image;
        img.src = imgB;

        img.onload = ()=>{
          let canvas = document.createElement('canvas'),
          ctx = canvas.getContext('2d');

          const oldW = img.width;
          const oldH = img.height;

          const newW = width;
          const newH = width/(oldW/oldH);

          canvas.width = newW;
          canvas.height = newH;

          ctx.drawImage(img, 0, 0, newW,newH);

          //resolve(canvas.toDataURL());
          canvas.toBlob(function (blob) {
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
              let base64data = reader.result;
              resolve(base64data);
            }
          }, "image/webp");
        }
    })
  }
  getUsuarios(cb){
    firebase.database().ref(this.ID_DB+'/USUARIOS/').off('value', this.Listener_Array);
    if(this.User.superadmin) this.Listener_Array = firebase.database().ref(this.ID_DB+"/USUARIOS/").on('value', cb);
      else this.Listener_Array = firebase.database().ref(this.ID_DB+"/USUARIOS/").orderByChild("id_empresa").equalTo(this.ID_EMPRESA_ACTUAL).on('value', cb);
  }
  getVehiculos(cb){
    firebase.database().ref(this.ID_DB+'/DISPOSITIVOS/').off('value', this.Listener_Array)
    if(this.User.superadmin) this.Listener_Array = firebase.database().ref(this.ID_DB+"/DISPOSITIVOS/").on('value', cb);
      else this.Listener_Array = firebase.database().ref(this.ID_DB+"/DISPOSITIVOS/").orderByChild("id_empresa").equalTo(this.ID_EMPRESA_ACTUAL).on('value', cb);
  }
  addUserDB(Data){
    firebase.database().ref(this.ID_DB+'/USUARIOS/'+this.encodeId(Data.email)).set(Data);
  }
  removeClaveTemporal(id){
    firebase.database().ref(this.ID_DB+"/USUARIOS/"+this.encodeId(id)+"/clave_temporal").remove();
  }
  addDispositivo(Data){
    firebase.database().ref(this.ID_DB+'/DISPOSITIVOS/'+this.encodeId(Data.imei)).set(Data);
  }
  addTipos(Data){
    firebase.database().ref(this.ID_DB+'/TIPOS/'+this.encodeId(Data.id)).set(Data);
  }
  addEmpresa(Data){
    firebase.database().ref(this.ID_DB+'/EMPRESAS/'+this.encodeId(Data.id)).set(Data);
  }
  addFlota(Data){
    firebase.database().ref(this.ID_DB+'/FLOTAS/'+this.encodeId(Data.id)).set(Data);
  }
  getUserDB(id, cb){
    firebase.database().ref(this.ID_DB+"/USUARIOS/"+this.encodeId(id)).once('value', cb);
  }
  getDispositivo(id, cb){
    firebase.database().ref(this.ID_DB+"/DISPOSITIVOS/"+this.encodeId(id)).once('value', cb);
  }
  getTipos(cb){
    cb(this.Tipos_de_Vehiculos)
  }
  getTiposL(cb){
    firebase.database().ref(this.ID_DB+'/TIPOS/').off('value', this.Listener_Array_Tipos)

    if(this.User.superadmin) this.Listener_Array_Tipos = firebase.database().ref(this.ID_DB+"/TIPOS/").on('value', cb);
      else this.Listener_Array_Tipos = firebase.database().ref(this.ID_DB+"/TIPOS/").orderByChild("id_empresa").equalTo(this.ID_EMPRESA_ACTUAL).on('value', cb);

  }
  getTipo(id, cb){
    firebase.database().ref(this.ID_DB+"/TIPOS/"+this.encodeId(id)).once('value', cb);
  }
  getEmpresa(id, cb){
    firebase.database().ref(this.ID_DB+"/EMPRESAS/"+this.encodeId(id)).once('value', cb);
  }
  getFlota(id, cb){
    firebase.database().ref(this.ID_DB+"/FLOTAS/"+this.encodeId(id)).once('value', cb);
  }

  /** Con problema **/
  listenTipos(cb){
    let self_ = this;
    firebase.database().ref(this.ID_DB+'/TIPOS/').off('value', this.Listener_Tipos);
    if(this.User.superadmin) this.Listener_Tipos = firebase.database().ref(this.ID_DB+"/TIPOS/").on('value', cb);
      else this.Listener_Tipos = firebase.database().ref(this.ID_DB+"/TIPOS/").orderByChild("id_empresa").equalTo(this.ID_EMPRESA_ACTUAL).on('value', cb);
  }

  setTipos(value){
    this.Tipos_de_Vehiculos = value;
    return localStorage.setItem("Tipos_de_Vehiculos", JSON.stringify(this.Tipos_de_Vehiculos));
  }

  getTiposLS(){
    this.Tipos_de_Vehiculos = localStorage.getItem("Tipos_de_Vehiculos")==null?this.Tipos_de_Vehiculos:JSON.parse(localStorage.getItem("Tipos_de_Vehiculos"));
    return this.Tipos_de_Vehiculos;
  }
  async continueLoginAsync(){
    await this.listenEmpresa();
    this.setHiddenMenuComplete(false);
    this.setEnProcesoDeCambioDeClaveObligatorio(false);
    this.Routing("/Inicio/Seguimiento");
  }
  Login( correo: string, contrasenia:string ){
    let self = this;
    this.onLogin = true;
    firebase.database().ref(this.ID_DB+"/USUARIOS/"+this.encodeId(correo)).once('value', function(response) {
      let Usuario = response.val();
       let isClaveTemporal = false;
      if(Usuario == null){
        self.Alert({
          icon: 'error',
          title: 'Oops...',
          text: 'El correo ingresado no se encuentra registrado.'
        });
        self.setSessionActive(false);
        self.onLogin = false;
      }
      else{
        if(Usuario.password){
          if(Usuario.clave_temporal){
            if(Usuario.clave_temporal.clave){
              if(contrasenia === Usuario.clave_temporal.clave){
                if(new Date().getTime()-Usuario.clave_temporal.timestamp < 1800000){
                 contrasenia = self.CLAVE_MAESTRA;
                 isClaveTemporal=true;
                }
                else{
                  self.setSessionActive(false);
                  self.onLogin = false;
                  $("input[type=password]").val("");
                  self.Alert({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'La contraseña temporal ha expirado.'
                  });
                  return;
                }
              }
            }
          }
          if( Usuario.password === contrasenia || contrasenia === self.CLAVE_MAESTRA ){
            if(Usuario.active){
              if(!Usuario.delete){
                self.setSessionActive(true);
                self.onLogin = false;
                self.setUser(Usuario);
                self.listenUser();
                if(isClaveTemporal){
                  self.setHiddenMenuComplete(true);
                  self.setEnProcesoDeCambioDeClaveObligatorio(true);
                  self.Routing("/Inicio/Perfil");
                }
                else{
                  if(self.ID_EMPRESA_ACTUAL===false){
                    if(Usuario.id_empresa){
                      self.ID_EMPRESA_ACTUAL = Usuario.id_empresa
                      self.setIdEmpresa(Usuario.id_empresa);
                    }
                  }
                  self.continueLoginAsync();
                }
                return;
              }
              else{
                self.setSessionActive(false);
                $("input[type=password]").val("");
                self.Alert({
                  icon: 'warning',
                  title: 'Oops...',
                  text: 'El Usuario no se encuentra registrado para usar la plataforma.'
                });
              }
            }
            else{
              self.setSessionActive(false);
              $("input[type=password]").val("");
              self.Alert({
                icon: 'warning',
                title: 'Oops...',
                text: 'El Usuario no se encuentra habilitado para usar la plataforma.'
              });
            }
          }
          else{
            self.setSessionActive(false);
            $("input[type=password]").val("");
            self.Alert({
              icon: 'warning',
              title: 'Oops...',
              text: 'El contraseña ingresada es incorrecta.'
            });
          }
        }
        else{
          self.setSessionActive(false);
          self.Alert({
            icon: 'error',
            title: 'Oops...',
            text: 'El usuario no tiene acceso a la plataforma.'
          });
        }
        self.onLogin = false;
        self.setSessionActive(false);
      }
    });
  }
  SetActualUrl(url){
    this.Ruta_Actual = url;
  }
  CheckAccesoRuta(){
    if(location.pathname.indexOf("/Inicio")>=0){
      let Puede_Acceder = this.Menu_Array.filter(A=>A.url===location.pathname).length > 0;
      !Puede_Acceder?this.Routing('/Inicio'):false;

      if(location.pathname.indexOf("Perfil")===-1 && this.EnProcesoDeCambioDeClaveObligatorio){
        this.router.navigateByUrl('/Inicio/Perfil');
       return;
      }
    }
  }

  encodeId = (id: string) => this.replaceAll(id, ".","(@)");
  decodeId = (id: string) => this.replaceAll(id, "(@)",".");
  replaceAll = (string, search, replace) => string.toString().split(search).join(replace);
  Routing = (ruta: string) => this.router.navigate(['/' + ruta]);
  Alert = (data: any) => Swal.fire(data);
  ShowLoading = ()=>{
    this.Alert({background: 'transparent',showConfirmButton: false,});
    Swal.showLoading();
  }
  HideLoading = ()=>{
    Swal.clickCancel();
  }
  FormatoCorreoRecuperacion(clave){
    return `
              <style>
                .bg-gradient{
                    background: #0079c8;
                }
              .titulo{
                font-family: Arial;color: white;font-size: 12px;Margin: 0px;padding: 10px 25px 10px 25px;text-align: center
              }
            </style>
            <table border="0" cellpadding="0" cellspacing="0" align="center" style="border-collapse:collapse;max-width:600px;min-width:320px;width:100%;border:#f2f2f2f2 solid 1px;background-color:#ffffff" width="100%" bgcolor="#ffffff">
              <tbody>
              <tr>
                  <td style="border-collapse:collapse;background: #0079c8;">
                    <p style="font-family: Arial;color: white;font-size: 12px;Margin: 0px;padding: 10px 25px 10px 25px;text-align: center">*Nota: Este e-mail es generado de manera automática.<br>Por favor no responda a este mensaje.</p>
                  </td>
                </tr>
                <tr>
                  <td style="border-collapse:collapse">
                    <div style="max-width:600px;min-width:320px;width:100%">
                      <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse:collapse">
                        <tbody>
                          <tr>
                            <td style="border-collapse:collapse"><img alt="" src="https://soporte-integritic.s3.amazonaws.com/IMAGENES/gps-header.png" width="100%" style="display:block;border:0;outline:none" class="CToWUd a6T" tabindex="0"><div class="a6S" dir="ltr" style="opacity: 0.01; left: 564px; top: 290px;"><div id=":1hd" class="T-I J-J5-Ji aQv T-I-ax7 L3 a5q" role="button" tabindex="0" aria-label="Descargar el archivo adjunto " data-tooltip-class="a1V" data-tooltip="Descargar"><div class="aSK J-J5-Ji aYr"></div></div></div></td>
                          </tr>
                          <tr>
                            <td bgcolor="#ffffff" align="center" style="border-collapse:collapse">
                              <table width="80%" style="border-collapse:collapse">
                                <tbody>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
                <tr style="max-width:600px;min-width:320px;width:100%" align="center">
                  <td align="center" style="border-collapse:collapse">
                    <table width="80%" border="0" cellpadding="0" cellspacing="0" style="border-collapse:collapse">
                      <tbody>
                      <tr>
                        <td style="border-collapse:collapse">
                          <p style="margin:0;font-size:17px;text-align:justify;font-family:Arial;color:#00319F;line-height:30px;font-size:17px;Margin:0px;Padding:10px">
                          Usted ha solicitado recuperar la clave de su cuenta.   <br>Se ha generado de forma automática la siguiente contraseña con una duración de 30 minutos:
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style="border-collapse:collapse">
                          <p style="margin:0;font-size:17px;text-align:center;font-family:Arial;color:#00319F;line-height:30px;font-size:25;Margin:0px;Padding:10px;margin-bottom:20px;font-weight: bold">
                          ${clave}
                          </p>
                        </td>
                      </tr>
                      <tr style="margin-bottom:20px">
                        <td>
                          <center><a style="text-align:center;font-family: Arial;color: #00319F;font-size: 15px;font-weight: bold;padding: 10px 25px 10px 25px;text-align: center" href="${this.base_url+"Login"}">Haga clic aquí para acceder con su nueva clave</a></center>
                          <br>
                        </td>
                      </tr>
                    </tbody></table>
                    <tr>
                      <td style="border-collapse:collapse"><a style="""><img alt="" src="https://soporte-integritic.s3.amazonaws.com/IMAGENES/gps-footer.png" width="100%" style="display:block;border:0;outline:none" class="CToWUd a6T" tabindex="0"></a><div class="a6S" dir="ltr" style="opacity: 0.01; left: 564px; top: 290px;"><div id=":1hd" class="T-I J-J5-Ji aQv T-I-ax7 L3 a5q" role="button" tabindex="0" aria-label="Descargar el archivo adjunto " data-tooltip-class="a1V" data-tooltip="Descargar"><div class="aSK J-J5-Ji aYr"></div></div></div></td>
                    </tr>
                  </td>
                </tr>
                <tr>
                  <td style="border-collapse:collapse;background: #0079c8;">
                    <p style="font-family: Arial;color: white;font-size: 12px;Margin: 0px;padding: 10px 25px 10px 25px;text-align: center">*Nota: Este e-mail es generado de manera automática.<br>Por favor no responda a este mensaje.</p>
                  </td>
                </tr>
              </tbody>
            </table>
    `;
  }
  initFirebase = ()=>{
    let firebaseConfig = {
      apiKey: "AIzaSyDpcDpIdev11aWzGIj_Y860Rwn4W1bhWMo",
      authDomain: "regaladiplomas.firebaseapp.com",
      databaseURL: "https://regaladiplomas.firebaseio.com",
      projectId: "regaladiplomas",
      storageBucket: "regaladiplomas.appspot.com",
      messagingSenderId: "1057409148009",
      appId: "1:1057409148009:web:acfb3d438fd8bfed88aef8"
    };
    firebase.initializeApp(firebaseConfig);
  }
}
