<div class="container-fluid">
  <h1 class="h3 mb-2 text-gray-800 mt-3">Visualización de ubicación en tiempo real</h1>
  <div class="row">
    <div class="col-xl-12 col-md-12 mb-4">
      <div class="card border-bottom-{{global.clase_menu}} shadow h-100 py-2">
        <div class="card-body">
          <div class="row">
              <!-- <div class="col-md-6">
                <div class="form-group">
                  <label>Seleccione un Vehículo:</label>
                  <select id="seleccion-vehiculo" class="form-control" (change)="seleccionarVehiculo($event)">
                    <option selected value="ALL">Ver Todos los Vehículos</option>
                    <option *ngFor="let dispositivo of Listado_Vehiculos; let i = index" [value]="dispositivo.imei">{{dispositivo.descripcion}} • {{dispositivo.patente}} • Tipo: {{dispositivo.tipo}}</option>
                  </select>
                </div>
              </div> -->
              <!-- <div class="col-md-6">
                <button *ngIf="!_VER_TODOS_" class="mt-30 ml-1 btn {{AutoCenter?'bg-success':'bg-danger'}}" (click)="AutoCenter = AutoCenter?false:true;" style="color:white;">Auto-Centrado</button>
                <button *ngIf="_VER_TODOS_" disabled class="mt-30 ml-1 btn {{AutoCenter?'bg-success':'bg-danger'}}" style="color:white;">Auto-Centrado</button>
                <button *ngIf="!_VER_TODOS_" class="mt-30 ml-1 btn {{AutoZoom?'bg-success':'bg-danger'}}" (click)="AutoZoom = AutoZoom?false:true;" style="color:white;">Auto-Zoom</button>
                <button *ngIf="_VER_TODOS_" disabled class="mt-30 ml-1 btn {{AutoZoom?'bg-success':'bg-danger'}}" style="color:white;">Auto-Zoom</button>
                <button class="btn bg-primary mt-30 ml-1" (click)="VerVehiculo('ALL')" style="color:white;" id="boton-ver-todos">Ver Todos</button>
                <button class="btn btn-warning mt-30 ml-1" (click)="PantallaCompleta()" style="">Pantalla Completa</button>
              </div> -->
          </div>
          Filtros:
          <div class="row mt-2">
                
                <div class="form-group mr-2 ml-2" *ngIf="global.User.superadmin">
                  <select class="form-control" [(ngModel)]="ShowEmpresas" (change)="ResetearSeleccion()">
                    <option value="ALL">Todas las Empresas</option>
                    <option *ngFor="let empresa of Listado_Empresas; let i = index" [value]="empresa.id">{{empresa.name}}</option>
                  </select>
                </div>
                <div class="form-group mr-2">
                  <select class="form-control" [(ngModel)]="SoloFlotas" (change)="ResetearSeleccion()">
                    <option value="ALL">Todas las Flotas</option>
                    <option *ngFor="let flota of Listado_Flotas; let i = index" [value]="flota.id">{{flota.name}}</option>
                  </select>
                </div>
                <div class="form-group mr-2">
                  <select class="form-control" [(ngModel)]="TipoGlobal" (change)="ResetearSeleccion();">
                    <option value="ALL">Todo tipo de Vehículo</option>
                    <option *ngFor="let tipo of global.Tipos_de_Vehiculos; let i = index" [value]="tipo.id">{{tipo.name}}</option>
                  </select>
                </div>
                <div class="form-group mr-2">
                  <select id="seleccion-vehiculo" class="form-control" (change)="seleccionarVehiculo($event)">
                    <option selected value="ALL">Todos los Vehículos</option>
                    <option *ngFor="let dispositivo of Listado_Vehiculos_Select; let i = index" [value]="dispositivo.imei">{{dispositivo.descripcion}} • {{dispositivo.patente}} • Tipo: {{dispositivo.tipo}}</option>
                  </select>
                </div>


                <div class="form-group mr-2">
                 <button class="btn bg-primary " (click)="VerVehiculo('ALL')" style="color:white;" id="boton-ver-todos">Ver Todos</button>
                  <button class="btn btn-warning  ml-1" (click)="PantallaCompleta()" style="">Pantalla Completa</button>
                </div>

              </div>
              <div class="row">
                <div class="form-group ml-2 mr-2">
                  <button *ngIf="_VER_TODOS_" class="mt-32 btn {{SoloEncendidos?'bg-success':'bg-danger'}}" (click)="SoloEncendidos = SoloEncendidos?false:true; VerTodos();" style="color:white;">
                    {{SoloEncendidos?'Ver Solo Encendidos':'Ver Solo Encendidos'}}
                  </button>

                  <button *ngIf="!_VER_TODOS_" disabled class="mt-32 btn {{SoloEncendidos?'bg-success':'bg-danger'}}" style="color:white;">Ver Solo Encendidos</button>
                  <button *ngIf="!_VER_TODOS_" class="ml-1 btn {{AutoCenter?'bg-success':'bg-danger'}}" (click)="AutoCenter = AutoCenter?false:true;" style="color:white;">Auto-Centrado</button>
                  <button *ngIf="_VER_TODOS_" disabled class="ml-1 btn {{AutoCenter?'bg-success':'bg-danger'}}" style="color:white;">Auto-Centrado</button>
                  <button *ngIf="!_VER_TODOS_" class="ml-1 btn {{AutoZoom?'bg-success':'bg-danger'}}" (click)="AutoZoom = AutoZoom?false:true;" style="color:white;">Auto-Zoom</button>
                  <button *ngIf="_VER_TODOS_" disabled class="ml-1 btn {{AutoZoom?'bg-success':'bg-danger'}}" style="color:white;">Auto-Zoom</button>

                </div>
                <div class="form-group mr-2">
                
                </div>

            
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row d-none" id="row-map">
    <div class="col-xl-8 col-md-6 mb-4" id="row-mapa-css">
      <div class="card border-left-{{global.clase_menu}} shadow h-100 py-2">
        <div class="card-body">
          <div id="map" class="map" style="height: 500px;"></div>
        </div>
      </div>
    </div>
    <div class="col-xl-4 col-md-6 mb-4">
      <div class="card border-left-{{global.clase_menu}} shadow h-100 py-2">
        <div class="card-body">
          <div class="row no-gutters align-items-center">
            <div class="col mr-2" *ngIf="_VER_TODOS_">
              <span class="" *ngFor="let dispositivo of Listado_Vehiculos; let i = index">
                <span class="row" *ngIf="dispositivo.show">
                     <!-- <img (click)="VerVehiculo(dispositivo.imei)" height="50px" src="https://cdn-sacyr.s3.amazonaws.com/{{dispositivo.color}}.png" style="cursor:pointer;background-color:#{{dispositivo.codigo_color}}">  -->
                    <span *ngIf="!dispositivo.isb64" (click)="VerVehiculo(dispositivo.imei)" class="icon-{{dispositivo.tipo_icon}}" style="cursor:pointer;font-size: 40px;margin-top: 1px;color:#{{dispositivo.codigo_color}}"></span>
                    <span *ngIf="dispositivo.isb64" (click)="VerVehiculo(dispositivo.imei)" style="cursor:pointer;font-size: 40px;margin-top: 1px;"><img src="{{dispositivo.tipo_icon.replace('https://cdn-gps.s3.ama2zonaws.com/','https://d3fhzxpgfkcmpz.cloudfront.net/')}}" style="width: 40px; position:absolute; margin-top: 10px;"></span>

                    <span (click)="VerVehiculo(dispositivo.imei)"  *ngIf="dispositivo.GPS" class="h6 font-weight-normal badge" style="cursor:pointer;height: 20px; margin-top: 15px; margin-left: {{dispositivo.isb64?50:10}}px; color:white; background:#{{dispositivo.codigo_color}}; "><b>{{dispositivo.identificador}}</b></span>
                    <!-- <span *ngIf="dispositivo.GPS" class="h6 font-weight-normal">{{dispositivo.GPS.ESTADO}}</span> -->
                    <i title="Vehículo {{dispositivo.GPS?dispositivo.GPS.ESTADO:''}}" style="margin-top: 17px !important; margin-left: 10px;" class="fas fa-circle {{dispositivo.GPS?dispositivo.GPS.ESTADO==='Encendido'?'text-success fa-pulse':'text-danger':'text-danger'}}"></i>
                    <span (click)="VerVehiculo(dispositivo.imei)" *ngIf="dispositivo.GPS" class="h6 font-weight-normal" style="cursor:pointer;margin-top: 17px !important; margin-left: 10px;">{{dispositivo.descripcion}}</span>
                    <span (click)="VerVehiculo(dispositivo.imei)"  *ngIf="dispositivo.GPS" class="h6 font-weight-normal badge bg-primary" style="cursor:pointer;margin-top: 15px !important; margin-left: 10px; color:white; padding: 5px; max-height: 20px;"><b></b> {{dispositivo.patente}}</span>

                    <span *ngIf="dispositivo.GPS" class="h6 font-weight-normal" style="margin-top: 17px !important; margin-left: 10px;"><b></b> {{dispositivo.GPS.VELOCIDAD}} km/h</span>


                    <!-- <span *ngIf="dispositivo.GPS" class="h6 font-weight-normal {{dispositivo.GPS.ESTADO==='Encendido'?'text-success':'text-danger'}}" style="margin-top: 17px !important; margin-left: 10px; float: right; position: absolute; right: 0;">{{dispositivo.GPS.ESTADO}}</span> -->

                </span>
               

              </span>
              <span *ngIf="ContadorDeVistos===0">No hay vehículos para mostrar.</span>
            </div>
            <div class="col mr-2" *ngIf="!_VER_TODOS_">
              <div *ngIf="Dispositivo_Actual.flotas_string!=='Personas'" class="text-xs font-weight-bold text-info text-uppercase mb-1">Datos del Vehículo</div>
              <div class="h5 mb-0 font-weight-bold text-gray-800"> <i title="Vehículo {{estadoEquipo}}" style="margin-top: 17px !important; margin-right: 10px;" class="fas fa-circle {{estadoEquipo==='Encendido'?'text-success fa-pulse':'text-danger'}}"></i> {{Dispositivo_Actual.descripcion}} 
                <!-- <img style="position: absolute;right: 0;top: 0;" class="float-right" src="{{urlImgGPS}}" width="70px"> -->
              <span *ngIf="!Dispositivo_Actual.isb64" class="float-right icon-{{Dispositivo_Actual.tipo_icon}}"  width="70px" style="position: absolute;right: 0;top: 0;font-size: 60px;margin-top: 5px;color:#{{Dispositivo_Actual.codigo_color}}"></span>
                <span *ngIf="Dispositivo_Actual.isb64" class="float-right icon-{{Dispositivo_Actual.tipo_icon}}"  width="70px" style="position: absolute;right: 0;top: 0;font-size: 60px;margin-top: 5px;color:#{{Dispositivo_Actual.codigo_color}}"><img src="{{Dispositivo_Actual.tipo_icon}}" style="height: 70px;"></span>

                <!-- <span *ngIf="!dispositivo.isb64" (click)="VerVehiculo(dispositivo.imei)" class="icon-{{dispositivo.tipo_icon}}" style="cursor:pointer;font-size: 40px;margin-top: 1px;color:#{{dispositivo.codigo_color}}"></span>
                <span *ngIf="dispositivo.isb64" (click)="VerVehiculo(dispositivo.imei)" style="cursor:pointer;font-size: 40px;margin-top: 1px;"><img src="{{dispositivo.tipo_icon}}" style="height: 40px;"></span> -->
              </div><br>
              <div *ngIf="Dispositivo_Actual.flotas_string!=='Personas'" class="h5 font-weight-bold text-gray-800">Patente:
                <span *ngIf="Dispositivo_Actual.patente!==false" class="h6 font-weight-normal">{{ Dispositivo_Actual.patente }}</span>
                <span *ngIf="Dispositivo_Actual.patente===false" class="h6 font-weight-normal"><img src='{{urlImgLoading}}' width="40px"></span>
              </div>
              <div *ngIf="Dispositivo_Actual.flotas_string!=='Personas'" class="h5 font-weight-bold text-gray-800">Tipo del Vehículo:
                <span *ngIf="Dispositivo_Actual.tipoNombre!=false" class="h6 font-weight-normal">{{ Dispositivo_Actual.tipoNombre }}</span>
                <span *ngIf="Dispositivo_Actual.tipoNombre==false" class="h6 font-weight-normal"><img src='{{urlImgLoading}}' width="40px"></span>
              </div>
              <div class="h5 font-weight-bold text-gray-800">IMEI:
                <span *ngIf="imei!=false" class="h6 font-weight-normal">{{ Dispositivo_Actual.imei }}</span>
                <span *ngIf="imei==false" class="h6 font-weight-normal"><img src='{{urlImgLoading}}' width="40px"></span>
              </div>
              <div class="h5 font-weight-bold text-gray-800">Observaciones:
                <span *ngIf="Dispositivo_Actual.observaciones!=false" class="h6 font-weight-normal">{{ Dispositivo_Actual.observaciones }}</span>
                <span *ngIf="Dispositivo_Actual.observaciones==false" class="h6 font-weight-normal"><img src='{{urlImgLoading}}' width="40px"></span>
              </div>
              <!-- <div class="h5 font-weight-bold text-gray-800">Identificador de Chip:
                <span *ngIf="identificadorEquipo!=false" class="h6 font-weight-normal">{{ identificadorEquipo }}</span>
                <span *ngIf="identificadorEquipo==false" class="h6 font-weight-normal"><img src='{{urlImgLoading}}' width="40px"></span>
              </div> -->
              <div *ngIf="Dispositivo_Actual.flotas_string!=='Personas'" class="h5 font-weight-bold text-gray-800">Estado del Vehículo:
                <span *ngIf="estadoEquipo!=false" class="h6 font-weight-normal">{{ estadoEquipo }}</span>
                <span *ngIf="estadoEquipo==false" class="h6 font-weight-normal"><img src='{{urlImgLoading}}' width="40px"></span>
              </div>
              <div class="h5 font-weight-bold text-gray-800">Velocidad Actual:
                <span *ngIf="VelocidadActual!==false" class="h6 font-weight-normal">{{ VelocidadActual }} Km/H</span>
                <span *ngIf="VelocidadActual===false" class="h6 font-weight-normal"><img src='{{urlImgLoading}}' width="40px"></span>
              </div>
              <div *ngIf="Dispositivo_Actual.flotas_string!=='Personas'" class="h5 font-weight-bold text-gray-800">Odometro:
                <span *ngIf="Odometro!==false" class="h6 font-weight-normal">{{ Odometro }} Kms</span>
                <span *ngIf="Odometro===false" class="h6 font-weight-normal"><img src='{{urlImgLoading}}' width="40px"></span>
              </div>
              <div *ngIf="datosAdicionalesEquipo!=false" class="h5 font-weight-bold text-gray-800">Datos Adicionales:
                <ul>
                  <li>Bateria: <span *ngIf="datosAdicionalesEquipo.battery!== undefined" class="h6 font-weight-normal">{{ datosAdicionalesEquipo.battery }}%</span></li>
                  <li>Cargando: <span *ngIf="datosAdicionalesEquipo.charging!== undefined" class="h6 font-weight-normal">{{ datosAdicionalesEquipo.charging?"Si":"No" }}</span></li>
                  <li>Señal: <span *ngIf="datosAdicionalesEquipo.gsmStrength!== undefined" class="h6 font-weight-normal">{{ datosAdicionalesEquipo.gsmStrength }}%</span></li>
                </ul>
                <!-- <span *ngIf="datosAdicionalesEquipo!=false" class="h6 font-weight-normal">{{ datosAdicionalesEquipo.battery }}</span>
                <span *ngIf="datosAdicionalesEquipo==false" class="h6 font-weight-normal"><img src='{{urlImgLoading}}' width="40px"></span> -->
              </div>
              <div *ngIf="Dispositivo_Actual.flotas_string!=='Personas'" class="h5 font-weight-bold text-gray-800">Flotas:
                <span *ngIf="Dispositivo_Actual.flotas_string!==false" class="h6 font-weight-normal">{{ Dispositivo_Actual.flotas_string }}.</span>
                <!-- <span *ngIf="Dispositivo_Actual.flotas_string===false" class="h6 font-weight-normal"><img src='{{urlImgLoading}}' width="40px"></span> -->
              </div>
              <div class="h5 font-weight-bold text-gray-800">Última actualización de ubicación:
                <span *ngIf="ultimaActualizacion!=false" class="h6 font-weight-normal">{{ ultimaActualizacion  | date:'dd-MM-yyyy HH:mm:ss' }}</span>
                <span *ngIf="ultimaActualizacion==false" class="h6 font-weight-normal"><img src='{{urlImgLoading}}' width="40px"></span>
              </div>
              <div class="h5 font-weight-bold text-gray-800">Coordenadas:
                <span *ngIf="coordenadas!=false" class="h6 font-weight-normal">{{ coordenadas }}</span>
                <span *ngIf="coordenadas==false" class="h6 font-weight-normal"><img src='{{urlImgLoading}}' width="40px"></span>
              </div>
              <!-- <div class="h5 font-weight-bold text-gray-800">Google Maps Street View:
                <span *ngIf="coordenadas!=false" class="h6 font-weight-normal"><a href="http://maps.google.com/maps?q=&layer=c&cbll={{ coordenadas.split(' ')[0] }},{{ coordenadas.split(' ')[1] }}" target="_blank">Abrir Maps</a></span>
                <span *ngIf="coordenadas==false" class="h6 font-weight-normal"><img src='{{urlImgLoading}}' width="40px"></span>
              </div> -->
            </div>
            <!-- <div class="col-auto">
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="d-none" id="overlays">
</div>

