<!-- Menú Abierto -->
 <ul class="d-none d-md-block navbar-nav {{clase_menu}} sidebar sidebar-dark accordion {{ global.MenuOculto?'toggled':'' }}" id="accordionSidebar">
  <a class="sidebar-brand d-flex align-items-center justify-content-center {{!global.User.superadmin && Empresa.color_menu_logo ? ('custom-'+Empresa.color_menu_logo) : '' }}" routerLink="/Inicio">
    <div class="sidebar-brand-icon">
      <img *ngIf="global.User.superadmin" width="100%" src="https://cdn-sacyr.s3.amazonaws.com/logo-blanco-2.svg">
      <img *ngIf="!global.User.superadmin && Empresa.image==null" width="100%" src="https://cdn-sacyr.s3.amazonaws.com/logo-blanco-2.svg">
      <img *ngIf="!global.User.superadmin && Empresa.image!=null" width="100%" [src]="Empresa.image" width="80%">
    </div>
    <!-- <div class="sidebar-brand-text mx-3 text-left">Sacyr Track<br><span style="font-size: 12px;">PMOB530</span></div> -->
  </a>
  <hr class="sidebar-divider my-0">
  <span *ngFor="let menu of global.Menu_Array; let i = index" class="">
    <li class="menu-span nav-item {{ router.url===menu.url?'active':'' }}" *ngIf="menu.show && !menu.hidden">
      <a class="nav-link" routerLink="{{menu.url}}">
        <i class="{{menu.icon}}" style="font-size: {{ global.MenuOculto?'20':'15' }}px;margin-right: 5px;"></i>
        <span class="nav-item-name" *ngIf="!global.MenuOculto">{{menu.name}}</span></a>
    </li>
  </span>
  <br>
  <hr class="sidebar-divider d-none d-md-block">

  <div class="text-center d-none d-md-inline">
    <button class="rounded-circle border-0" id="sidebarToggle" (click)="Evento_Menu()"></button>
  </div>
</ul>

<!-- Menú Cerrado -->

<ul class="accordionSidebar-xs d-md-none navbar-nav {{clase_menu}} sidebar sidebar-dark accordion {{ global.MenuOculto?'toggled d-none':'' }}" id="accordionSidebar">
  <a class="sidebar-brand d-flex align-items-center justify-content-center" routerLink="/Inicio">
    <div class="sidebar-brand-icon">
      <img *ngIf="global.User.superadmin" width="100%" src="https://cdn-sacyr.s3.amazonaws.com/icono.svg">
      <img *ngIf="!global.User.superadmin && Empresa.image==null" width="100%" src="https://cdn-sacyr.s3.amazonaws.com/icono.svg">
      <img *ngIf="!global.User.superadmin && Empresa.image!=null" width="100%" [src]="Empresa.image" width="35px">
    </div>
    <!-- <div class="sidebar-brand-text mx-3 text-left">Sacyr Track<br><span style="font-size: 12px;">PMOB530</span></div> -->
  </a>
  <hr class="sidebar-divider my-0">
  <span *ngFor="let menu of global.Menu_Array; let i = index" class="">
    <li class="menu-span nav-item {{ router.url===menu.url?'active':'' }}" *ngIf="menu.show" style="margin-top: 15px;">
      <a class="nav-link" routerLink="{{menu.url}}">
        <i class="{{menu.icon}}" style="font-size: 25px;margin-right: 5px;"></i>
        </a>
    </li>
  </span>
  <br>
  <hr class="sidebar-divider d-none d-md-block">

  <div class="text-center d-none d-md-inline">
    <button class="rounded-circle border-0" id="sidebarToggle" (click)="Evento_Menu()"></button>
  </div>
</ul>
