import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import * as moment from "moment";
import { onLog } from 'firebase';

declare var $: any;
declare var Swal: any;
declare var firebase: any;
declare var ol: any;
@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.less']
})
export class ReportesComponent implements OnInit {
  coso:any = {};
  ID_DB:string;
  constructor(public global:GlobalService) { this.ID_DB = this.global.ID_DB; this.coso = { array: [], fin:0}; }
  Listado_Vehiculos:any = [];
  Vehiculo_Seleccionado:any = "";
  Fecha_Desde:any="";
  Hora_Desde:any="";
  fecha_maxima = moment().format('YYYY-MM-DD')
  // Fecha_Hasta:any="";
  Hora_Hasta:any="";
  Tipo_Reporte:any="";
  Puede_Buscar:any = false;
  Distancia_Recorrida:any = "0 Kms";
  Tiempo_Encendido:any = "";
  Posicion_final_global:any = [0,0];
  addHours(a, h){
    a.setHours(a.getHours()+0);
    return a;
  }


  ngOnInit(): void {


    this.global.listenTipos(tipo=>{
      let aux = tipo.val();
      let array_ = [];
      for(let index in aux){
        if(!aux[index].delete) array_.push(aux[index]);
      }
      this.global.setTipos(array_);
    });


    //console.log(this.coso)
    this.Hora_Desde = "07:00";
    this.Hora_Hasta = "21:00";

    this.Fecha_Desde = moment().format('YYYY-MM-DD')
    $("#hora-desde").val(this.Hora_Desde).trigger("change");
     $("#hora-hasta").val(this.Hora_Hasta).trigger("change");
    //$("#fecha-desde").val(this.Fecha_Desde).trigger("change");
    $("#fecha-hasta").val(this.Fecha_Desde).trigger("change");

    this.global.getVehiculos(Response=>{
      let Dispo_Aux = [];
      this.global.ShowLoading();
      for(let i in Response.val()){
        let Dispositivo = Response.val()[i];
        Dispositivo.id = i;
        let action = "CargarVehiculo";
        let push_data = {
          descripcion: Dispositivo.descripcion,
          imei: Dispositivo.imei?Dispositivo.imei:"-",
          observaciones: Dispositivo.observaciones?Dispositivo.observaciones:"-",
          patente: Dispositivo.patente?Dispositivo.patente:"-",
          color: Dispositivo.color?Dispositivo.color:"c1",
          fecha_creacion: Dispositivo.created_date?Dispositivo.created_date:"-",
          estado: Dispositivo.active?"Activo":"Bloqueado",
          tipo: this.global.Tipos_de_Vehiculos.filter(A=>Dispositivo.tipo.toString()===A.id.toString())[0].name,
          orden: Dispositivo.orden?Dispositivo.orden:0,

        }
        !Dispositivo.delete ? Dispo_Aux.push(push_data) : false;
      }     
      Dispo_Aux = Dispo_Aux.sort((A,B)=>A.orden-B.orden);
      this.Listado_Vehiculos = Dispo_Aux;
      this.global.HideLoading();
    });

    $('#fecha-hasta').on("change",evento=>{
      var fecha_hoy = moment().format('YYYY-MM-DD')
      if (  this.Fecha_Desde > fecha_hoy){
        this.global.Alert({
          title: '¡Atención!',
          text: "La fecha ingresada no es válida, por favor intente nuevamente",
          icon: 'warning',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
        })
        $('#fecha-hasta').val(fecha_hoy)   
      }
    })
}
ActualizarEstado(){
  let Variables = ["Vehiculo_Seleccionado","Fecha_Desde","Hora_Desde","Hora_Hasta","Tipo_Reporte"];
  this.Puede_Buscar = true;
  for(let i=0; i < Variables.length; i++ ) if(this[Variables[i]].length === 0) this.Puede_Buscar = false;

  if(!this.Puede_Buscar){
    $("#ruta").addClass("d-none");
    $("#encendido").addClass("d-none");
    $("#btn_reporte").addClass("d-none");
  }
}
  ActualizarVariable(Obj, V){
      let Id = Obj.target?Obj.target.value:Obj;
      this[V] = Id;
      this.ActualizarEstado();
  }
  Posicion_Global:any = null;
  HoraMarcada:any = "0";

  calcularDiasAusencia(fechaIni, fechaFin) {
    var diaEnMils = 1000 * 60 * 60 * 24,
        desde = new Date(fechaIni),
        hasta = new Date(fechaFin),
        diff = hasta.getTime() - desde.getTime() + diaEnMils;// +1 incluir el dia de ini
    return diff / diaEnMils;
  }

  MAPA_GLOBAL: any;
  iconFeatureGlobal: any;
  RegistrosGlobal: any;
  valmapG: any;
  Reproduciendo: boolean=false;
  interval:any=null;
  velocidad:any=100;
  BotonVelocidad(param){
    var _self = this
    if(param=="2x" && _self.velocidad>0) _self.velocidad-=10

    console.log(_self.velocidad)

  }
  BotonPlay(param){
    console.log(param)
    let max = $('#slider').slider("option", "max");
    //var interval = null;    
    let contador=0;
    let fecha;
    var _self = this
    //stopStuff()

    if(_self.Reproduciendo==true){
      stopStuff()      
      console.log("stop")
      //_self.velocidad = 0;
    }
    else{
      
      startStuff()
      //console.log(_self.velocidad)
    }
    function startStuff() {
      //console.log(_self.Reproduciendo)
      _self.interval = setInterval(()=>{
        console.log(_self.velocidad)
          _self.Reproduciendo = true
          $('#slider').slider("value", contador);
          fecha = moment(Number(_self.valmapG[contador])).add(4, 'hours').format('DD-MM-YY hh:mm:ss')
          //console.log(fecha)
          $( "#amount" ).val( fecha );
          _self.iconFeatureGlobal.setGeometry(new ol.geom.Point([Number(_self.RegistrosGlobal[_self.valmapG[contador]].X),Number(_self.RegistrosGlobal[_self.valmapG[contador]].Y)]));
          contador++;
          if(contador>=max) {clearInterval(_self.interval),_self.Reproduciendo=false};
        },_self.velocidad);
    }
    
    function stopStuff() {
        clearInterval(_self.interval);
        _self.Reproduciendo=false;
        //_self.velocidad = 50;
    }   
   


  }
  BuscarReportes(){
    this.global.ShowLoading();
    let _self_ = this;
    _self_.Distancia_Recorrida = "0.0 Kms";
    //let cosito = this.coso;
    firebase.database().ref(this.ID_DB+"/REPORTES/"+this.Vehiculo_Seleccionado+"/"+(this.Tipo_Reporte==="encendido"?"REPORTES_ENCENDIDO":"REPORTES_RUTAS")+"/"+this.Fecha_Desde).once('value', function(snapshot) {

      let Registros = snapshot.val();
      // console.log(Registros)
      // console.log(_self_.Vehiculo_Seleccionado)
      // console.log(_self_.Tipo_Reporte==="encendido"?"REPORTES_ENCENDIDO":"REPORTES_RUTAS")
      // console.log(_self_.Fecha_Desde)
      _self_.RegistrosGlobal = Registros;
      let Desde = _self_.Fecha_Desde+"T"+_self_.Hora_Desde+":00.000Z";
      let Hasta = _self_.Fecha_Desde+"T"+_self_.Hora_Hasta+":59.000Z";

      let Desde_Number = new Date(Desde).getTime();
      let Hasta_Number = new Date(Hasta).getTime();

      if(_self_.Tipo_Reporte==="ruta"){
        let idEquipo = _self_.Vehiculo_Seleccionado;

        _self_.global.getDispositivo(idEquipo, R=>{
          let Dispositivo_Actual = R.val();
          let urlImgGPS = "https://cdn-sacyr.s3.amazonaws.com/"+Dispositivo_Actual.color+".png";

          let auxDi = _self_.global.Tipos_de_Vehiculos.filter(A=>Dispositivo_Actual.tipo.toString()===A.id.toString())[0];
          urlImgGPS = auxDi.b64?auxDi.b64:"https://cdn-sacyr.s3.amazonaws.com/"+Dispositivo_Actual.color+".png";



          $("#row-map").removeClass("d-none");
          $("#map").empty();

          $("#ruta").removeClass("d-none");
          $("#encendido").addClass("d-none");

          var attribution = new ol.control.Attribution({collapsible: true});
          var view = new ol.View({projection: 'EPSG:4326',center: [-71.174460, -32.796171],zoom: 6});
          var iconStyle = new ol.style.Style({zIndex: 2,image: new ol.style.Icon({opacity: .9,scale: .22,src: urlImgGPS})});
          _self_.iconFeatureGlobal = new ol.Feature();
          var iconSource = new ol.source.Vector({features: [_self_.iconFeatureGlobal]});
          var iconLayer = new ol.layer.Vector({source: iconSource,style : iconStyle});
          var iconStyle_2 = new ol.style.Style({zIndex: 9,image: new ol.style.Icon({opacity: .9,scale: .28,src: "https://cdn-sacyr.s3.amazonaws.com/inicio.png"})});
          _self_.Posicion_Global = new ol.Feature();
          var iconSource_2 = new ol.source.Vector({features: [_self_.Posicion_Global]});
          var iconLayer_2 = new ol.layer.Vector({source: iconSource_2,style : iconStyle_2});

          var baseLayer = new ol.layer.Tile({source: new ol.source.OSM()});
          _self_.MAPA_GLOBAL = new ol.Map({controls: ol.control.defaults({attribution: false}).extend([attribution]),layers: [baseLayer],target: 'map',view: view});
            var fin_slider = 0;
            var last_co = [0,0];
            let ultimo = [];

            let Menor_x = 0;
            let Menor_y = 0;

            let Mayor_x = 0;
            let Mayor_y = 0;

            let UltimoRegistro:any = false;
            let PrimerRegistro:any = false;
            let Reportes_Array = [];
            let DistanciaTemporal:any = 0;
            let Inicial:any = false;
            for(let x in Registros){
              if(Number(x)>=Desde_Number &&  Number(x) <= Hasta_Number){
                if(Inicial===false) Inicial = Number(Registros[x].KILOMETRAJE);
              }
            }

            //console.log(Inicial)
            let valmap = []
            for(let x in Registros){

              if(Number(x)>=Desde_Number &&  Number(x) <= Hasta_Number){
                //console.log(x)


                valmap.push(x)
                $( "#slider" ).slider({
                  //value:Desde_Number,
                  //min: Desde_Number,
                  max: valmap.length - 1,
                  //step: 50,
                  slide: function( event, ui ) {
                    let fecha = moment(Number(valmap[ui.value])).add(4, 'hours').format('DD-MM-YY hh:mm:ss')
                    $( "#amount" ).val(fecha);
                    fin_slider = valmap[ui.value]
                    //cosito = {array: valmap, fin:fin_slider};
                    _self_.coso.fin = fin_slider

                    //console.log("343", fin_slider);
                    //_self_.BuscarReportes();
                  }
                });
                if(_self_.coso.fin>1000){
                  let fecha = moment(Number(_self_.coso.fin)).add(4, 'hours').format('DD-MM-YY hh:mm:ss')
                  $( "#amount" ).val( fecha );
                }
                else{
                  $( "#amount" ).val( "" );
                }
                //_self_.coso = {array: valmap, fin:fin_slider};

                DistanciaTemporal =  Number((Number(Registros[x].KILOMETRAJE)-Inicial)).toFixed(1);

                Reportes_Array.push({
                  fecha: Registros[x].FECHA.split("T")[1].split(".")[0],
                  velocidad:Registros[x].VELOCIDAD,
                  odometro: Registros[x].KILOMETRAJE,
                  X: Registros[x].X,
                  Y: Registros[x].Y,
                  //COURSE: Registros[x].COURSE,
                  recorrido: DistanciaTemporal,
                  localizar: `
                   <i data-x="${Registros[x].X}" data-y="${Registros[x].Y}" data-button-edit="${x}" style="cursor:pointer; font-size: 30px;" class="fas fa-map-marker-alt"></i>
                  `
                });

                let X = Number(Registros[x].X);
                let Y = Number(Registros[x].Y);

                if(Menor_x===0) Menor_x = X;
                if(Menor_y===0) Menor_y = Y;
                if(Mayor_x===0) Mayor_x = X;
                if(Mayor_y===0) Mayor_y = Y;

                if(X < Menor_x) Menor_x = X;
                if(Y < Menor_y) Menor_y = Y;

                if(X > Mayor_x) Mayor_x = X;
                if(Y < Mayor_y) Mayor_y = Y;


                let Actual = [Number(Registros[x].X),Number(Registros[x].Y)];
                  var features = [new ol.Feature({'geometry': new ol.geom.LineString([Actual,ultimo])})];
                  var vector = new ol.layer.Vector({
                    source: new ol.source.Vector({
                      features: features,
                      wrapX: false,
                    }),
                    style: new ol.style.Style({
                      zIndex: 1,
                      stroke: new ol.style.Stroke({
                        color: "black",
                        lineCap: 'round',
                        opacity: .1,
                        width: 3,
                      }),
                    }),
                  });



                  if(last_co[0] === 0 && last_co[1] === 0){
                    PrimerRegistro = Registros[x];
                    _self_.Posicion_Global.setGeometry(new ol.geom.Point(Actual));
                    _self_.HoraMarcada = x;
                  }
                  else {
                    //map.addLayer(vector);
                    //console.log(Registros[x].UTC, cosito.fin)
                    if(_self_.coso.fin>0){
                      if(Registros[x].UTC <= _self_.coso.fin){
                        _self_.MAPA_GLOBAL.addLayer(vector);
                        _self_.iconFeatureGlobal.setGeometry(new ol.geom.Point(last_co));

                      }
                    }
                    else{
                      _self_.MAPA_GLOBAL.addLayer(vector);
                      _self_.iconFeatureGlobal.setGeometry(new ol.geom.Point(last_co));
                    }
                  }


                  ultimo = Actual;
                  last_co = Actual;
                  UltimoRegistro = Registros[x];
                }
              }//fin de for

              let Distancia = 0;
              if(UltimoRegistro!==false && PrimerRegistro!==false){
                Distancia = Number(UltimoRegistro?UltimoRegistro.KILOMETRAJE:0) - Number(PrimerRegistro?PrimerRegistro.KILOMETRAJE:0);
                _self_.Distancia_Recorrida = Distancia.toFixed(1)+" Kms";
              }
              console.log(_self_.Distancia_Recorrida)

              let X = (Mayor_x+Menor_x)/2;
              let Y = (Mayor_y+Menor_y)/2;

              _self_.MAPA_GLOBAL.addLayer(iconLayer);
              _self_.MAPA_GLOBAL.addLayer(iconLayer_2);



              view.setZoom(12);
              view.setCenter([X,Y+0.02]);



              let Tabla = null;
              $('#dataTable').DataTable().destroy();
              Tabla = $('#dataTable').DataTable({dom: '<"datatable-header"fl<"toolbar">><"datatable-scroll"t><"datatable-footer"ip>',scrollX: false,scrollCollapse: false,lengthMenu: [[-1, 5, 10, 25, 50],["Todos", 5, 10, 25, 50]],aaSorting: [[0, "asc"]],language: {search: "<span >Buscar Registro:</span> _INPUT_",lengthMenu: "<label>Cantidad de registros mostrados</label>_MENU_",paginate: {first: "Primero",last: "Ultimo",next: "→",previous: "←"},decimal: "",emptyTable: "No existen registros con los filtros indicados.",info: "Mostrando _START_ a _END_, de un total de _TOTAL_ registros",infoEmpty: "",infoFiltered: "(Filtrando de un máximo de _MAX_ registros).",infoPostFix: "",thousands: ",",loadingRecords: "Cargando",processing: "Procesando",zeroRecords: "No se encontraron vehículos registrados."},processing: true,data: [],
                columnDefs:[{
                    className: "text-center",
                    targets: [0,1,2,3,4,5,6]
                },{ visible: false, targets: [6] }],
                 createdRow: function(t, a, e) {
                   if(a.velocidad === "0.0") $(t).addClass("tr-sin-mover");
                      else $(t).addClass("tr-normal");
                },
                columns: [{data:"fecha"},{data:"velocidad"},{data:"odometro"},{data:"recorrido"},{data:"X"},{data:"Y"},{data: "localizar"}],ordering: true});

              Tabla.clear().draw();
              Tabla.rows.add(Reportes_Array).draw();

              _self_.global.HideLoading();
              //console.log(_self_.HoraMarcada)
              $("[data-button-edit="+_self_.HoraMarcada+"]").addClass("text-danger");

              $("[data-button-edit]").bind("click").on("click", E=>{
               $("[data-button-edit]").removeClass("text-danger");
               let id = $(E.currentTarget).data('button-edit');
               _self_.HoraMarcada = id;
               $("[data-button-edit="+_self_.HoraMarcada+"]").addClass("text-danger");

               _self_.Posicion_Global.setGeometry(new ol.geom.Point( [   Number($(E.currentTarget).data('x')),   Number($(E.currentTarget).data('y')) ]));
            });
            let startPos = 0;
              //$("#slider").on("slidestop", function(event, ui) {
              $("#slider").on("slide", function(event, ui) {
                  let endPos = ui.value;
                  if (startPos != endPos) {
                    //console.log(Registros[valmap[ui.value]])
                      //iconStyle.getImage().setRotation((6.3*Registros[valmap[ui.value]].COURSE)/360);
                      console.log(valmap[ui.value])
                      try{
                        _self_.iconFeatureGlobal.setGeometry(new ol.geom.Point([Number(Registros[valmap[ui.value]].X),Number(Registros[valmap[ui.value]].Y)]));
                      }
                      catch(err){}
                      

                      //_self_.iconFeatureGlobal.changed();
                      //console.log("343", [Number(Registros[x].X),Number(Registros[x].Y)] valmap[ui.value]);
                  }

                  startPos = endPos;
              });
            _self_.valmapG = valmap;
          });
      }
      if(_self_.Tipo_Reporte==="encendido"){
        $("#encendido").removeClass("d-none");
        $("#ruta").addClass("d-none");
        //console.log(Registros)
        _self_.global.HideLoading();
        let Reportes_Array = [];
        let Tabla = null;

        let Registros_2 = JSON.parse(JSON.stringify(Registros));
        let Anterior:any = false;
        // let Registro_anterior = {};
        // let Registro_ultimo = {};
        Registros = {};
        var Registros_ordenados = {}
        let counter = 0
        for(let x in Registros_2){

          if(Registros_2[x].TIPO){
             console.log(Registros_2[x].TIPO)
             if(Registros_2[x].TIPO != "GTIGL")
               Registros[x] = Registros_2[x];
            if(Registros_2[x].ESTADO){
              if(!Registros_ordenados[counter]) Registros_ordenados[counter] = []
              console.error(Registros_ordenados[counter])
              let encendido = Registros_ordenados[counter].filter(a=>a.ESTADO == "Encendido")?Registros_ordenados[counter].filter(a=>a.ESTADO)[0]:false
                if(!encendido){
                  Registros_ordenados[counter].push(Registros[x])
                }else{              
                  Registros_ordenados[counter].push(Registros[x])
                  counter++
                }
              
            }
            
          }      
          Anterior = Registros_2[x].ESTADO;
        }
        console.log(Registros_ordenados)
        Anterior = false;
        //let NumeroAnterior:any = false;
        for(let x in Registros){
          if(Number(x)>=Desde_Number &&  Number(x) <= Hasta_Number){

            //if(Anterior!=Registros[x].ESTADO || Anterior===false){
              //if(Reportes_Array.length===0) Registro_anterior = Registros[NumeroAnterior]?Registros[NumeroAnterior]:Registros[x];
              Reportes_Array.push({
                fecha: Registros[x].FECHA.split("T")[1].split(".")[0],
                estado: Registros[x].ESTADO,
                tiempo: Number(Registros[x].UTC) / 1000,
                X: Registros[x].TIPO?Registros[x].TIPO:"-",//Registros[x].X,
                Y: "-",//Registros[x].Y,
              });
              //Registro_ultimo = Registros[x];


            //NumeroAnterior = x;


          }
          //Anterior = Registros[x].ESTADO;
        }

        let Tiempo_Inicio = Number(Desde_Number) / 1000;
        let Tiempo_Final = Number(Hasta_Number) / 1000;
        //console.log(Reportes_Array)
        let Estaba_Encendido = true;
        if(Reportes_Array[0]){
          if(Reportes_Array[0].estado === "Encendido"){
            Tiempo_Inicio = Number(Reportes_Array[0].tiempo);
            Estaba_Encendido = false;
          }
        }



        if(Reportes_Array[Reportes_Array.length-1]){
          if(Reportes_Array[Reportes_Array.length-1].estado === "Encendido"){
            Tiempo_Final = Number(Reportes_Array[Reportes_Array.length-1].tiempo);
          }
        }

        Tiempo_Inicio = Tiempo_Inicio ;
        Tiempo_Final = Tiempo_Final ;
        //console.log(Tiempo_Inicio,Tiempo_Final)
        let Tiempo_Total = 0;
        for(let i = Estaba_Encendido?0:1 ; i < Reportes_Array.length ; i++){

          if(Reportes_Array[i].estado === "Apagado"){
            let segundos = Math.floor(((Number(Reportes_Array[i].tiempo))-Tiempo_Inicio))
            Reportes_Array[i].Y =  fancyTimeFormat(segundos)
            Tiempo_Total += ((Number(Reportes_Array[i].tiempo))-Tiempo_Inicio);
            //console.log(Tiempo_Total)
          }
          Tiempo_Inicio = Number(Reportes_Array[i].tiempo);
        }

        //console.log(Tiempo_Total)
        //console.log(Reportes_Array)

        let I = new Date().getTime();
        let t = new Date();
        t.setSeconds(t.getSeconds() + Tiempo_Total);
        let F:any = t;
        let Diff = F - I;

        let msecPerMinute = 1000 * 60;
        let msecPerHour = msecPerMinute * 60;
        let msecPerDay = msecPerHour * 24;
        let interval = F - I;
        let days = Math.floor(interval / msecPerDay );
        interval = interval - (days * msecPerDay );
        let hours = Math.floor(interval / msecPerHour ) ;
        interval = interval - (hours * msecPerHour ) ;
        let minutes = Math.floor(interval / msecPerMinute );
        interval = interval - (minutes * msecPerMinute );
        let seconds = Math.floor(interval / 1000 );
        console.log(seconds)

        _self_.Tiempo_Encendido = ((hours) + (hours===1?" hora, ":" horas, ") + minutes + (minutes===1?" minuto, ":" minutos, ") + seconds + (seconds===1?" segundos.":" segundos."));

        $('#dataTable-2').DataTable().destroy();
        Tabla = $('#dataTable-2').DataTable({dom: '<"datatable-header"f<"toolbar">><"datatable-scroll"t><"datatable-footer"i>',scrollX: false,scrollCollapse: false,lengthMenu: [[-1, 10, 25, 50, -1],[5, 10, 25, 50, "Todos"]],aaSorting: [[0, "asc"]],language: {search: "<span >Buscar Registro:</span> _INPUT_",lengthMenu: "<label>Cantidad de registros mostrados</label>_MENU_",paginate: {first: "Primero",last: "Ultimo",next: "→",previous: "←"},decimal: "",emptyTable: "No existen registros con los filtros indicados.",info: "Mostrando _START_ a _END_, de un total de _TOTAL_ registros",infoEmpty: "",infoFiltered: "(Filtrando de un máximo de _MAX_ registros).",infoPostFix: "",thousands: ",",loadingRecords: "Cargando",processing: "Procesando",zeroRecords: "No se encontraron vehículos registrados."},processing: true,data: [],
          columnDefs:[{
              className: "text-center",
              targets: [0,1,2,3]
          }],
           createdRow: function(t, a, e) {
             // if(a.velocidad === "0.0") $(t).addClass("tr-sin-mover");
             //    else $(t).addClass("tr-normal");
          },
          columns: [{data:"fecha"},{data:"estado"},{data:"X"}, {data:"Y"}],ordering: true});

        Tabla.clear().draw();
        Tabla.rows.add(Reportes_Array).draw();
      }
    });

  }

}

function fancyTimeFormat(duration){
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;
    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";
    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }
    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
}
