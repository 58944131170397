import { Injectable } from '@angular/core';
import { CanActivateChild, Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {
  constructor(private global:GlobalService, private router: Router){}

  canActivateChild() {
    if(!this.global.checkLogin()){
      this.router.navigate(['/Login']);
      return;
    }
    this.global.SetActualUrl(location.pathname);
    this.global.SetMenu();
    return true;
  }
  canActivate() {
    if(this.global.checkLogin()){
      this.router.navigate(['/Inicio']);
      return;
    }
    return true;
  }

}
