import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { GlobalService } from '../../services/global.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';

declare var $: any;
declare var Swal: any;
@Component({
  selector: 'app-tipo-vehiculo',
  templateUrl: './tipo-vehiculo.component.html',
  styleUrls: ['./tipo-vehiculo.component.less']
})
export class TipoVehiculoComponent implements OnInit {

  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageCharged: boolean = false;
  imageB64Initial:string = "";

  imageChangedEvent2: any = '';
  croppedImage2: any = '';
  imageCharged2: boolean = false;
  imageB64Initial2:string = "";


  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  Tabla_de_Datos:any;
  Listado_Tipos:any = [];
  Icono_actual:any;
  registerForm = new FormGroup({
    name: new FormControl('',[ Validators.required , Validators.maxLength(15)]),
    description: new FormControl('',Validators.required),
    id: new FormControl({ value:getUniqueId(1), disabled:false}),
    image: new FormControl(''),
    icon: new FormControl(''),
  });

  Icono_actual_isB64:any = false;
  editForm = new FormGroup({
    id: new FormControl({ value:"", disabled:true}),
    name: new FormControl('',[ Validators.required , Validators.maxLength(15)]),
    description: new FormControl('',Validators.required),
    image: new FormControl(''),
    icon: new FormControl(''),
  });

  constructor(public global:GlobalService) {}



  ngOnInit(): void {
    this.Tabla_de_Datos = $('#dataTable').DataTable({dom: '<"datatable-header"f<"toolbar">><"datatable-scroll"t><"datatable-footer"i>',scrollX: false,scrollCollapse: false,lengthMenu: [[-1, 50, 25, 10, 5],["Todos", 50, 25, 10, 5]],aaSorting: [[0, "asc"]],language: {search: "<span >Buscar Tipo:</span> _INPUT_",lengthMenu: "<label>Cantidad de registros mostrados</label>_MENU_",paginate: {first: "Primero",last: "Ultimo",next: "→",previous: "←"},decimal: "",emptyTable: "No existen registros con los filtros indicados.",info: "Mostrando _START_ a _END_, de un total de _TOTAL_ registros",infoEmpty: "",infoFiltered: "(Filtrando de un máximo de _MAX_ registros).",infoPostFix: "",thousands: ",",loadingRecords: "Cargando",processing: "Procesando",zeroRecords: "No se encontraron vehículos registrados."},processing: true,data: [],
      columnDefs:[{
          className: "text-center",
          targets: [1,2,3]
      },{ visible: false, targets: [3] }],
      columns: [{data:"name"},{data:"description"},{data:"acciones"},{data:"fecha_creacion"}],ordering: true});
    let self = this;
    this.global.ShowLoading();

    this.global.getTiposL(Response=>{
      let Dispo_Aux = [];
      this.global.ShowLoading();
      console.log(Response.val())
      for(let i in Response.val()){
        let Dispositivo = Response.val()[i];
        //Dispositivo.id = i;
        let action = "CargarVehiculo";
        let push_data = {
          description: Dispositivo.description?Dispositivo.description:"-",
          name: (Dispositivo.name?Dispositivo.name:"-") + `<span class="icon-${Dispositivo.icon}" style="font-size:40px;margin-left:20px"></span> `,
          fecha_creacion: Dispositivo.created_date?Dispositivo.created_date:"-",
          // tipo: this.global.Tipos_de_Vehiculos.filter(A=>Dispositivo.tipo.toString()===A.id.toString())[0].name,
          acciones: `
            <button class="btn btn-info btn-icon-split mr-1" data-toggle="tooltip" data-button-edit="${Dispositivo.id}" title="Editar"><span class="icon"><i class="fas fa-user-edit"></i></span></button>
            <button class="btn btn-danger btn-icon-split" data-toggle="tooltip" data-button-delete="${Dispositivo.id}" title="Borrar"><span class="icon"><i class="fas fa-trash"></i></span></button>`
        }
        !Dispositivo.delete ? Dispo_Aux.push(push_data) : false;
      }
      this.Listado_Tipos = Dispo_Aux;
      this.Tabla_de_Datos.clear().draw();
      this.Tabla_de_Datos.rows.add(Dispo_Aux).draw();
      this.global.HideLoading();
      //setTimeout(()=>$('[data-toggle="tooltip"]').tooltip(),0);

      /** Carga eventos de botoones **/
      $("[data-button-edit]").bind("click").on("click", E=>{
         let id = $(E.currentTarget).data('button-edit');
         this.CargarVehiculo(id);
      });
      $("[data-button-delete]").bind("click").on("click", E=>{
         let id = $(E.currentTarget).data('button-delete');
         this.EliminarVehiculo(id);
      });


      function formatState (icon) {
        // console.log(icon)
        // console.log(self.global.Icono_vehiculos)
        if (!icon.id) {
          return icon.id;
        }
        var $icon = $(`<span class="icon-${icon.id}" style="font-size:20px;margin-rigth:10px">  ${icon.id}</span>`);
        let search = self.global.Icono_vehiculos.filter(A=>A.name===icon.id)[0];
        if(search){
          if(search.b64){
            var $icon = $(`<span><img height="20px" style="margin-rigth:10px" src="${search.b64}">${icon.id}</span>`);
          }
        }
        return $icon;
      };
      
      $(".tipo_icono").select2({
        templateResult: formatState
      });
  
    });
  }

  Tipo_Vechiculo_Actual:any= {};
  async EditarTipo(E){
    E.preventDefault();
    E.stopPropagation();
    let _continue_ = true;
    for(let i in this.editForm.controls){
      if(!this.editForm.controls[i].valid && i != "id" && i != "icon" ){
        $("#modal-editar-tipo [formControlName="+i+"]").addClass("is-invalid");
        _continue_ = false;
      }
    }
    console.log(this.editForm.valid)
    if(!_continue_){
      setTimeout(()=>$("#modal-editar-tipo [formControlName]").removeClass("is-invalid"), 1000);
      return;
    }
    if(_continue_){
      for(let i in this.editForm.controls) this.Tipo_Vechiculo_Actual[i] = this.editForm.controls[i].value;
      this.Tipo_Vechiculo_Actual.active===undefined?this.Tipo_Vechiculo_Actual.active=true:false;
      this.Tipo_Vechiculo_Actual.icon = $("#editar_icono").val()
      if(this.croppedImage.length>0){
        this.Tipo_Vechiculo_Actual.b64 = this.croppedImage;
      }
      this.global.ShowLoading();
      let imgResized = await this.global.reducirImagenManteniendoAspectRatio(this.croppedImage,180);
      this.global.uploadImage(imgResized,btoa(this.global.User.correo)+btoa(this.Tipo_Vechiculo_Actual.name)+btoa(this.Tipo_Vechiculo_Actual.id_empresa)+"_tipo_vehiculo").then(response=>{
        this.Tipo_Vechiculo_Actual.b64 = response+"?v="+Math.random().toString(36).slice(-4);
        this.global.addTipos(this.Tipo_Vechiculo_Actual);
        this.global.HideLoading();
        this.CerrarModalE();
      });
    }
  }


  EliminarVehiculo = (id)=>{
    this.global.Alert({
      title: '¡Atención!',
      text: "¿Segur@ que deseas eliminar el Tipo de vehículo?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          '!Eliminado!',
          'Se ha eliminado con éxito.',
          'success'
        );
        this.Tipo_Vechiculo_Actual = {};
        this.global.getTipo(id, R=>{
         let Dispositivo = R.val();
         this.Tipo_Vechiculo_Actual = Dispositivo;
         this.Tipo_Vechiculo_Actual.delete = true;
          this.global.addTipos(this.Tipo_Vechiculo_Actual);
        });
      }
    });
  }
  CargarVehiculo = (id)=>{
    this.Tipo_Vechiculo_Actual = {};
    this.global.getTipo(id, R=>{
     let Dispositivo = R.val();
     this.Tipo_Vechiculo_Actual = Dispositivo;
     console.log(Dispositivo)
    this.Icono_actual = Dispositivo.icon;
    // let search = this.global.Icono_vehiculos.filter(A=>A.name===Dispositivo.icon)[0];

    // if(Dispositivo.b64)
    // if(search){
    //   if(search.b64){
    //     Dispositivo.b64 = search.b64;
    //   }
    // }
    if(Dispositivo.b64){
      this.Icono_actual_isB64 = true;
      this.Icono_actual = Dispositivo.b64;
      this.imageB64Initial = Dispositivo.b64;
    }
    else{
      this.Icono_actual_isB64 = false;
    }

     this.editForm = new FormGroup({
      id: new FormControl({ value: Dispositivo.id, disabled:true}),
      name: new FormControl(Dispositivo.name,[Validators.required, Validators.maxLength(15)]),
      description: new FormControl(Dispositivo.description,Validators.required),
      icon: new FormControl(Dispositivo.icon,Validators.required),

    });
    $('#editar_icono').val(Dispositivo.icon).trigger('change');
    $("#modal-editar-tipo").modal()
   });
  }

  RegistrarTipoVehiculo(E){
    console.log(E)
    E.preventDefault();
    E.stopPropagation();
    let _continue_ = true;

    for(let i in this.registerForm.controls){
      if(!this.registerForm.controls[i].valid){

        if(i==="icon" && $("#crearIcono").val() != ""){
          //this.registerForm.controls[i].value = $("#crearIcono").val();
        }
        else if(i==="icon" && $("#crearIcono").val() == ""){
          if(this.croppedImage2.length>0){
            //this.registerForm.controls["b64"].value = this.croppedImage2;
          }
          else{
            $("#modal-nuevo-tipo [formControlName="+i+"]").addClass("is-invalid");
            _continue_ = false;
          }
        }
        else {
          $("#modal-nuevo-tipo [formControlName="+i+"]").addClass("is-invalid");
          _continue_ = false;
        }
      }
    }
    if(!_continue_){

      console.log("pasa por aca")
      setTimeout(()=>$("#modal-nuevo-tipo [formControlName]").removeClass("is-invalid"), 1000);
      return;
    }

    if(_continue_){
      let _New_ = this.registerForm.value;
      console.log(_New_)
      this.global.getTipo(_New_.id, async Response=>{
        let self = this;
        console.log(Response.val())
        if(Response.val()===null || Response.val().delete === true){
          _New_.active = true;
          _New_.delete = false;
          _New_.icon = $("#crearIcono").val();
          if(self.croppedImage2.length>0){
            _New_.b64 = self.croppedImage2;
          }
          _New_.id_empresa = self.global.ID_EMPRESA_ACTUAL;
          _New_.created_date = new Date().toISOString();

          self.global.ShowLoading();
          let imgResized = await self.global.reducirImagenManteniendoAspectRatio(self.croppedImage2,180);
          self.global.uploadImage(imgResized,btoa(self.global.User.correo)+btoa(_New_.name)+btoa(_New_.id_empresa)+"_tipo_vehiculo").then(response=>{
            _New_.b64 = response+"?v="+Math.random().toString(36).slice(-4);

            self.global.addTipos(_New_);
             self.global.Alert({
            icon: 'success',
            title: '',
            text: 'Tipo de Vehículo registrado correctamente.'
          })
           self.CerrarModal();

          });
  }





        else{
          self.global.Alert({
            icon: 'warning',
            title: 'Oops...',
            text: 'El IMEI ingresado ya se encuentra registrado.'
          });
          // $("[formcontrolname=imei]").addClass("is-invalid");
          setTimeout(()=>$("[formControlName]").removeClass("is-invalid"), 3000);
        }
      });

    }
    else
    console.log("invalido coso")
  }
  CerrarModal(){
    $("#modal-nuevo-tipo").modal('hide')
    this.registerForm = new FormGroup({
      email: new FormControl('',[ Validators.required, Validators.pattern(this.emailPattern) ]),
      name: new FormControl('',Validators.required),
      apellido_paterno: new FormControl('',Validators.required),
      apellido_materno: new FormControl(''),
      user_type: new FormControl('.', [Validators.required,Validators.minLength(2)]),
      password: new FormControl('', Validators.required),
      repit_password: new FormControl('', Validators.required)
    });
    this.imageB64Initial="";
    this.imageB64Initial2="";
  }
  CerrarModalE(){
    $("#modal-editar-tipo").modal('hide')
    this.editForm = new FormGroup({
      email: new FormControl({ value:"", disabled:true}),
      name: new FormControl('',Validators.required),
      apellido_paterno: new FormControl('',Validators.required),
      apellido_materno: new FormControl(''),
      user_type: new FormControl('.', [Validators.required,Validators.minLength(2)]),
    });
    this.imageB64Initial="";
    this.imageB64Initial2="";
  }
  Enviar(){
    $("#enviar-formulario").click();
  }
  EnviarE(){
    $("#enviar-formulario-e").click();
  }

  
  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
  }
  imageCropped(event) {
      this.croppedImage = event.base64;
      //this.global.User.imagen = this.croppedImage;
  }
  imageLoaded() { this.imageCharged = true; }
  cropperReady(){}
  loadImageFailed(){}

  fileChangeEvent2(event: any): void {
      this.imageChangedEvent2 = event;
  }
  imageCropped2(event) {
      this.croppedImage2 = event.base64;
      //this.global.User.imagen = this.croppedImage;
  }
  imageLoaded2() { this.imageCharged2 = true; }
  cropperReady2(){}
  loadImageFailed2(){}


}

/**
 * generate groups of 4 random characters
 * @example getUniqueId(1) : 607f
 * @example getUniqueId(2) : 95ca-361a-f8a1-1e73
 */
 export function getUniqueId(parts: number): string {
  const stringArr = [];
  for(let i = 0; i< parts; i++){
    // tslint:disable-next-line:no-bitwise
    const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    stringArr.push(S4);
  }
  return stringArr.join('-');
}
