<!DOCTYPE html>
<html lang="en">

<head>

  <meta charset="utf-8">
  <meta http-equiv="X-UA-Compatible" content="IE=edge">
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
  <meta name="description" content="">
  <meta name="author" content="">
  <title>Login</title>
</head>

<body class="bg-gradient-primary">

  <div class="container">
    <div class="row justify-content-center">

      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-9">

        <div class="card o-hidden border-0 shadow-lg my-5" *ngIf="!inicioRecuperacion">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-lg-12 d-none d-lg-block text-center">
                <br><br>
                <img width="340px" src="https://cdn-sacyr.s3.amazonaws.com/logo-login-2.svg">
                <div class="pl-5 pr-5">
                  <hr>
                  <h1 class="h5 text-gray-900">Ingreso sesión</h1>
                  <h1 class="h6 text-gray">¡Le damos la bienvenida!</h1>
                  <br>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="pb-5 pl-5 pr-5">
                  <form (ngSubmit)="onSubmit($event)" [formGroup]="loginForm">
                    <div class="form-group">
                      <input type="email" required formControlName="email" class="form-control form-control-user" id="exampleInputEmail" placeholder="Correo" aria-describedby="emailHelp">
                    </div>
                    <div class="form-group">
                      <input type="password" autocomplete="input-password" (focus)="useReadOnlyPass=false" (click)="useReadOnlyPass=false" [readonly]="useReadOnlyPass" formControlName="password" class="form-control form-control-user" id="input-password" placeholder="Contraseña" required>
                      <!-- <input type="password" formControlName="password" class="form-control input_pass"  placeholder="Contraseña" required> -->
                    </div>
                    <div class="form-group text-center">
                      <div class="small">
                        <a style="cursor: pointer;" (click)="iniciarRecuperacionDeContrasenia(this)">¿Olvidó su contraseña?</a>
                      </div>
                    </div>
                    <button *ngIf="!global.onLogin" type="submit" class="btn btn-primary btn-user btn-block">Ingresar</button>
                    <button *ngIf="global.onLogin" disabled class="btn btn-warning btn-user btn-block">Ingresando <i class="fas fa-spinner fa-spin spin-login"></i></button>
                    <a href="index.html" class="d-none btn btn-google btn-user btn-block">
                      <i class="fab fa-google fa-fw"></i> Login with Google
                    </a>
                    <a href="index.html" class="d-none btn btn-facebook btn-user btn-block">
                      <i class="fab fa-facebook-f fa-fw"></i> Login with Facebook
                    </a>
                  </form>
                  <hr>
                  <div class="text-center d-none">
                    <a class="small">¿Olvidaste la contraseña?</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card o-hidden border-0 shadow-lg my-5" *ngIf="inicioRecuperacion">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-lg-12 d-none d-lg-block text-center">
                <br><br>
                <img width="340px" src="https://cdn-sacyr.s3.amazonaws.com/logo-login-2.svg">
              </div>
              <hr>
              <div class="col-lg-12">
                <div class="p-5">
                  <div class="text-center">
                    <h1 class="h4 text-gray-900 mb-4">Recuperar Contraseña</h1>
                  </div>
                  <form (ngSubmit)="recuperarContrasenia($event)" [formGroup]="recuperarForm">

                    <div class="form-group">
                      <label>Indique el correo para poder recuperar la contraseña</label>
                      <input type="email-recuperar" required formControlName="email" class="form-control form-control-user" id="exampleInputEmail" placeholder="Correo" aria-describedby="emailHelp">
                    </div>
                    <button *ngIf="!global.onRecuperando" style="width: 38%;" (click)="volver()" type="button" class="btn btn-warning btn-user">Volver</button>
                    <button *ngIf="!global.onRecuperando" style="margin-left: 3%; width: 59%;" type="submit" class="btn btn-primary btn-user">Recuperar</button>
                    <button *ngIf="global.onRecuperando" disabled class="btn btn-warning btn-user btn-block">Validando <i class="fas fa-spinner fa-spin spin-login"></i></button>
                  </form>
                  <hr><br>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="text-center text-white">
          Se recomienda utilizar el navegador 
          <a class="text-white" href="https://www.google.com/intl/es-419/chrome/" target="_blank">
            <b><u>Google Chrome</u></b></a>.
            <br>Todos los derechos reservados © 2021. 
            <br> Un producto <a class="text-white" href="https://www.integritic.cl" target="_blank"><b><u>integritic.cl</u></b></a>
        </footer>
      </div>
    </div>
  </div>
</body>
</html>
