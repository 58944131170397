import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';

import { LandingComponent } from './components/landing/landing.component';
import { LoginComponent } from './components/login/login.component';
import { EmpresasComponent } from './components/empresas/empresas.component';
import { FlotasComponent } from './components/flotas/flotas.component';
import { LayoutComponent } from './components/layout/layout.component';
import { UsuariosComponent } from './components/usuarios/usuarios.component';
import { VehiculosComponent   } from './components/vehiculos/vehiculos.component';
import { ReportesComponent   } from './components/reportes/reportes.component';
import { TipoVehiculoComponent   } from './components/tipo-vehiculo/tipo-vehiculo.component';
import { InicioComponent } from './components/inicio/inicio.component'; /** Seguimiento **/
import { PageNotFoundComponentComponent } from './components/page-not-found-component/page-not-found-component.component';
import { ReportesRangoComponent   } from './components/reportes-rango/reportes-rango.component';
import { PerfilComponent   } from './components/perfil/perfil.component';


const routes: Routes = [
  //{ path: '', component: LandingComponent },
  { path: '', canActivate: [AuthGuard], component: LoginComponent },
  { path: 'Login', canActivate: [AuthGuard], component: LoginComponent },
  { path: 'login', canActivate: [AuthGuard], component: LoginComponent },

  {
    path: 'Inicio',
    component: LayoutComponent,
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        component: PageNotFoundComponentComponent,
      },
      {
        path: 'Empresas',
        component: EmpresasComponent,
      },
      {
        path: 'Flotas',
        component: FlotasComponent,
      },
       {
        path: 'Perfil',
        component: PerfilComponent,
      },
      {
        path: 'Seguimiento',
        component: InicioComponent,
      },
      {
        path: 'Usuarios',
        component: UsuariosComponent,
      },
      {
        path: 'Vehiculos',
        component: VehiculosComponent,
      },
      {
        path: 'Tipos-de-Vehiculos',
        component: TipoVehiculoComponent,
      },
      {
        path: 'Reportes',
        component: ReportesComponent,
      },
      {
        path: 'Reportes-rangos',
        component: ReportesRangoComponent,
      },
    ], 
  },
  { path: '**', component: PageNotFoundComponentComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
