<div class="container-fluid">
  <h1 class="h3 mb-2 text-gray-800">Módulo de Reportes</h1>
  <p class="mb-4">Descripción del módulo.</p>
  <div class="card shadow border-bottom-{{global.clase_menu}} shadow h-100">
    <div class="card-header py-3 border-bottom-{{global.clase_menu}}">
      <h6 class="m-0 font-weight-bold row">
        <div class="form-group col-lg-2">
            <label>Tipo de Reporte:</label>
            <select id="reporte" class="form-control" (change)="ActualizarVariable($event, 'Tipo_Reporte')">
              <option disabled selected>Seleccione</option>
              <!-- <option value="distancia">Distancia Recorrida</option> -->
              <option value="encendido">Tiempo de Encendido</option>
              <option value="ruta">Ruta y Distancia Recorrida</option>
            </select>
        </div>
        <div class="form-group col-lg-2" id="foco">
            <label>Vehículo:</label>
            <select class="form-control" id="vehiculo" (change)="ActualizarVariable($event, 'Vehiculo_Seleccionado')">
              <option selected disabled>Seleccione...</option>
              <option *ngFor="let dispositivo of Listado_Vehiculos; let i = index" [value]="dispositivo.imei">{{dispositivo.descripcion}} - {{dispositivo.patente}} - {{dispositivo.imei}}</option>
            </select>
        </div>
        <div class="form-group col-lg-2">
            <label>Fecha: </label>
            <input type="date" id="fecha-hasta" class="form-control" max="{{this.fecha_maxima}}" (change)="ActualizarVariable($event, 'Fecha_Desde')">
        </div>
         <div class="form-group col-lg-2">
            <label>Desde: <img src="https://cdn-sacyr.s3.amazonaws.com/inicio.png" width="10px" style="margin-top: -2px; margin-left: 5px;"></label>
            <input type="time" id="hora-desde" class="form-control" (change)="ActualizarVariable($event, 'Hora_Desde')">
        </div>
        <div class="form-group col-lg-2">
            <label>Hasta: <img src="https://cdn-sacyr.s3.amazonaws.com/cement-truck.png" width="25px" style="margin-top: -4px; margin-left: 5px;"></label>
            <input type="time" id="hora-hasta" class="form-control" (change)="ActualizarVariable($event, 'Hora_Hasta')">
        </div>
        <div class="form-group col-12 col-lg-2 d-none d-lg-block">
          <button style="width: 100%" *ngIf="Puede_Buscar" class="mt-25 ml-1 btn btn-{{global.clase_menu}} btn-icon-split" (click)="BuscarReportes()">
            <span style="" class="icon text-white-50">
              <i class="fas fa-search"></i>
            </span>
            <span class="text" style="font-size:15px;">Buscar Reportes</span>
          </button>
          <button style="width: 100%" *ngIf="!Puede_Buscar" class="mt-25 ml-1 btn btn-{{global.clase_menu}} btn-icon-split" disabled>
            <span  style=""  class="icon text-white-50">
              <i class="fas fa-search"></i>
            </span>
            <span class="text" style="font-size:15px;">Buscar Reportes</span>
          </button>
        </div>

        <div class="form-group col-12 col-lg-2 d-lg-none ">
            <button style="width: 100%;" *ngIf="Puede_Buscar" class="mt-25 ml-1 btn btn-{{global.clase_menu}} btn-icon-split" (click)="BuscarReportes()">
              <span style=" position: absolute; left: 17px;" class="icon text-white-50">
                <i class="fas fa-search"></i>
              </span>
              <span class="text" style="font-size:15px;">Buscar Reportes</span>
            </button>
            <button style="width: 100%;" *ngIf="!Puede_Buscar" class="mt-25 ml-1 btn btn-{{global.clase_menu}} btn-icon-split" disabled>
              <span  style=" position: absolute; left: 17px;"  class="icon text-white-50">
                <i class="fas fa-search"></i>
              </span>
              <span class="text" style="font-size:15px;">Buscar Reportes</span>
            </button>
        </div>

      </h6>
    </div>
    <div class="card-body pl-3 pr-3 row d-none" id="ruta">
      <span class="pl-3 pt-1 pb-3"><b>Distancia Recorrida: {{Distancia_Recorrida}}</b></span>
      <div class="col-xl-12 col-md-12 mb-4" id="row-mapa-css">
        <div class="card border-left-{{global.clase_menu}} shadow h-100 py-2">
          <div class="card-body">
            <div id="map" class="map" style="height: 500px;"></div>
          </div>
          <input type="text" id="amount" readonly style="border:0; color:#f6931f; font-weight:bold;">
          <button class="btn btn-info" (click)="BotonPlay('play')" style="width: 150px;">Reproducir Ruta</button>
          <!-- <button class="btn btn-info" (click)="BotonVelocidad('2x')" style="width: 70px;">>></button> -->

          <div id="slider"></div>
        </div>
      </div>
      <div class="table-responsive p-3">
        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
          <thead>
            <tr>
              <th>Hora</th>
              <th>Velocidad</th>
              <th>Odometro</th>
              <th>Distancia<br>Recorrida</th>
              <th>Latitud</th>
              <th>Longitud</th>
              <th>Ver en Mapa</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <div class="card-body pl-3 pr-3 row d-none" id="encendido">
      <span class="pl-3 pt-1 pb-3"><b>Tiempo Encendido: {{Tiempo_Encendido}}</b></span>
      <div class="table-responsive p-3">
        <table class="table table-bordered" id="dataTable-2" width="100%" cellspacing="0">
          <thead>
            <tr>
              <th>Hora</th>
              <th>Estado</th>
              <th>Tipo</th>
              <th>Tiempo<br>Encendido</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>

</div>
