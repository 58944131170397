<div class="container-fluid">
  <h1 class="h3 mb-2 text-gray-800">Gestión de Usuarios</h1>
  <p class="mb-4">Descripción del módulo.</p>
  <div class="card shadow border-bottom-{{global.clase_menu}} shadow h-100">
    <div class="card-header py-3">
      <h6 class="m-0 font-weight-bold">
        <button class="btn btn-{{global.clase_menu}} btn-icon-split" data-toggle="modal" data-target="#modal-nuevo-usuario">
          <span class="icon text-white-50">
            <i class="fas fa-user-plus"></i>
          </span>
          <span class="text">Registrar nuevo Usuario</span>
        </button>
      </h6>
    </div>
    <div class="card-body pl-3 pr-3">
      <div class="table-responsive">
        <table class="table table-bordered" id="dataTable" width="100%" cellspacing="0">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Cargo</th>
              <th>Correo</th>
              <th width="180px" class="text-center">Tipo de Usuario</th>
              <th width="210px" class="text-center">Acceso a Plataforma</th>
              <th width="245px" class="text-center">Acciones</th>
            </tr>
          </thead>
        </table>
      </div>
  </div>
</div>
<div class="modal fade" id="modal-nuevo-usuario" tabindex="-1" role="dialog" aria-labelledby="modal-nuevo-usuario" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Registro de nuevo Usuario</h5>
        <button class="close" type="button" (click)="CerrarModal()" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="card-body p-0">
          <div class="row ml-0 mr-0">
            <!-- <div class="col-lg-5 d-none d-lg-block bg-register-image"></div> -->
            <div class="col-lg-12">
              <div class="p-2">
                <form class="user" (ngSubmit)="RegistrarUsuario($event)" [formGroup]="registerForm">
                  <div class="form-group">
                    <label>Correo Electrónico:*</label>
                    <input type="email" formControlName="email" class="form-control form-control-user" aasda="Correo Electrónico *" required>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12 mb-3 mb-sm-0">
                      <label>Nombres: *</label>
                      <input type="text" formControlName="name" class="form-control form-control-user" aasda="Nombres *" required>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-6">
                      <label>Apellido Paterno: *</label>
                      <input type="text" formControlName="apellido_paterno" class="form-control form-control-user" aasda="Apellido Paterno *" required>
                    </div>
                    <div class="col-sm-6">
                      <label>Apellido Materno: *</label>
                      <input type="text" formControlName="apellido_materno" class="form-control form-control-user" aasda="Apellido Materno">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-3">
                      <label>Tipo de Usuario: </label>
                    </div>
                    <div class="col-sm-9">
                      <select class="form-control" formControlName="user_type" required>
                        <option selected value=".">Seleccione una opción</option>
                        <option>Administrador</option>
                        <option value="Normal">Usuario Restringido</option>
                        <option >Chofer</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12 mb-3 mb-sm-0">
                      <label>Cargo: *</label>
                      <input type="text" formControlName="cargo" class="form-control form-control-user" aasda="Cargo *" required>
                    </div>
                  </div>
                  <hr><br>
                  <div class="form-group row">
                    <div class="col-sm-6 mb-3 mb-sm-0">
                      <label>Contraseña: *</label>
                      <input type="password" formControlName="password" class="form-control form-control-user" aasda="Contraseña *" required>
                    </div>
                    <div class="col-sm-6">
                      <label>Repita Contraseña: *</label>
                      <input type="password" formControlName="repit_password" class="form-control form-control-user" aasda="Repita Contraseña *" required>
                    </div>
                  </div>
                  <input type="submit" class="d-none" id="enviar-formulario">
                </form>
                <hr>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" id="btn-cerrar-modal" type="button" (click)="CerrarModal()" >Cancelar</button>
        <button class="btn btn-primary" (click)="Enviar()">Registrar</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" id="modal-editar-usuario" tabindex="-1" role="dialog" aria-labelledby="modal-editar-usuario" aria-hidden="true">
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Edición de Usuario</h5>
        <button class="close" type="button" (click)="CerrarModalE()" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body p-0">
        <div class="card-body p-0">
          <div class="row ml-0 mr-0">
            <!-- <div class="col-lg-5 d-none d-lg-block bg-register-image"></div> -->
            <div class="col-lg-12">
              <div class="p-2">
                <form class="user" (ngSubmit)="EditarUsuario($event)" [formGroup]="editForm">
                  <div class="form-group">
                    <label>Correo Electrónico:</label>
                    <input type="email" formControlName="email" class="form-control form-control-user disabled" aasda="Correo Electrónico">
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12 mb-3 mb-sm-0">
                      <label>Nombres:</label>
                      <input type="text" formControlName="name" class="form-control form-control-user" aasda="Nombres" required>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-6">
                      <label>Apellido Paterno:</label>
                      <input type="text" formControlName="apellido_paterno" class="form-control form-control-user" aasda="Apellido Paterno" required>
                    </div>
                    <div class="col-sm-6">
                      <label>Apellido Materno:</label>
                      <input type="text" formControlName="apellido_materno" class="form-control form-control-user" aasda="Apellido Materno">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-3">
                      <label>Tipo de Usuario: </label>
                    </div>
                    <div class="col-sm-9">
                      <select class="form-control" formControlName="user_type" required>
                        <option selected value=".">Seleccione una opción</option>
                        <option *ngIf="global.User.superadmin">Super Admin</option>
                        <option>Administrador</option>
                        <option value="Normal">Usuario Restringido</option>
                        <option >Chofer</option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12 mb-3 mb-sm-0">
                      <label>Cargo: *</label>
                      <input type="text" formControlName="cargo" class="form-control form-control-user" aasda="Cargo *" required>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-sm-12 mb-3 mb-sm-0">
                      <label>Contraseña: </label>
                      <input type="password" formControlName="password" minlength="6" class="form-control form-control-user" aasda="Contraseña">
                    </div>
                  </div>
                  <input type="submit" class="d-none" id="enviar-formulario-e">
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-secondary" id="btn-cerrar-modal-e" type="button" (click)="CerrarModalE()" >Cancelar</button>
        <button class="btn btn-primary" (click)="EnviarE()">Guardar</button>
      </div>
    </div>
  </div>
</div>
